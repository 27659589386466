import { render, staticRenderFns } from "./QnAModal.vue?vue&type=template&id=69c52996&scoped=true"
import script from "./QnAModal.vue?vue&type=script&lang=js"
export * from "./QnAModal.vue?vue&type=script&lang=js"
import style0 from "./QnAModal.vue?vue&type=style&index=0&id=69c52996&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "69c52996",
  null
  
)

export default component.exports