<template>
  <div class="tab-navigation" :class="{ 'switch-mode': mode === 'switch' }">
    <ul class="tab-navigation-list">
      <li
        v-for="(tab, index) in tabs"
        :key="index"
        class="tab-navigation-item"
        :class="{ active: modelValue === tab.id }"
        @click="handleTabClick(tab)"
      >
        <span class="item-name">
          {{ tab.label }}
        </span>
        <i v-if="mode === 'scroll'" class="icon-down mb"></i>
        <p class="item-desc pc">{{ tab.desc }}</p>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: "TabNavigation",
  props: {
    tabs: {
      type: Array,
      required: true,
    },
    mode: {
      type: String,
      default: "scroll", // 'scroll' or 'switch'
      validator: (value) => ["scroll", "switch"].includes(value),
    },
    modelValue: {
      // v-model을 위한 prop
      type: String,
      default: "notice",
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      headerHeight: window.innerHeight >= 768 ? 68 : 36,
    };
  },
  mounted() {
    window.addEventListener("resize", this.updateHeaderHeight);
  },
  // beforeDestroy() {
  //   window.removeEventListener("resize", this.updateHeaderHeight);
  // },
  methods: {
    updateHeaderHeight() {
      this.headerHeight = window.innerHeight >= 768 ? 68 : 36;
    },
    handleTabClick(tab) {
      if (this.mode === "scroll") {
        const element = document.querySelector(`.${tab.target}`);
        if (element) {
          const elementPosition = element.getBoundingClientRect().top;
          const offsetPosition =
            elementPosition + window.pageYOffset - this.headerHeight;

          window.scrollTo({
            top: offsetPosition,
            behavior: "smooth",
          });
        }
      } else {
        this.$emit("update:modelValue", tab.id);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@use "@/assets/scss/landing/abstracts/variables" as m;

.tab-navigation {
  width: 335px;
  height: 40px;
  padding: 0 22px;
  border-radius: 24px;
  background: m.color(white);
  box-shadow: 0px 4px 12px 0px rgba(109, 110, 250, 0.24);
  cursor: pointer;
  @include m.tablet {
    width: clamp(604px, 88.281vw, 904px);
    height: 103px;
    padding: 0 48px;
    box-shadow: 0px 4px 32px 0px rgba(109, 110, 250, 0.24);
  }
  @include m.desktop {
    width: 1320px;
    height: 184px;
    padding: 0 60px;
    border-radius: 32px;
  }

  // swtich mode
  &.switch-mode {
    @include m.tablet {
      bottom: -38px !important;
      max-height: 84px;
      padding: 0;
    }
    @include m.desktop {
      max-height: 90px;
    }
    .tab-navigation-list {
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      padding: 0;
      margin: 0;
      height: 100%;
      @include m.tablet {
        height: 84px;
        border-radius: 24px;
        background: #fff;
        overflow: hidden;
      }
      @include m.desktop {
        height: 90px;
      }
      .tab-navigation-item {
        @include m.flex-center;
        flex: 1;
        margin: 0;
        padding: 0;
        border: none;
        background: #fff;
        white-space: nowrap;
        @include m.tablet {
          height: 84px;
        }
        @include m.desktop {
          height: 90px;
        }
        &:not(:last-child) {
          @include m.tablet {
            position: relative;
            //border-right: 2px solid m.color(primary100);
            &::after {
              content: "";
              position: absolute;
              top: 50%;
              right: 0;
              width: 2px;
              height: 40px;
              background: m.color(primary100);
              transform: translateY(-50%);
            }
          }
        }
        .item-name {
          font-weight: 700;
          font-size: 0.875rem;
          color: m.color(gray400);
          @include m.tablet {
            font-size: 1.25rem;
          }
          @include m.desktop {
            font-size: 1.75rem;
          }
        }
        &.active {
          .item-name {
            color: m.color(gray900);
          }
        }
      }
    }
  }

  &:not(.switch-mode) {
    .tab-navigation-list {
      @include m.flex(row, center, center);
      height: 100%;
      padding: 10px 0;
      @include m.tablet {
        padding: 20px 0;
      }
      @include m.desktop {
        padding: 40px 0;
      }
      .tab-navigation-item {
        @include m.flex(row, center, center);
        width: 33.33%;
        height: 100%;
        cursor: pointer;
        @include m.tablet {
          @include m.flex(column, center, baseline);
        }
        &:not(:first-child) {
          padding-left: 20px;
          @include m.tablet {
            //padding-left: 48px;
          }
          @include m.desktop {
            padding-left: 30px;
          }
        }
        &:not(:last-child) {
          border-right: 2px solid m.color(primary100);
          @include m.tablet {
            padding-right: clamp(10px, 15px, 48px);
          }
          @include m.desktop {
            padding-right: 30px;
          }
        }
        &:first-child {
          padding-left: 10px;
          @include m.tablet {
            padding-left: 0;
          }
        }
        .item-name {
          font-weight: 700;
          font-size: 0.875rem;
          color: m.color(gray900);
          @include m.tablet {
            font-size: 1.25rem;
          }
          @include m.desktop {
            font-size: 1.75rem;
          }
        }
        .icon-down {
          width: 12px;
          height: 12px;
          margin-left: 8px;
          background: url(m.$icon + "ico-down-gray.png") no-repeat;
          background-size: cover;
        }
        .item-desc {
          display: none;
          font-size: 400px;
          font-size: 0.75rem;
          color: m.color(gray600);
          @include m.tablet {
            padding-top: 4px;
            display: block;
          }
          @include m.desktop {
            padding-top: 24px;
            font-weight: 500;
            font-size: 1.125rem;
          }
        }
      }
    }
  }
}
</style>
