<template>
  <div class="uc-sms-template-list list-view">
    <TemplateTabs />

    <div class="tab-contents">
      <!-- 검색영역 Start -->
      <div class="search-section card border-0">
        <div class="search-section-forms">
          <div class="d-flex align-items-center flex-wrap mb-4">
            <label>검색조건</label>
            <b-dropdown id="template-dropdown" variant="secondary" class="template-dropdown">
              <template #button-content>
                <span>{{ searchData.searchCondi === 'tmpltName' ? '템플릿명' : '템플릿ID' }}</span>
                <IconArrowDown />
              </template>
              <b-dropdown-item @click="searchData.searchCondi = 'tmpltName';">템플릿명</b-dropdown-item>
              <b-dropdown-item @click="searchData.searchCondi = 'tmpltId';">템플릿ID</b-dropdown-item>
            </b-dropdown>
            <div class="search-group">
              <SearchInput v-model="searchData.searchText" @search="fnSelectSmsTmpltList" />
            </div>
          </div>
        </div>
      </div>
      <!-- 검색영역 End -->

      <!-- 리스트 영역 Start -->
      <div class="table-section card">
        <div class="d-flex align-items-center">
          <p class="list-count">전체 <span class="text-primary">{{ totCnt | formatComma }}건</span></p>

          <b-dropdown id="pageCount-dropdown" variant="secondary" class="pageCount-dropdown">
            <template #button-content>
              <span>{{ pageCount }}개씩 보기</span>
              <IconArrowDown />
            </template>
            <b-dropdown-item-button @click="handlePageCountChange(10)">10개씩 보기</b-dropdown-item-button>
            <b-dropdown-item-button @click="handlePageCountChange(20)">20개씩 보기</b-dropdown-item-button>
            <b-dropdown-item-button @click="handlePageCountChange(30)">30개씩 보기</b-dropdown-item-button>
          </b-dropdown>

          <b-button variant="secondary" class="btn-svg btn-svg-right ml-auto" @click="navigateToManage">
            <span>템플릿 등록</span>
            <IconArrowRight />
          </b-button>
          <i class="vertical-divider"></i>
          <b-button variant="outline-primary" @click="fnDeleteSmsTemplate">삭제</b-button>

          <b-button @click="fnExcelDownLoad" variant="outline-primary" class="btn-svg btn-svg-right ml-2">
            <span>엑셀 다운로드</span>
            <IconArrowLineDown />
          </b-button>

        </div>
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>

                <th scope="col" class="text-center">
                  <b-form-checkbox id="selectAll" name="selectAll" v-model="listAllChecked" @change="fnListChkAll"
                    size="lg"></b-form-checkbox>
                </th>


                <th scope="col">
                  <div class="d-flex align-items-center">
                    NO.
                    <IconSort />
                  </div>
                </th>
                <th scope="col">
                  <div class="d-flex align-items-center"> <!-- NOTE: 정렬시 class명 추가하면 스타일 변경됨 -->
                    템플릿 ID
                    <IconSort />
                  </div>
                </th>
                <th scope="col">
                  <div class="d-flex align-items-center">
                    템플릿명
                    <IconSort class="down" /> <!-- NOTE: 정렬시 class명 추가하면 스타일 변경됨 -->
                  </div>
                </th>
                <th scope="col">
                  <div class="d-flex align-items-center">
                    발송유형
                    <IconSort />
                  </div>
                </th>
                <th scope="col">
                  <div class="d-flex align-items-center">
                    등록자
                    <IconSort />
                  </div>
                </th>
                <th scope="col">
                  <div class="d-flex align-items-center">
                    등록일자
                    <IconSort />
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(contant, idx) in contants" :key="contant.tmpltId">


                <td class="text-center">
                  <b-form-checkbox :id="'checkbox-' + idx" :value="contant.tmpltId" v-model="listChkBox"
                    size="lg"></b-form-checkbox>
                  <!-- @change="selected($event)" -->
                </td>
                <td>{{ totCnt - offset - contant.rownum + 1 | formatComma }}</td>
                <td>
                  <span class="text-underline" @click="navigateToManage(contant)">{{ contant.tmpltId }}</span>
                </td>
                <td>{{ contant.tmpltName | unescapeXss }}</td>
                <td>{{ contant.senderTypeName }}</td>
                <td>{{ contant.regNm }}</td>
                <td>{{ contant.regDt }}</td>
              </tr>
              <tr v-if="contants.length == 0">
                <td class="text-center" colspan="11">검색된 내용이 없습니다.</td>
              </tr>

            </tbody>
          </table>

          <Pagination @fnClick="fnSearch" :listTotalCnt="totCnt" :selected="pageCount" :pageNum="pageNo"
            ref="updatePaging" />
        </div>
      </div>
      <!-- 리스트 영역 End -->
    </div>

    <AlertModal :title="alertTitle" :desc="alertDesc" />
    <ConfirmModal :title="confirmTitle" :desc="confirmDesc" :onSubmit="callback" />
  </div>
</template>

<script>
import IconArrowDown from '@/components/service/icons/IconArrowDown.vue'
import TemplateTabs from '@/components/service/template/TemplateTabs.vue'
import SearchInput from '@/components/service/form/SearchInput.vue'
import IconSort from '@/components/service/icons/IconSort.vue';
import IconArrowRight from '@/components/service/icons/IconArrowRight.vue';
import Pagination from '@/components/service/Pagination.vue';
import AlertModal from '@/components/service/modal/AlertModal.vue'
import IconArrowLineDown from '@/components/service/icons/IconArrowLineDown.vue';
import ConfirmModal from '@/components/service/modal/ConfirmModal.vue';
import templateApi from "@/modules/template/service/templateApi.js";

export default {
  components: { IconArrowDown, SearchInput, Pagination, IconSort, TemplateTabs, IconArrowRight, AlertModal, IconArrowLineDown, ConfirmModal },
  name: "smsTemplateList",
  props: {
    searchData: {
      type: Object,
      require: false,
      default: function () {
        return {
          'searchCondi': 'tmpltName',
          'searchText': '',
          'searchOthPrjUseYn': []
        }
      }
    },
    componentsTitle: {
      type: String,
      require: false,
      default: function () {
        return 'SMS/MMS 템플릿';
      }
    },
  },
  data() {
    return {
      pageCount: 10,
      othPrjUseYnAllSelected: true,
      listAllChecked: false,
      listChkBox: [],
      listSize: 10,
      pageNo: 1,
      totCnt: 0,  //전체 리스트 수
      offset: 0, //페이지 시작점
      searchDateInterval: 7,
      attachFileNm: "",			// 첨부파일
      contants: [],
      searchText: "",
      confirmTitle: '',
      confirmDesc: '',
      alertTitle: '',
      alertDesc: '',
      title: '',
      desc: '',
      callback: () => { } // 기본 함수로 초기화
    }
  },
  mounted() {
    this.fnValidUseChGrp();
    this.fnPageNoResetSearch();
  },
  created() {
    // 상세화면에서 목록 가기 호출 시 params에서 데이터를 가져와서 설정
    if (this.$route.params.fromDetailPage) {
      this.setParams();
    }
  },
  methods: {
    toggleMenu() {
      this.hideMenu = !this.hideMenu;
    },
    navigateToManage(contant) {
      this.$router.push({ name: "smsTemplateManage", params: { status: "UPT", tmpltId: contant.tmpltId } })
    },
    handlePageCountChange(count) {
      this.pageCount = count;
      this.fnSelected(count);
    },
    showConfirm(message, method) {
      if (typeof method !== 'function') {
        method = () => { }; // callback이 없는 경우 빈 함수 
      }

      this.confirmTitle = 'SMS/MMS 템플릿'
      this.confirmDesc = message
      this.callback = method
      this.$bvModal.show('confirm-modal');

      // this.$refs.confirmModal.openModal(this.componentsTitle, message, method);
    },
    showAlert(msg) {
      this.alertTitle = 'SMS/MMS 템플릿'
      this.alertDesc = msg
      this.$bvModal.show('alert-modal');
    },
    fnListChkAll() {
      if (this.listAllChecked) {
        this.listChkBox = this.contants.map(contant => contant.tmpltId);
      } else {
        this.listChkBox = []
      }
    },
    async fnValidUseChGrp() {
      let params = { chGrp: 'SMS/MMS' };
      await templateApi.selectValidUseChGrp(params).then(response => {
        const result = response.data;
        if (result.success) {
          if (this.$gfnCommonUtils.isEmpty(result.data)) {
            this.showAlert('이용하실 수 없는 채널입니다.')
            this.$router.back();
          }
        } else {
          this.showAlert('시스템 오류입니다. 잠시 후 다시 시도하세요.')
          this.$router.back();
        }
      });
    },
    //템플릿 엑셀 다운로드
    fnExcelDownLoad() {
      const params = this.searchData;
      templateApi.excelDownloadSmsTmplt(params);
    },
    //템플릿 삭제
    fnDeleteSmsTemplate() {
      //유효성 검사
      if (this.listChkBox == null || this.listChkBox.length == 0) {
        this.showAlert('삭제할 항목을 선택해주세요.');
        return;
      }

      this.showConfirm("선택한 템플릿을 삭제하시겠습니까?", this.fnProcDeleteSmsTemplate)
    },
    //템플릿 삭제 처리
    async fnProcDeleteSmsTemplate() {
      const params = { tmpltIds: this.listChkBox };

      await templateApi.deleteSmsTmplt(params).then(response => {
        const result = response.data;
        if (result.success) {
          this.listAllChecked = false;
          this.fnListChkAll();
          this.fnPageNoResetSearch();
          this.showAlert('삭제되었습니다.');
          return;

        } else {
          this.showAlert(result.message);
        }
      });
    },
    //템플릿 리스트 검색
    async fnSelectSmsTmpltList() {

      let params = Object.assign({}, this.searchData);
      params.pageNo = this.pageNo;
      params.listSize = this.listSize;

      await templateApi.selectSmsTmpltList(params).then(response => {
        const result = response.data;
        if (result.success) {
          this.contants = result.data;
          this.totCnt = result.pageInfo.totCnt;
          this.offset = result.pageInfo.offset;

          // 상세화면에서 목록올때 기존 검색조건, 페이징 정보 세팅
          this.$nextTick(() => {
            if (this.$route.params.fromDetailPage) {
              this.$route.params.fromDetailPage = false;
            } else if (this.$route.params.fromDetailPage == false) {
              this.initParams();
            }
          });

        } else {
          this.showAlert(result.message);
        }
      });
    },
    // select 박스 선택시 리스트 재출력
    fnSelected(listSize) {
      this.listSize = Number(listSize);
      this.$refs.updatePaging.fnAllDecrease(1);
    },
    fnPageNoResetSearch() {
      this.$refs.updatePaging.fnAllDecrease(2);
    },
    fnSearch(pageNo) {
      this.pageNo = (this.$gfnCommonUtils.defaultIfEmpty(pageNo, '1')) * 1;
      this.fnSelectSmsTmpltList();
    },
    // 상세화면에서 목록올때 기존 검색조건, 페이징 정보 세팅
    generateLink(contant) {
      let params = {
        searchData: JSON.stringify(this.searchData),
        pageNo: this.pageNo,
        listSize: this.listSize,
      };
      return {
        name: 'smsTemplateManage',
        params: {
          tmpltId: contant.tmpltId,
          ...params // 결합된 모든 데이터를 params로 전달
        }
      };
    },
    // 상세화면에서 목록올때 기존 검색조건, 페이징 정보 세팅
    setParams() {
      // 현재 route params를 가져와서 설정
      const params = this.$route.params;
      const searchDataString = this.$route.params.searchData || '{}';
      this.searchData = JSON.parse(searchDataString);
      // 검색 데이터 업데이트
      this.searchData.searchCondi = params.searchCondi || this.searchData.searchCondi;
      this.searchData.searchText = params.searchText || this.searchData.searchText;

      // 페이지 번호와 리스트 사이즈 업데이트
      this.pageNo = params.pageNo || this.pageNo;
      this.listSize = params.listSize || this.listSize;

    },
    // 상세화면에서 목록올때 기존 검색조건, 페이징 정보 세팅
    initParams() {
      // 검색 데이터 업데이트
      this.searchData.searchCondi = 'tmpltName';
      this.searchData.searchText = '';
      // 페이지 번호와 리스트 사이즈 업데이트
      this.pageNo = 1;
      this.listSize = 10;
    }
  }
};
</script>

<style scoped lang="scss">
@use "~@/assets/scss/service/base/typography" as typography;
@import '@/assets/scss/service/message.scss';

.template-dropdown {
  min-width: 160px;
  margin: 0 16px;
}

.search-group {
  width: 355px;
  margin-right: 20px;
}

.form-group {
  margin-left: 24px;
  margin-bottom: 0;
}

/* 애니메이션 추가 */
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.1s ease;
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(-10px);
  opacity: 0;
}

.check-menu {
  display: flex;
}

.vertical-divider {
  width: 1px;
  height: 16px;
  margin: 0 20px;
  background: var(--border-color);
}

.list-view .pageCount-dropdown {
  margin-right: auto;
}
</style>
