<template>
  <button class="signup-btn-back">
    <IconArrowRight2 style="transform: rotateZ(180deg);" />
    뒤로가기
  </button>
</template>

<script>
import IconArrowRight2 from '../../service/icons/IconArrowRight2.vue';

export default {
  name: 'SignupBackButton',

  components: {
    IconArrowRight2
  }
}
</script>

<style lang="scss" scoped>
.signup-btn-back {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  border: 0;

  @media (min-width: 300px) {
    width: fit-content;
    height: 19px;
    gap: 4px;
    font-size: 12px;
    background-color: transparent;

    svg {
      width: 12px;
      height: 12px;
    }
  }

  @media (min-width: 375px) {
    width: fit-content;
    height: 19px;
    gap: 4px;
    font-size: 12px;
    background-color: transparent;

    svg {
      width: 12px;
      height: 12px;
    }
  }

  @media (min-width: 1024px) {
    width: 96px;
    height: 36px;
    gap: 8px;
    font-size: 14px;
    font-weight: 700;
    color: #111827;
    background-color: #F3F4F6;
    border: 0;
    border-radius: 999px;

    svg {
      width: 16px;
      height: 16px;
    }
  }
}
</style>