<template>
  <div class="qna-container">
    <div class="qna-container__title">
     <h3 class="customer-title">1:1 문의</h3> 
    </div>

    <div class="qna-container__form-wrapper">
      <LoginForm>
        <LoginFormRadioGroupField label="문의 유형" required>
          <LoginFormRadio label="회원" />
          <LoginFormRadio label="결제" />
          <LoginFormRadio label="서비스" />
          <LoginFormRadio label="기타" />
        </LoginFormRadioGroupField>

        <LoginFormTextField label="제목" placeholder="제목을 입력해주세요" items="flex-start" required />

        <div class="qna-container__form-wrapper__textarea">
          <LoginFormTextField label="문의 내용" type="textarea" placeholder="문의 내용을 작성" items="flex-start" required />
          <div class="qna-container__form-wrapper__textarea__btn-file">
            <button type="button">파일 첨부</button>
          </div>
          <LoginFormFileField class="file" label="문의 내용" file-name="스팸 차단 취소 작성 서류.pdf" />
          <LoginFormFileField class="file" label="문의 내용" file-name="스팸 차단 취소 작성 서류.pdf" />
          <div class="qna-container__form-wrapper__textarea__file-desc">
            <div>첨부 가능 : jpg, jpeg, gif, png, tiff, hwp, pdf, xls, xlsx, ppt, pptx, doc, docx</div>
            <div style="font-weight: 700;">
              (<strong>4 MB</strong> / 5 MB)
            </div>
          </div>
        </div>

        <LoginFormTextField label="회사명" placeholder="회사명을 입력해주세요" items="flex-start" required />
        <LoginFormTextField label="성함" placeholder="성함을 입력해주세요" items="flex-start" required />

        <div class="d-flex align-items-center qna-container__form-wrapper__email">
          <LoginFormTextField style="flex: 1;" label="이메일" placeholder="이메일 주소를 입력해주세요" items="flex-start" required />

          <span>@</span>

          <b-dropdown variant="primary">
            <template #button-content>
              <span>선택</span>
              <IconArrowDown />
            </template>
            <b-dropdown-text>
              <span>gmail.com</span>
            </b-dropdown-text>
            <b-dropdown-text>
              <span>naver.com</span>
            </b-dropdown-text>
          </b-dropdown>
        </div>

        <LoginFormTextField label="연락처" placeholder="연락처를 입력해주세요" items="flex-start" required />
      </LoginForm>
    </div>

    <div class="qna-container__terms">
      <LoginFormSingleCheckboxField class="checkbox-terms">
        <template #label>
          <div>
            <strong style="color: #6D6EFA">[필수]</strong>
            <span>&nbsp;개인정보 수집, 이용에 동의합니다.</span>
          </div>
        </template>
      </LoginFormSingleCheckboxField>

      <button>약관보기</button>
    </div>

    <button class="btn-post">문의 등록</button>

    <AlertModal id="required-type" title="문의 유형 입력 요청" desc="문의 유형은 필수사항입니다." />
    <AlertModal id="required-email" title="이메일 입력 요청" desc="이메일은 필수사항입니다." />
    <AlertModal id="required-title" title="제목 입력 요청" desc="제목은 필수사항입니다." />
    <AlertModal id="required-contact" title="연락처 입력 요청" desc="연락처는 필수사항입니다." />
    <AlertModal id="required-content" title="문의 내용 입력 요청" desc="문의 내용은 필수사항입니다." />
    <AlertModal id="required-attachment" title="첨부파일 용량 확인" desc="첨부 파일 용량은 5MB를 넘을 수 없습니다." />
    <AlertModal id="required-name" title="이름 입력 요청" desc="이름은 필수사항입니다." />
    <AlertModal id="required-terms" title="개인정보 수집, 이용 미동의" desc="개인정보 수집, 이용 미동의 시 서비스를 이용하실 수 없습니다." />

    <AlertModal id="complete" title="인증 이메일 발송 완료" desc="작성하신 문의가 등록 완료되었습니다. 문의하신 내용의 답변은 이메일로 발송됩니다." />
  </div>
</template>

<script>
import LoginForm from '../../../components/landing/login/LoginForm.vue'
import LoginFormRadioGroupField from '../../../components/landing/login/LoginFormRadioGroupField.vue'
import LoginFormRadio from '../../../components/landing/login/LoginFormRadio.vue'
import LoginFormTextField from '../../../components/landing/login/LoginFormTextField.vue'
import LoginFormFileField from '../../../components/landing/login/LoginFormFileField.vue'
import LoginFormSingleCheckboxField from '../../../components/landing/login/LoginFormSingleCheckboxField.vue'
import IconArrowDown from '../../../components/service/icons/IconArrowDown.vue'
import AlertModal from '../../../components/service/modal/AlertModal.vue'

export default {
  name: "InquiryForm",

  components: {
    LoginForm,
    LoginFormRadioGroupField,
    LoginFormRadio,
    LoginFormTextField,
    LoginFormFileField,
    LoginFormSingleCheckboxField,
    IconArrowDown,
    AlertModal
  }
};
</script>


<style lang="scss" scoped>
  .qna-container {
    margin-top: 38px;
    padding: 60px 0;
    line-height: 1.4;

    @media (min-width: 300px) {
        padding: 20px 0;
    }

    @media (min-width: 375px) {
        padding: 20px 0;
    }

    @media (min-width: 770px) {
      padding: 60px 0;
    }

    @media (min-width: 1025px) {
      padding: 60px 0;
    }

  &__title {
    color: #111827;
    font-size: 28px;
    font-weight: 700;

    @media (min-width: 300px) {
      font-size: 20px;
    }

    @media (min-width: 375px) {
      font-size: 20px;
    }

    @media (min-width: 1024px) {
      font-size: 28px;
    }
  }

  &__form-wrapper {
    background-color: #F9FAFB;
    border: 1px solid #E5E7EB;

    @media (min-width: 300px) {
      padding: 32px 20px;
      margin: 20px 0;
      border-radius: 12px;
    }

    @media (min-width: 375px) {
      padding: 32px 20px;
      margin: 20px 0;
      border-radius: 12px;
    }

    @media (min-width: 1024px) {
      padding: 48px 40px;
      margin: 40px 0;
      border-radius: 20px;
    }

    @media (min-width: 1280px) {
      padding: 60px;
      margin: 40px 0 80px;
      border-radius: 20px;
    }

    &__textarea {
      &__btn-file {
        position: relative;

        button {
          position: absolute;
          right: 16px;
          bottom: 16px;
          background-color: #4B5563;
          color: #fff;
          font-size: 14px;
          line-height: 1.8;
          font-weight: 400;
          z-index: 1;

          @media (min-width: 300px) {
            padding: 3.5px 11px;
            border-radius: 6px;
          }

          @media (min-width: 375px) {
            padding: 3.5px 11px;
            border-radius: 6px;
          }

          @media (min-width: 1024px) {
            padding: 3.5px 11px;
            border-radius: 6px;
          }
        }
      }

      &__file-desc {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        margin-top: 8px;
        font-size: 12px;
        color: #9CA3AF;

        strong {
          color: #374151;
        }

        @media (min-width: 300px) {
          padding-left: 0;
          flex-direction: column;
        }

        @media (min-width: 375px) {
          padding-left: 0;
          flex-direction: column;
        }

        @media (min-width: 1024px) {
          padding-left: 222px;
          flex-direction: row;
        }
      }

      :deep textarea {
        height: 184px;
        margin-bottom: 4px;

        @media (min-width: 300px) {
          height: 144px;
        }

        @media (min-width: 375px) {
          height: 144px;
        }

        @media (min-width: 1024px) {
          height: 184px;
        }
      }

      :deep .login-form__field.file {
        .login-form__field__label {
          opacity: 0;

          @media (min-width: 300px) {
            display: none;
          }

          @media (min-width: 375px) {
            display: none;
          }

          @media (min-width: 1024px) {
            display: initial;
          }
        }

        .login-form__field__input {
          display: none;
        }

        .login-form__field__input__file-name {
          margin-top: 4px;
          font-weight: 500;
        }
      }
    }

    &__email {
      display: flex;
      align-items: center;
      font-size: 18px;

      >span {
        margin: 0;
        padding: 0 12px;
        font-weight: 500;
        color: #6B7280;

        @media (min-width: 300px) {
          margin-top: 32px;
        }

        @media (min-width: 375px) {
          margin-top: 32px;
        }

        @media (min-width: 1024px) {
          margin: 0;
        }
      }

      :deep .b-dropdown {
        margin: 0;
        background-color: #ffffff;
        border: 1px solid #D1D5DB;
        border-radius: 8px;

        @media (min-width: 300px) {
          margin-top: 30px;
        }

        @media (min-width: 375px) {
          margin-top: 32px;
        }

        @media (min-width: 1024px) {
          margin: 0;
        }
      }

      :deep .b-dropdown .dropdown-toggle,
      :deep .b-dropdown .dropdown-toggle:hover {
        display: flex !important;
        justify-content: space-between !important;
        align-items: center !important;
        width: 220px !important;
        padding: 9.5px 16px !important;
        box-sizing: border-box !important;

        @media (min-width: 300px) {
          width: 70px !important;
        }

        @media (min-width: 375px) {
          width: 100px !important;
        }

        @media (min-width: 1024px) {
          width: 220px !important;
        }

        span {
          font-weight: 400;
          font-size: 14px;
          line-height: 1.8;
          color: #6B7280 !important;

          @media (min-width: 300px) {
            font-size: 12px;
          }

          @media (min-width: 375px) {
            font-size: 12px;
          }

          @media (min-width: 1024px) {
            font-size: 14px;
          }
        }

        &::after {
          content: none;
        }
      }

      :deep .b-dropdown .dropdown-menu {
        margin-top: 4px;
        width: 100%;
        border-radius: 8px;
        border: 1px solid #D1D5DB;
        box-shadow: 0 6px 12px 0px #62688A3D;

        li {
          padding: 12px 16px;
          border-bottom: 1px solid #D1D5DB;

          .b-dropdown-text span {
            font-size: 14px;
          }
        }
      }
    }

    :deep .login-form {
      --gap: 60px !important;

      @media (min-width: 300px) {
        --gap: 40px !important;
      }

      @media (min-width: 375px) {
        --gap: 40px !important;
      }

      @media (min-width: 1024px) {
        --gap: 60px !important;
      }
    }

    :deep .login-form__field {
      @media (min-width: 300px) {
        gap: 12px;
      }

      @media (min-width: 375px) {
        gap: 12px;
      }

      @media (min-width: 1024px) {
        gap: 120px;
      }
    }

    :deep .login-form__field__label {
      width: 102px;
      min-width: 102px;

      label {
        font-size: 20px;
        font-weight: 500;
        color: #111827;

        @media (min-width: 300px) {
          font-size: 14px;
        }

        @media (min-width: 375px) {
          font-size: 14px;
        }

        @media (min-width: 1024px) {
          font-size: 20px;
        }
      }
    }
  }

  &__terms {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
    margin-bottom: 80px;
    border-bottom: 1px solid #D1D5DB;
    
    @media (min-width: 300px) {
      margin-bottom: 40px;
    }

    @media (min-width: 375px) {
      margin-bottom: 40px;
    }

    @media (min-width: 1024px) {
      margin-bottom: 80px;
    }

    >button {
      padding: 12px;
      background-color: #E9E9FE;
      border-radius: 8px;
      line-height: 1.43;
      font-size: 14px;
      color: #6D6EFA;

      @media (min-width: 300px) {
        padding: 3.5px 11px;
        border-radius: 6px;
        line-height: 1.8;
        font-weight: 400;
      }

      @media (min-width: 375px) {
        padding: 3.5px 11px;
        border-radius: 6px;
        line-height: 1.8;
        font-weight: 400;
      }

      @media (min-width: 1024px) {
        padding: 12px;
        border-radius: 8px;
        line-height: 1.43;
        font-weight: 700;
      }
    }
  }
}

.checkbox-terms {
  @media (min-width: 300px) {
    justify-content: flex-start;
    align-items: flex-start !important;
  }

  @media (min-width: 375px) {
    justify-content: flex-start;
    align-items: flex-start !important;
  }

  @media (min-width: 1024px) {
    justify-content: flex-start;
    align-items: center !important;
  }

  :deep label {
    font-size: 24px;

    @media (min-width: 300px) {
      font-size: 13px;
    }

    @media (min-width: 375px) {
      font-size: 14px;
    }

    @media (min-width: 1024px) {
      font-size: 24px;
    }

    span {
      color: #111827;
    }
  }
}

.btn-post {
  display: block;
  margin: 0 auto;
  padding: 0 91px;
  width: fit-content;
  height: 52px;
  background-color: #6D6EFA;
  border-radius: 12px;
  border: 0;
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 700;

  @media (min-width: 300px) {
    padding: 0;
    width: 100%;
    font-size: 14px;
  }

  @media (min-width: 375px) {
    padding: 0;
    width: 100%;
    font-size: 14px;
  }

  @media (min-width: 1024px) {
    padding: 0 91px;
    width: fit-content;
    font-size: 16px;
  }
}
</style>
