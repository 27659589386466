var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"signup-wrapper__container__contents__wrapper"},[_c('div',{staticClass:"signup-wrapper__container__contents__wrapper__header"},[_vm._v(" 회원가입 ")]),_c('div',{staticClass:"signup-wrapper__container__contents__wrapper__main"},[_c('div',{staticClass:"signup-wrapper__container__contents__wrapper__main__title"},[_vm._v(" 3. 영업사원 ")]),_c('div',{staticClass:"signup-wrapper__container__contents__wrapper__main__form"},[_c('LoginForm',[_c('div',{staticStyle:{"display":"flex","gap":"4px","align-items":"flex-end","flex":"1"}},[_vm._l((_vm.fields),function(field){return _c('LoginFormTextField',{key:field.label,staticStyle:{"flex":"1"},attrs:{"label":field.label,"type":field.type,"placeholder":field.placeholder,"desc":field.desc,"items":"flex-start","readonly":field.readonly},model:{value:(field.value),callback:function ($$v) {_vm.$set(field, "value", $$v)},expression:"field.value"}})}),_c('button',{on:{"click":function($event){return _vm.$bvModal.show('signup-modal')}}},[_vm._v("이름 검색")])],2)])],1)]),_c('SignupModal',{attrs:{"title":"영업사원 선택","buttons":{
    cancel: { text: '닫기' },
    confirm: { text: '선택' },
  }},scopedSlots:_vm._u([{key:"signup-contents",fn:function(){return [_c('div',{staticStyle:{"display":"flex","flex-direction":"column","height":"100%","overflow":"hidden"}},[_c('SearchBox'),_c('div',{staticStyle:{"width":"100%","min-height":"20px"}}),_c('div',{staticStyle:{"flex":"1","overflow":"auto"}},[_c('SignupModalTable',{attrs:{"data":Array.from({ length: 20 }, () => ([
            { name: '사원명', value: '김영서' },
            { name: '전화번호', value: '010-****-**00' },
          ]))}})],1)],1)]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }