<template>
  <div :class="className" :style="style">
    <div v-if="label" class="login-form__field__label" :style="labelStyle">
      <label :for="id" :class="{ required }">
        {{ label }}
      </label>
    </div>

    <slot />
  </div>
</template>

<script>
export default {
  name: 'LoginFormField',

  props: {
    justify: {
      type: String,
      default: 'flex-start'
    },
    items: {
      type: String,
      default: 'center'
    },
    id: {
      type: String,
      default: () => `id-${crypto.randomUUID()}`
    },
    row: Boolean,
    label: String,
    labelStyle: Object,
    required: Boolean,
  },

  computed: {
    className() {
      const { row } = this.$props

      return { 'login-form__field': true, row }
    },

    style() {
      const { justify, items } = this.$props

      return {
        'justify-content': justify,
        'align-items': items
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.login-form__field {
  display: flex;
  gap: 12px;

  &.row {
    margin: 0 !important;
    flex-direction: row;
  }

  @media (min-width: 300px) {
    flex-direction: column;
    gap: 8px;
  }

  @media (min-width: 375px) {
    flex-direction: column;
    gap: 8px;
  }

  @media (min-width: 1024px) {
    flex-direction: row;
    gap: 12px;
  }

  &__label {
    display: flex;
    align-items: flex-start;
    width: 120px;
    min-width: 120px;
    font-size: 16px;
    font-weight: 500;
    gap: 2px;
    user-select: none;
    
    label {
      position: relative;
      margin: 0;
      color: #6B7280;

      &.required::after {
        position: absolute;
        top: -1px;
        right: -11px;
        color: #FF594F;
        content: '*';
      }

      @media (min-width: 375px) {
        font-size: 14px;
      }

      @media (min-width: 1024px) {
        font-size: 16px;
      }
    }
  }
}
</style>