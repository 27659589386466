<template>
  <b-modal 
    v-model="imgPreviewOpen"
    id="image-preview-modal"
    centered
    size="lg"
    @hidden="fnClose"
  >
    <div class="text-center">
      <img :src="imageUrl" class="img-fluid" />
    </div>
    <template #modal-footer>
      <b-button @click="fnClose" variant="outline-primary">닫기</b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: "imageUploadPopup",
  props: {
    imgPreviewOpen: {
      type: Boolean,
      require: true,
      default: false
    }
  },
  data() {
    return {
      imageUrl : ''
    }
  },
  methods: {
    //이미지 파일 열기
    fnOpenImg(imageUrl){
      this.imageUrl = imageUrl;
      //this.imageUrl = require('@/assets/images/uploadImage/20210405_6596874356127434594.jpg')
    },
    //파일업로드팝업 초기화
    fnClose(){
      this.$emit('update:imgPreviewOpen', false)
    },
  }
}
</script>