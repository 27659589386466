<template>
  <div class="uc-message-send-sms">
    <MessageTabs />

    <div class="d-flex tab-contents">
      <div class="card">
        <div class="preview p-sticky">
          <p class="preview-title">미리보기</p>
          <PreviewDefault size="lg">
            <div class="preview-section sms" v-if="(sendData.senderType == 'MMS' || sendData.senderType == 'LMS')">
              <div v-if="$gfnCommonUtils.isEmpty(sendData.smsTitle)" class="title">
                메시지 제목
              </div>
              <div v-else class="title">
                {{sendData.smsTitle}}
              </div>
            </div>
            <div class="preview-section sms" style="margin-top: 10px;">
              <div v-if="sendData.senderType == 'MMS'" class="content">
                <div v-for="(imgInfo, idx) in sendData.imgInfoList" :key="idx"
                    :style="'padding:65px;background-image: url('+imgInfo.imgUrl+');'">
                </div>
              </div>
              <div>{{sendData.smsContent}}</div>
              <br v-if="!$gfnCommonUtils.isEmpty(sendData.smsContent)"/>
              <span v-if="sendData.msgKind == 'A' && !$gfnCommonUtils.isEmpty(sendData.rcvblcNumber)">
                {{sendData.rcvblcNumber}}
              </span>
            </div>
          </PreviewDefault>
        </div>
      </div>

      <div class="section card">
        <p class="section-title f-body1 c-gray700">01.메세지 내용</p>
        <div class="d-flex align-items-center">
          <label class="form-label">발송유형</label>
          <b-form-group class="radio-group m-0">
            <b-form-radio-group inline v-model="sendData.senderType" @change="fnContentAdAdd('', sendData.senderType)">
              <b-form-radio name="type" value="SMS">SMS</b-form-radio>
              <b-form-radio name="type" value="LMS">LMS</b-form-radio>
              <b-form-radio name="type" value="MMS">MMS</b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="d-flex align-items-center my-1 py-3">
          <label class="form-label">메시지 구분<span class="require">*</span></label>
          <b-form-group class="radio-group">
            <b-form-radio-group inline v-model="sendData.msgKind" @change="fnContentAdAdd(sendData.msgKind, '')">
              <b-form-radio name="some-radios" value="A">광고성</b-form-radio>
              <b-form-radio name="some-radios" value="I">정보성</b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="btn-wrap">
          <b-button variant="outline-primary" size="sm" v-b-modal.select-template-modal>템플릿 불러오기</b-button>
          <!-- <b-button v-show="sendData.senderType === 'MMS' || sendData.senderType === 'LMS'" variant="outline-primary" size="sm" v-b-modal.add-mms-content-modal>내용입력</b-button> -->
          <b-button variant="outline-primary" size="sm" v-b-modal.add-content-modal>내용입력</b-button>
        </div>

        <div v-if="sendData.senderType === 'MMS'" class="d-flex align-items-start image-select">
          <label class="form-label">이미지</label>
          <b-button variant="outline-primary" size="sm" v-b-modal.select-image-modal>이미지 선택</b-button>
          <ul class="image-select-list">
            <li>
              <span class="text-truncate">jpghttps://api.msghub-dev.uplus.co.kr/jpghttps://api.msghub-dev.uplus.co.kr/ </span>
              <button type="button" class="btn btn-icon p-0">
                <IconClose />
              </button>
            </li>
            <li>
              <span class="text-truncate">jpghttps://api.msghub-dev.uplus.co.kr/... </span>
              <button type="button" class="btn btn-icon p-0">
                <IconClose />
              </button>
            </li>
            <li>
              <span class="text-truncate">jpghttps://api.msghub-dev.uplus.co.kr/... </span>
              <button type="button" class="btn btn-icon p-0">
                <IconClose />
              </button>
            </li>
          </ul>
        </div>

        <hr class="hr">

        <p class="section-title f-body1 c-gray700">02.수신자 선택</p>
        <div class="d-flex align-items-center">
          <label class="form-label">수신자<span class="require">*</span></label>
          <div class="p-0 btn-wrap">
            <b-button variant="outline-primary" size="sm" class="btn-svg btn-svg-right" v-b-modal.enter-receiver-modal>
              <span>수신자 직접입력</span>
              <IconArrowRight />
            </b-button>
            <b-button variant="outline-primary" size="sm" class="btn-svg btn-svg-right" v-b-modal.search-address-modal>
              <span>주소록 검색</span>
              <IconArrowRight />
            </b-button>
            <b-button variant="outline-primary" size="sm" class="btn-svg btn-svg-right" @click="triggerFileInput">
              <span>엑셀 업로드</span>
              <IconArrowRight />
            </b-button>
            <b-button variant="light" class="btn btn-sm btn-svg btn-svg-right btn-sample-download">
              <span>샘플 다운로드</span>
              <IconDownload />
            </b-button>
            <b-form-file class="d-none" plain ref="fileInput"></b-form-file>
          </div>
        </div>
        <div class="d-flex align-items-center receive-count">
          <p class="m-0 mr-1 pr-3 f-body5 c-gray600">수신자: <span class="text-primary">0명</span></p>
          <b-button variant="outline-secondary" size="sm" :disabled="sendData.senderType === 'SMS'">수신자 모두삭제</b-button>
        </div>
        <div v-if="sendData.senderType !== 'SMS'" class="receive-table">
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th>매체</th>
                  <th>번호</th>
                  <th>기타</th>
                  <th class="text-center">삭제</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Phone</td>
                  <td>010-0000-0000</td>
                  <td>mergeData</td>
                  <td class="text-center">
                    <button class="btn btn-icon p-0">
                      <IconClose />
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <hr class="hr">

        <p class="section-title f-body1 c-gray700">03.발송옵션 선택</p>
        <div class="d-flex align-items-center">
          <label class="form-label">발송시간<span class="require">*</span></label>
          <b-form-group class="radio-group">
            <b-form-radio-group inline v-model="time">
              <b-form-radio name="time" value="now" >즉시</b-form-radio>
              <b-form-radio name="time" value="book">예약</b-form-radio>
            </b-form-radio-group>
          </b-form-group>
          <div v-if="time === 'book'" class="book-form">
            <CustomDatepicker />
            <vue-timepicker v-model="startTime" format="hh" :hour-range="[0, 23]" class="book-time"></vue-timepicker>
            <span class="time-divider">:</span>
            <vue-timepicker v-model="endTime" format="hh" :hour-range="[0, 23]" class="book-time"></vue-timepicker>
          </div>
        </div>

        <hr class="hr">

        <p class="section-title f-body1 c-gray700">04.발신자 선택</p>
        <div class="d-flex align-items-center">
          <label class="form-label">발신번호<span class="require">*</span></label>
          <b-dropdown id="number-dropdown" variant="secondary" class="send-number-dropdown">
            <template #button-content>
              <span>{{ phoneNumber === '' ? '선택' : phoneNumber }}</span>
              <IconArrowDown />
            </template>
            <b-dropdown-item-button
      v-for="(callback, index) in sendData.callbackList"
      :key="index"
      @click="setPhoneNumber(callback.callback)">
      {{ callback.callback | hpNumberAddDash }}
    </b-dropdown-item-button>
          </b-dropdown>
        </div>
        <hr class="hr">
        <div class="submit-wrap">
          <b-button variant="secondary" size="lg" v-b-modal.send-test-modal>테스트 발송</b-button>
          <b-button variant="primary" size="lg">발송</b-button>
        </div>
      </div>
    </div>
    <SelectTemplateModal @select-template="fnSetTemplateInfo" :sender-type="sendData.senderType" :key="sendData.senderType"/>
    <AddContentModal :sendData="sendData" :isSpecialBusi="isSpecialBusi"/>
    <AddMMSContentModal />
    <SelectImageModal />
    <SendTestModal />
    <SearchAddressModal />
    <EnterReceiverModal :contsVarNms="sendData.contsVarNms" :requiredCuPhone="sendData.requiredCuPhone" :requiredCuid="sendData.requiredCuid" :recvInfoLst="sendData.recvInfoLst"/>

  </div>
</template>

<script>
import IconArrowRight from '@/components/service/icons/IconArrowRight.vue';
import IconArrowDown from '@/components/service/icons/IconArrowDown.vue';
import IconDownload from '@/components/service/icons/IconDownload.vue';
import MessageTabs from '@/components/service/message/MessageTabs.vue';
import SearchAddressModal from '@/components/service/modal/SearchAddressModal.vue';
import SelectTemplateModal from '@/modules/message/components/modal/SelectTemplateModal.vue';
import AddContentModal from '@/modules/message/components/modal/AddContentModal.vue';
import AddMMSContentModal from '@/modules/message/components/modal/AddMMSContentModal.vue';
import SelectImageModal from '@/modules/message/components/modal/SelectImageModal.vue';
import SendTestModal from '@/modules/message/components/modal/SendTestModal.vue';
import EnterReceiverModal from '@/modules/message/components/modal/EnterReceiverModal.vue';
import IconClose from '@/components/service/icons/IconClose.vue';
import CustomDatepicker from '@/components/service/form/CustomDatepicker.vue'
import PreviewDefault from '@/components/service/preview/PreviewDefault.vue';
import tokenSvc from '@/common/token-service';
import messageApi from "@/modules/message/service/messageApi.js";
import confirm from "@/modules/commonUtil/service/confirm.js";
import {eventBus} from "@/modules/commonUtil/service/eventBus";
import XLSX from 'xlsx';

export default {
  components: { 
    IconArrowRight, 
    IconArrowDown, 
    IconDownload, 
    SelectTemplateModal, 
    AddContentModal, 
    IconClose, 
    AddMMSContentModal, 
    SelectImageModal, 
    SendTestModal, 
    SearchAddressModal, 
    EnterReceiverModal, 
    CustomDatepicker, 
    MessageTabs, 
    PreviewDefault, 
  },
  name: "ucMessageSendSms",
  data() {
    return {
      phoneNumber: '',
      showPreviewTitle: false,
      time: 'now',
      startTime: null,
      endTime: null,
      smsTemplateOpen : false,
      smsContsOpen : false,
      imgMngOpen : false,
      directInputOpen : false,
      addressInputOpen : false,
      testSendInputOpen : false,
      imgLimitSize : 3,
      recvCnt : 0,  //수신자명수
      isSpecialBusi : !this.$gfnCommonUtils.isEmpty(tokenSvc.getToken().principal.bizType),
      inProgress: false,
      tempFile: [],
      beforeCuInputType: 'DICT',
      monthAmount : 0,
      monSenderLimitAmout : '없음',
      feeType : false,
      sendData : {
        chGrp: 'SMS/MMS',
        callback: '',  //발신번호
        callbackList : [],
        requiredCuid : false,  //app 로그인 ID 필수여부
        requiredCuPhone : true,  //수신자 폰번호 필수여부
        senderType: 'SMS',  //SMS, MMS
        msgKind: 'A',
        cuInputType:'',  //DICT, ADDR, EXCEL
        smsTitle:'',  //제목
        smsContent:'',  //내용
        rcvblcNumber:'',  //수신거부
        cuInfo:'',
        rsrvSendYn:'N',  //예약발송여부
        rsrvDate:this.$gfnCommonUtils.getCurretDate(),
        rsrvHH:'00',
        rsrvMM:'00',
        campaignId:'',
        imgInfoList: [],
        recvInfoLst: [],  //수신자정보
        contsVarNms: [], //메세지 내용 변수명
        testRecvInfoLst: [],  //테스트 수신자정보
        excelLimitRow: 0
      },
      nightSendSthh: '',
      nightSendStmm: '',
      nightSendEdhh: '',
      nightSendEdmm: '',
      nightSendYn : 'N',
      nightSendLimitYn : false
    }
  },
  watch : {
    recvCnt (newval) {
      if(newval>30000) {
        confirm.fnAlert(this.componentsTitle, "발송 최대 수신자 수는 30000명을 넘길 수 없습니다.");
        this.fnRemoveRecvInfo();
      }
    }
  },
  mounted() {
    this.fnExistApiKey();
    this.fnValidUseChGrp();
    this.fnSelectCallbackList();
    this.fnNightSendTime();
  },
  methods: {
    setPhoneNumber(value) {
      this.phoneNumber = value;
    },
    triggerFileInput() {
      // 파일 입력 요소 클릭
      this.$refs.fileInput.$el.click();
    },
    fnRemoveRecvInfo(){
      this.fnCallbackRecvInfoLst(null);
      this.$refs.excelFile.value = '';
      this.tempFile = [];
    },
    fnReadFile(){
      if(this.$refs.excelFile.files && this.$refs.excelFile.files.length > 0){
        const file = this.$refs.excelFile.files[0];
        let reader = new FileReader();

        reader.onload = () => {
          let data = reader.result;
          let workbook = XLSX.read(data, {type: 'binary'});
          let sheetName = '';
          let excelArray = [];

          if(workbook.SheetNames && workbook.SheetNames.length > 0){
            sheetName = workbook.SheetNames[0];
          }
          if(this.$gfnCommonUtils.isEmpty(sheetName) == false){
            excelArray = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
          }

          let recvArr = [];

          for(var i=0; i<excelArray.length; i++) {
            if(i!=(excelArray.length-1)) recvArr[i] = excelArray[i+1].Template;
          }

          recvArr = new Set(recvArr);

          this.sendData.excelLimitRow = (excelArray.length-1);

          this.recvCnt = recvArr.size;
        };
        reader.readAsBinaryString(file);
      } else {
        this.recvCnt = 0;
      }
    },
    fnReset(){
      Object.assign(this.$data, this.$options.data.apply(this));
      // 발송 처리 후 발신자 세팅
      this.fnSelectCallbackList();
      this.fnNightSendTime();
    },
    async fnExistApiKey(){
      let params = {};
      await messageApi.selectApiKey(params).then(response =>{
        const result = response.data;
        if(result.success) {
          if(this.$gfnCommonUtils.isEmpty(result.data)){
            confirm.fnAlert(this.componentsTitle, '해당 프로젝트의 사용가능한 API 키가 존재하지 않습니다.\n메시지 발송하실 수 없습니다.');
          } else {
            // 사용가능한 api 키가 존재하면 발송제한 금액 세팅
            this.fnSetSentAmount();
          }
        }
      });
    },
    async fnValidUseChGrp(){
      let params = {chGrp: this.sendData.chGrp};
      await messageApi.selectValidUseChGrp(params).then(response =>{
        const result = response.data;
        if(result.success) {
          if(this.$gfnCommonUtils.isEmpty(result.data)){
            confirm.fnAlert(this.componentsTitle, '이용하실 수 없는 채널입니다.');
            this.$router.back();
          }
        } else {
          confirm.fnAlert(this.componentsTitle, '시스템 오류입니다. 잠시 후 다시 시도하세요.');
          this.$router.back();
        }
      });
    },
    fnSetSentAmount() {
      let params = {};
      var vm = this;
      messageApi.setSentAmout(params).then(response =>{
        const result = response.data;
        if(result.success && result.data.amountMap != null) {
          let resultData = result.data;
          vm.monthAmount = resultData.amountMap.month + "원";
          vm.monSenderLimitAmout = resultData.returnApiKeyMap.monSenderLimitAmount+"원";

          if(resultData.returnApiKeyMap.feeType == "PRE") {
            vm.feeType = false;
          } else {
            vm.feeType = true;
          }
        }
      });
    },
    //발송 정보 유효성 체크
    fnValidSendMsgData(testSendYn){
      const {
        callback, senderType, smsTitle,
        smsContent, msgKind, rcvblcNumber,
        testRecvInfoLst, cuInputType, recvInfoLst,
      } = this.sendData

      if(this.fnSetContsVarNms() == false){
        return false;
      }
      if(!callback && !this.isSpecialBusi){
        confirm.fnAlert(this.componentsTitle, '발신번호를 선택해주세요.');
        return false;
      }
      if(senderType == 'MMS' && !smsTitle){
        confirm.fnAlert(this.componentsTitle, '제목을 입력해주세요.');
        return false;
      }
      if(!smsContent){
        confirm.fnAlert(this.componentsTitle, '내용을 입력해주세요.');
        return false;
      }
      // 메시지 구분 : 광고성, 수신거부번호 미입력 시
      if(msgKind && msgKind === 'A'){
        if(!rcvblcNumber || rcvblcNumber === '') {
          confirm.fnAlert(this.componentsTitle, '광고성 문자는 수신거부번호를 필수로 입력해야 합니다.');
          return false;
        }
      }

      if(testSendYn == 'Y'){
        if(!testRecvInfoLst == null || testRecvInfoLst.length == 0){
          confirm.fnAlert(this.componentsTitle, '테스트 수신자 정보를 입력해주세요.');
          return false;
        }
      } else {
        if(cuInputType == 'DICT' || cuInputType == 'ADDR'){
          if(!recvInfoLst == null || recvInfoLst.length == 0){
            confirm.fnAlert(this.componentsTitle, '수신자 정보를 입력해주세요.');
            return false;
          }
        }
        if(cuInputType == 'EXCEL'){
          if(this.$refs.excelFile.value != 0){
            this.tempFile = [];
            this.tempFile.push.apply(this.tempFile, this.$refs.excelFile.files);
          }
          const uploadFile = this.tempFile[0];
          if(!uploadFile){
            confirm.fnAlert(this.componentsTitle, '엑셀파일을 등록해주세요.');
            return false;
          }
          const permitExten = 'xls,xlsx'.split(',');
          const extnIdx = uploadFile.name.lastIndexOf('.');
          const extn = uploadFile.name.substring(extnIdx+1);
          if((permitExten.indexOf(extn) < 0)){
            confirm.fnAlert(this.componentsTitle, '허용되지 않는 확장자입니다.');
            return false;
          }
        }
      }

      return true;
    },
    //메시지 발송 처리
    async fnSendSmsMessage(testSendYn){
      if(this.inProgress){
        confirm.fnAlert(this.componentsTitle, 'SMS 메시지 발송 처리중입니다.');
        return;
      }

      //유효성 체크
      if(this.fnValidSendMsgData(testSendYn) == false) return;

      //야간밸리데이션
      if(testSendYn != 'Y' && this.fnNightSendCheck() == false) return;

      //발송처리
      let params = Object.assign({}, this.sendData);
      params.testSendYn = testSendYn;

      if(testSendYn == 'Y'){
        params.recvInfoLst = Object.assign([], this.sendData.testRecvInfoLst);
        params.cuInputType = 'DICT';
        //테스트 발송은 즉시발송만 가능
        params.rsrvSendYn = 'N';
      }

      params.smsTitle = params.smsTitle.replace("(광고)","");
      params.smsContent = params.smsContent.replace("(광고)","");

      let fd = new FormData();
      if(this.sendData.cuInputType == 'EXCEL'){
        fd.append('file', this.tempFile[0]);
        this.$refs.excelFile.value = '';
        this.tempFile = [];
      }
      fd.append('paramString', JSON.stringify(params));

      this.inProgress = true;
      const vm = this;

      let sendYn = window.confirm("발송하시겠습니까?");

      if(sendYn){
        if(this.sendData.senderType == 'SMS'){
          await messageApi.sendSmsMessage(fd).then(response => this.fnSendCallBack(response, testSendYn))
          .catch(function () { vm.inProgress = false; });
          } else if(this.sendData.senderType == 'MMS' || this.sendData.senderType == 'LMS'){
            await messageApi.sendMmsMessage(fd).then(response => this.fnSendCallBack(response, testSendYn))
            .catch(function () { vm.inProgress = false; });
          }
      }else{
        this.inProgress = false;
        return;
      }

      // 발송제한 금액 세팅
      this.fnSetSentAmount();
    },
    fnSendCallBack(response, testSendYn){
      this.inProgress = false;
      const result = response.data;

      if(result.success) {
        if(testSendYn == 'Y'){
          if(!this.$gfnCommonUtils.isEmpty(result.message)){
            confirm.fnAlert(this.componentsTitle, result.message);
          } else {
            confirm.fnAlert(this.componentsTitle, '발송하였습니다.');
          }
        } else {
          if(result.data != null && !this.$gfnCommonUtils.isEmpty(result.data.feeMsg)){
            eventBus.$on('callbackEventBus', this.fnAlertFeeMsgCallBack);
            confirm.fnAlert(this.componentsTitle, result.data.feeMsg, 'ALERT', result);
          } else {
            this.fnAlertFeeMsgCallBack(result);
          }
        }
      } else {
        confirm.fnAlert(this.componentsTitle, result.message);
      }
    },
    fnAlertFeeMsgCallBack(result){
      if(this.$gfnCommonUtils.isEmpty(result.message)){
        confirm.fnAlert(this.componentsTitle, '발송 요청 처리 되었습니다.');
      } else {
        confirm.fnAlert(this.componentsTitle, result.message);
      }
      //this.fnReset();
    },
    fnClickCuInputType(e){
      this.tempFile = [];
      this.tempFile.push.apply(this.tempFile, this.$refs.excelFile.files);
      this.$refs.excelFile.value = '';

      if(this.sendData.cuInputType == e.target.value){
        this.fnChgCuInputType('N');
      }
      this.beforeCuInputType = this.sendData.cuInputType;
    },
    //수신자 입력 타입 변경시
    fnChgCuInputType(chgYn){
      if(this.$gfnCommonUtils.defaultIfEmpty(chgYn, 'Y') == 'Y'){
        if((this.beforeCuInputType != 'DICT' && this.beforeCuInputType != 'ADDR')
          || (this.sendData.cuInputType != 'DICT' && this.sendData.cuInputType != 'ADDR')){
          this.fnCallbackRecvInfoLst(null);  //수신자 입력 타입 변경시 수신자 정보 초기화
        }
      }
      if(!this.sendData.smsContent){
        confirm.fnAlert(this.componentsTitle, '메시지 내용을 먼저 입력해주세요.');
        return;
      }
      if(this.fnSetContsVarNms() == false){
        return;
      }
      if(this.sendData.cuInputType == 'DICT'){  //직접입력
        //수신자 직접입력 팝업 호출
        this.directInputOpen = true;
      } else if(this.sendData.cuInputType == 'ADDR'){  //주소록
        //주소록 검색 팝업 호출
        this.addressInputOpen = true;
      } else if(this.sendData.cuInputType == 'EXCEL'){  //엑셀
        //엑셀파일찾기 호출
        this.$refs.excelFile.click();
      }
    },
    fnSetContsVarNms(){
      const rsvNmSet = new Set(['phone']);
      const conts = this.sendData.smsContent;
      let varNms = [];
      let containRsvNm = false;
      conts.replace(/#\{(([a-z|A-Z|0-9|ㄱ-ㅎ|ㅏ-ㅣ|가-힣|_])+)\}/g, function($0, $1) {
        if(rsvNmSet.has($1)){
          containRsvNm = true;
          return false;
        }
        varNms.push($1);
      });
      if(containRsvNm){
        confirm.fnAlert(this.componentsTitle, '발송 내용 변수 phone 은 예약어로 사용하실 수 없습니다.');
        return false;
      } else {
        this.sendData.contsVarNms = this.fnSetArrayRemoveDupliVal(varNms);
        return true;
      }
    },
    //array에 중복 항목을 제거한다.
    fnSetArrayRemoveDupliVal(array){
      let seen = {};
      return array.filter(function(item) {
        return seen.hasOwnProperty(item) ? false : (seen[item] = true);
      });
    },
    fnCallbackImgInfo(imgInfo){
      let temp = {
        imgUrl: imgInfo.chImgUrl,
        fileId: imgInfo.fileId
      };
      if(imgInfo.rtnParams && imgInfo.rtnParams.updIdx > 0){
        this.sendData.imgInfoList[imgInfo.rtnParams.updIdx-1] = temp;
      } else {
        if(this.fnImgLimitSize() == false) return;
        this.sendData.imgInfoList.push(temp);
      }

      //this.fnDelDuplImgInfo();
    },
    fnDelDuplImgInfo(){
      const vm = this;
      this.sendData.imgInfoList = this.sendData.imgInfoList.filter(function(item, i){
        return (
          vm.sendData.imgInfoList.findIndex((item2) => {
            return item.fileId === item2.fileId;
          }) === i
        );
      });
    },
    fnOpenImageManagePopUp(){
      if(this.fnImgLimitSize() == false) return;
      this.$refs.imgMngPopup.fnSearch();
      this.imgMngOpen = !this.imgMngOpen;
    },
    fnImgLimitSize(){
      if(this.sendData.imgInfoList != null && this.sendData.imgInfoList.length >= this.imgLimitSize){
        confirm.fnAlert(this.componentsTitle, '이미지는 최대 '+this.imgLimitSize+'개까지 등록 가능합니다.');
        return false;
      }
    },
    //템플릿 정보 Set
    fnSetTemplateInfo(templateInfo){
      this.sendData.senderType = templateInfo.senderType;  //발송유형
      this.sendData.msgKind = templateInfo.msgKind;  //메시지구분
      this.sendData.smsContent = templateInfo.tmpltContent;  //메시지내용

      if(this.sendData.senderType != 'SMS'){
        this.sendData.smsTitle = templateInfo.tmpltTitle;  //메시지제목
        //이미지정보
        if(templateInfo.imgInfoList != null && templateInfo.imgInfoList.length > 0){
          this.sendData.imgInfoList = templateInfo.imgInfoList;
        } else {
          this.sendData.imgInfoList = [];
        }
      } else {
        this.sendData.smsTitle = '';  //메시지제목
      }

      //수신거부
      if(this.sendData.msgKind == 'A'){
        this.sendData.rcvblcNumber = templateInfo.rcvblcNumber;
      } else {
        this.sendData.rcvblcNumber = '';
      }
    },
    //내용입력 callback
    fnSetSmsInfo(data){
      var msgKind = this.sendData.msgKind;
      if(this.sendData.smsContent != data.smsContent){
        this.fnCallbackRecvInfoLst(null);  //수신자 정보 초기화
      }
      this.sendData.smsTitle = data.smsTitle;
      if(msgKind == "A" && this.sendData.senderType == "SMS") {
          if(data.smsContent.indexOf("광고")== -1) {
            this.sendData.smsContent = "(광고)" + data.smsContent;
          } else {
            this.sendData.smsContent = data.smsContent;
          }
      } else {
        this.sendData.smsContent = data.smsContent;
      }
      this.sendData.rcvblcNumber = data.rcvblcNumber;
    },
    fnUpdateImg(idx){
      let params = {updIdx: idx};
      this.$refs.imgMngPopup.fnSearch();
      this.$refs.imgMngPopup.fnSetRtnParams(params);
      this.imgMngOpen = !this.imgMngOpen;
    },
    fnDelImg(idx){
      this.sendData.imgInfoList.splice(idx-1, 1);
    },
    //수신자 정보 callback
    fnCallbackRecvInfoLst(recvInfoLst, addYn) {
      if(recvInfoLst != null){
        if(this.$gfnCommonUtils.defaultIfEmpty(addYn, 'N') == 'Y'){
          this.sendData.recvInfoLst = this.sendData.recvInfoLst.concat(recvInfoLst);
        } else {
          this.sendData.recvInfoLst = recvInfoLst;
        }
        //수신자 중복제거
        this.fnDelDuplRecvInfo();

        this.recvCnt = this.sendData.recvInfoLst.length;
        this.sendData.cuInfo = JSON.stringify(this.sendData.recvInfoLst);
      } else {
        this.recvCnt = 0;
        this.sendData.recvInfoLst = [];
        this.sendData.cuInfo = '';
      }
    },
    //수신자 중복 제거
    fnDelDuplRecvInfo(){
      const vm = this;
      this.sendData.recvInfoLst = this.sendData.recvInfoLst.filter(function(item, i){
        return (
          vm.sendData.recvInfoLst.findIndex((item2) => {
            return item.phone === item2.phone
          }) === i
        );
      });
    },
    fnUpdateRsrvDate(sltDate){
      this.sendData.rsrvDate = sltDate;
    },
    fnSubString(str, sIdx, length){
      let shortStr = ''
      if(!this.$gfnCommonUtils.isEmpty(str)){
        shortStr = str.toString();
        if(shortStr.length > length){
          shortStr = shortStr.substring(sIdx, length) + '...  ';
        } else {
          shortStr = shortStr + '  ';
        }
      }
      return shortStr;
    },
    fnOpenTestSendInputPopup(){
      this.fnSetContsVarNms();
      this.$refs.testSendInputPopup.fnSetTestRecvInfoLst(this.sendData.testRecvInfoLst);
      this.testSendInputOpen = !this.testSendInputOpen;
    },
    //테스트 발송 callback
    fnCallbackTestRecvInfoLst(testRecvInfoLst){
      if(testRecvInfoLst != null){
        this.sendData.testRecvInfoLst = Object.assign([], testRecvInfoLst);
        this.fnSendSmsMessage('Y'); //실제 발송함수
      } else {
        this.sendData.testRecvInfoLst = [];
      }
    },
    fnOpenSmsTemplatePopup(){
      this.$refs.smsTmplPopup.fnSearch();
      this.smsTemplateOpen = !this.smsTemplateOpen;
    },
    fnOpenSmsContentsPopup(){
      this.smsContsOpen = !this.smsContsOpen;
    },
    //SMS 템플릿 엑셀 다운로드
    async fnExcelTmplteDownLoad(){
      if(this.fnSetContsVarNms() == false) return;
      const params = {
        contsVarNms : this.sendData.contsVarNms
      };
      await messageApi.excelDownSendSmsRecvTmplt(params);
    },
    //발신번호 리스트 조회
    async fnSelectCallbackList(){
      var params = {};
      await messageApi.selectCallbackList(params).then(response =>{
        var result = response.data;
        if(result.success) {
          this.sendData.callbackList = result.data;
        } else {
          confirm.fnAlert(this.componentsTitle, result.message);
        }
      });
    },
    //야간 메시지 전송 체크
    fnNightSendCheck(){
      let params = {
        nightSendYn : this.nightSendYn,
        rsrvSendYn : this.sendData.rsrvSendYn,
        rsrvHH : this.sendData.rsrvHH,
        rsrvMM : this.sendData.rsrvMM,
        nightSendSthh : this.nightSendSthh,
        nightSendStmm : this.nightSendStmm,
        nightSendEdhh : this.nightSendEdhh,
        nightSendEdmm : this.nightSendEdmm
      }
      var nightSendLimitYn = messageApi.checkNightSendTime(params);

      if(nightSendLimitYn){
        this.nightSendLimitYn = nightSendLimitYn;
      }

      return !nightSendLimitYn;
    },
    // 야간 메시지 전송 시간 확인
		async fnNightSendTime() {
			let params = {
        isChk : "Y"
      };
			await messageApi.selectNightSendTime(params).then(response =>{
				var result = response.data;
				if(result.success) {
					this.nightSendSthh = result.data.nightSendSthh;
					this.nightSendStmm = result.data.nightSendStmm;
					this.nightSendEdhh = result.data.nightSendEdhh;
					this.nightSendEdmm = result.data.nightSendEdmm;
          this.nightSendYn = result.data.nightSendYn;
          this.nightSendYn = 'N';
				} else {
					confirm.fnAlert(this.title, result.message);
				}
			});
		},
    fnContentAdAdd(msgKind, senderType){
      var smsContent = this.sendData.smsContent;
      msgKind = msgKind == '' ? this.sendData.msgKind : msgKind;
      senderType = senderType == '' ? this.sendData.senderType : senderType;
      if( msgKind == 'A' && senderType == 'SMS' && smsContent != '' ){
        this.sendData.smsContent = '(광고)'+this.sendData.smsContent.replace("(광고)","");
      }else if(msgKind == 'A' && senderType != 'SMS' && smsContent != '' ){
        this.sendData.smsContent = this.sendData.smsContent.replace("(광고)","");
      }else if(msgKind != 'A' && senderType == 'SMS' && smsContent != '' ){
        this.sendData.smsContent = this.sendData.smsContent.replace("(광고)","");
      }else if(msgKind != 'A' && senderType != 'SMS' && smsContent != ''){
        this.sendData.smsContent = this.sendData.smsContent.replace("(광고)","");
      }
      // console.log('this.sendData.smsContent:' + this.sendData.smsContent)
      // console.log('msgKind:' + msgKind)
      // console.log('senderType:' + senderType)
    }
  },
  watch: {
    type: function (val) {
      if (val === 'LMS') {
        this.showPreviewTitle = true
      }
    },
    recvCnt (newval) {
      if(newval>30000) {
        confirm.fnAlert(this.componentsTitle, "발송 최대 수신자 수는 30000명을 넘길 수 없습니다.");
        this.fnRemoveRecvInfo();
      }
    }
  }
};
</script>

<style scoped lang="scss">
@use "~@/assets/scss/service/base/typography" as typography;
@import '@/assets/scss/service/message.scss';

.radio-group {
  margin: 0;
}
.custom-radio {
  margin-right: 20px;
}
.btn-wrap {
  padding-left: 132px;
  button + button {
    margin-left: 12px;
  }
}
.image-select {
  margin-top: 20px;
  &-list {
    margin: 0 0 0 28px;
    padding: 0;
    list-style: none;
    li {
      display: flex;
      align-items: center;
      & + li {
        margin-top: 16px;
      }
    }
    span {
      max-width: 240px;
      overflow: hidden;
      @include typography.font-style(16px, 500, 140%, -0.32px);
      color: var(--gray900);
    }
    .btn {
      margin-left: 12px;
    }
  }
}
</style>
