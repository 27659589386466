<template>
  <div class="faq-list-container">
    <div class="faq-list-container__title">
      <h3 class="customer-title">자주 묻는 질문</h3> 
    </div>

    <div class="faq-list-container__filters">
      <div class="faq-list-container__filters__tabs">
        <div v-for="(tab, index) in tabs" role="tab" :key="tab.name" class="faq-list-container__filters__tabs__tab"
          :class="{ active: activatedTabIndex === index }" @click="activeTab(index)">
          <span>{{ tab.name }}</span>
          <span>({{ tab.count }})</span>
        </div>
      </div>

      <SearchBox />
    </div>

    <div class="faq-list-container__list-wrapper">
      <div v-for="(item, index) in faqItems" :key="index" class="faq-list-container__list-wrapper__item"
        :class="{ collapsed: item.collapsed }">
        <div role="button" class="faq-list-container__list-wrapper__item__title"
          @click="item.collapsed = !item.collapsed">
          <span class="faq-list-container__list-wrapper__item__title__q">Q.</span>
          <span>{{ item.title }}</span>
          <IconArrowDown />
        </div>
        <div class="faq-list-container__list-wrapper__item__contents">
          {{ item.content }}
        </div>
      </div>
    </div>

    <Pagination />
  </div>
</template>
<script>
import Pagination from '../components/MainPagination.vue';
import SearchBox from '../components/SearchBox.vue';
import IconArrowDown from '@/components/service/icons/IconArrowDown.vue'

export default {
  name: "FaqList",

  data: () => ({
    activatedTabIndex: 0,
    tabs: [
      { name: '전체', count: 12 },
      { name: '결제', count: 12 },
      { name: '회원', count: 12 },
      { name: '서비스 기타', count: 12 },
    ],
    faqItems: Array.from({ length: 10 }, () => ({
      title: '템플릿은 어떻게 이용하나요?',
      content: '이렇게 사용하시면 됩니다. 이렇게 사용하시면 됩니다. 이렇게 사용하시면 됩니다. 이렇게 사용하시면 됩니다. 이렇게 사용하시면 됩니다.',
      collapsed: true
    }))
  }),

  methods: {
    activeTab(index) {
      this.activatedTabIndex = index
    }
  },

  components: {
    SearchBox,
    Pagination,
    IconArrowDown
  }
};
</script>
<style lang="scss" scoped>

.faq-list-container__filters {
    margin: 40px 0;

    @media (min-width: 300px) {
      margin: 15px 0 40px !important;
      display: flex;
      flex-direction: column;
      align-items: center !important;
    }

    @media (min-width: 375px) {
      margin: 15px 0;
    }

    @media (min-width: 1024px) {
      margin: 15px 0;
    }
    @media (min-width: 1025px) {
      margin: 15px 0;
    }
}

.landing-wrapper h3.customer-title {
  @media (min-width: 375px) {
      padding-bottom: 0px;
    }
}

.faq-list-container {
  margin-top: 38px;
  padding: 60px 0;
  line-height: 1.4;

    @media (min-width: 300px) {
        margin-top: 38px !important;
        padding: 20px 0;
    }

    @media (min-width: 375px) {
        padding: 20px 0;
    }

    @media (min-width: 770px) {
      padding: 60px 0;
    }

    @media (min-width: 1025px) {
      padding: 60px 0;
    }

  &__title {
    color: #111827;
    font-size: 28px;
    font-weight: 700;

    @media (min-width: 375px) {
      font-size: 20px;
    }

    @media (min-width: 1024px) {
      font-size: 28px;
    }
  }

  &__filters {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 40px 0;

    @media (min-width: 375px) {
      flex-direction: column;
      gap: 20px;
      align-items: center;
    }

    @media (min-width: 1024px) {
      flex-direction: row;
      gap: 0;
      justify-content: space-between;
      align-items: flex-start;
    }

    &__tabs {
      display: flex;
      padding: 8px 0;

      @media (min-width: 300px) {
          padding: 15px 0;
        }

      &__tab {
        display: flex;
        align-items: center;
        gap: 4px;
        font-size: 20px;
        padding: 0 26.5px;
        cursor: pointer;

        @media (min-width: 300px) {
          padding: 0 10px;
          font-size: 14px;
        }

        @media (min-width: 375px) {
          padding: 0 14px;
          font-size: 14px;
        }

        @media (min-width: 1024px) {
          padding: 0 16.5px;
          font-size: 20px;
        }

        @media (min-width: 1280px) {
          padding: 0 26.5px;
          font-size: 20px;
        }

        &:last-child {
          padding-right: 0;
        }

        &.active {
          span {
            color: #374151;
          }

          span:first-child {
            font-weight: 700;
          }
        }

        &:not(:last-child) {
          border-right: 1px solid #E5E7EB;
        }

        span {
          color: #6B7280;
        }

        span:last-child {
          font-size: 0.8em;
          font-weight: 500;
        }
      }
    }
  }
}

.faq-list-container__list-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;

  &__item {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 16px 20px;
    border-radius: 12px;
    border: 1px solid #F3F4F6;
    box-shadow: 0px 4px 16px 0px #53587114;

    @media (min-width: 375px) {
      padding: 8px 12px;
      border-radius: 8px;
    }

    @media (min-width: 1024px) {
      padding: 16px 20px;
      border-radius: 12px;
    }

    &.collapsed {
      .faq-list-container__list-wrapper__item__title :deep svg {
        transform: rotate(0);
      }

      .faq-list-container__list-wrapper__item__contents {
        display: none;
      }
    }

    &__title {
      display: flex;
      align-items: center;
      color: #4B5563;

      &__q {
        padding: 8px 16px;
        background: linear-gradient(308.23deg, #D4E0FF 8.84%, #788FF5 86.03%);
        line-height: 1.4;
        font-size: 20px;
        font-weight: 700;
        color: #fff;
        border-radius: 8px;

        @media (min-width: 300px) {
          font-size: 14px;
          padding: 4px 8px;
          border-radius: 4px;
        }

        @media (min-width: 375px) {
          font-size: 14px;
          padding: 4px 8px;
          border-radius: 4px;
        }

        @media (min-width: 1024px) {
          font-size: 20px;
          padding: 8px 16px;
          border-radius: 8px;
        }
      }

      span:nth-child(2) {
        padding: 0 40px;
        flex: 1;
        color: #374151;
        font-weight: 700;
        font-size: 20px;

        @media (min-width: 300px) {
          font-size: 14px;
          padding: 0 12px;
        }

        @media (min-width: 375px) {
          font-size: 14px;
          padding: 0 12px;
        }

        @media (min-width: 1024px) {
          font-size: 20px;
          padding: 0 40px;
        }
      }

      :deep svg {
        width: 28px;
        height: 28px;
        transform: rotate(180deg);

        @media (min-width: 300px) {
          width: 16px;
          height: 16px;
        }

        @media (min-width: 375px) {
          width: 16px;
          height: 16px;
        }

        @media (min-width: 1024px) {
          width: 28px;
          height: 28px;
        }
      }
    }

    &__contents {
      display: block;
      padding: 20px 40px;
      background-color: #F9FAFB;
      font-size: 18px;
      color: #6B7280;
      font-weight: 500;
      word-break: keep-all;

      @media (min-width: 300px) {
        font-size: 14px;
        padding: 12px;
      }

      @media (min-width: 375px) {
        font-size: 14px;
        padding: 12px;
      }

      @media (min-width: 1024px) {
        font-size: 18px;
        padding: 20px 40px;
      }
    }
  }
}
</style>