<template>
  <div class="login-form__radio">
    <input
      :id="id"
      type="radio"
      :name="name"
      :checked="modelValue === value"
      @click="onClick"
    />
    <label :for="id">
      <slot name="label">
        {{ label }}
      </slot>
    </label>
  </div>
</template>

<script>
export default {
  name: 'LoginFormRadio',

  props: {
    id: {
      type: String,
      default: () => `id-${crypto.randomUUID()}`
    },
    label: String,
    value: [String, Number]
  },

  inject: ['withInRadioGroup', 'name', 'modelValue', 'onClickRadio'],

  methods: {
    onClick() {
      this.onClickRadio(this.value)
    }
  },

  mounted() {
    if (!this.withInRadioGroup) {
      throw new Error("Radio Group 컴포넌트 안에 있어야 한다.");
    }
  }
}
</script>

<style lang="scss" scoped>
@use "../../../assets/scss/service/abstracts/variables" as *;

.login-form__radio {
  display: flex;
  align-items: center;

  input[type="radio"] {
    display: none;

    ~label {
      display: flex;
      gap: 8px;
      align-items: center;
      margin: 0;
      cursor: pointer;
      user-select: none;
      font-size: 16px;
      font-weight: 400;

      @media (min-width: 300px) {
        font-size: 12px;
      }

      @media (min-width: 375px) {
        font-size: 12px;
      }

      @media (min-width: 1024px) {
        font-size: 16px;
      }

      &::before {
        display: block;
        width: 16px;
        height: 16px;
        border: 1.25px solid #9CA3AF;
        border-radius: 999px;
        box-sizing: border-box;
        content: '';
      }
    }

    &:checked~label {
      position: relative;

      &::before {
        border: 0;
        background-repeat: no-repeat;
        background-size: contain;
        background-image: url('#{$icon}icon-radio-on.svg');
      }
    }
  }
}
</style>