<template>
  <div class="template-card" @click="selectCard">

    <div v-if="this.value == 'notAllowSlide1' || this.value == 'notAllowSlide2'" class="template-card-slide">
      <!-- NOTE: 템플릿 이미지 추가 -->
      <img :src="imageSrc" :alt="imageAlt" />
    </div>
    <div v-else class="template-card-img">
      <!-- NOTE: 템플릿 이미지 추가 -->
      <img :src="imageSrc" :alt="imageAlt" />
    </div>

    <slot />
  </div>
</template>

<script>
export default {
  props: {
    setCard: {
      type: Function
    },
    value: {
      type: String
    }
  },
  name: 'templateCard',
  computed: {
    imageSrc() {
      // value 값에 따라 이미지 경로를 설정
      if (this.value === "allowTemplate1") {
        return require("@/assets/images/service/rcs/allowTemplate1.jpg");
      } else if (this.value === "allowTemplate2") {
        return require("@/assets/images/service/rcs/allowTemplate2.jpg");
      } else if (this.value === "allowTemplate3") {
        return require("@/assets/images/service/rcs/allowTemplate3.jpg");
      } else if (this.value === "allowTemplate4") {
        return require("@/assets/images/service/rcs/allowTemplate4.jpg");
      } else if (this.value === "allowImage1") {
        return require("@/assets/images/service/rcs/allowImage1.jpg");
      } else if (this.value === "allowImage2") {
        return require("@/assets/images/service/rcs/allowImage2.jpg");
      } else if (this.value === "allowThumb1") {
        return require("@/assets/images/service/rcs/allowThumb1.jpg");
      } else if (this.value === "allowThumb2") {
        return require("@/assets/images/service/rcs/allowThumb2.jpg");
      } else if (this.value === "allowSns1") {
        return require("@/assets/images/service/rcs/allowSns1.jpg");
      } else if (this.value === "allowSns2") {
        return require("@/assets/images/service/rcs/allowSns2.jpg");
      } else if (this.value === "notAllowTemplate1") {
        return require("@/assets/images/service/rcs/notAllowTemplate1.jpg");
      } else if (this.value === "notAllowTemplate2") {
        return require("@/assets/images/service/rcs/notAllowTemplate2.jpg");
      } else if (this.value === "notAllowText1") {
        return require("@/assets/images/service/rcs/notAllowText1.jpg");
      } else if (this.value === "notAllowText2") {
        return require("@/assets/images/service/rcs/notAllowText2.jpg");
      } else if (this.value === "notAllowImage1") {
        return require("@/assets/images/service/rcs/notAllowImage1.jpg");
      } else if (this.value === "notAllowImage2") {
        return require("@/assets/images/service/rcs/notAllowImage2.jpg");
      } else if (this.value === "notAllowImage3") {
        return require("@/assets/images/service/rcs/allowImage1.jpg");
      } else if (this.value === "notAllowImage4") {
        return require("@/assets/images/service/rcs/allowImage2.jpg");
      } else if (this.value === "notAllowThumb1") {
        return require("@/assets/images/service/rcs/allowThumb1.jpg");
      } else if (this.value === "notAllowThumb2") {
        return require("@/assets/images/service/rcs/allowThumb2.jpg");
      } else if (this.value === "notAllowSns1") {
        return require("@/assets/images/service/rcs/allowSns1.jpg");
      } else if (this.value === "notAllowSns2") {
        return require("@/assets/images/service/rcs/allowSns2.jpg");
      } else if (this.value === "notAllowSlide1") {
        return require("@/assets/images/service/rcs/notAllowSlide1.jpg");
      } else if (this.value === "notAllowSlide2") {
        return require("@/assets/images/service/rcs/notAllowSlide2.jpg");
      }
      return "";
    },
    imageAlt() {
      // value 값에 따라 alt 텍스트 설정
      if (this.value === "allowTemplate1") {
        return "allow_template1";
      } else if (this.value === "allowTemplate2") {
        return "allow_template2";
      } else if (this.value === "allowTemplate3") {
        return "allow_template3";
      } else if (this.value === "allowTemplate4") {
        return "allow_template4";
      } else if (this.value === "allowImage1") {
        return "allow_image1";
      } else if (this.value === "allowImage2") {
        return "allow_image2";
      } else if (this.value === "allowThumb1") {
        return "allow_thumb1";
      } else if (this.value === "allowThumb2") {
        return "allow_thumb2";
      } else if (this.value === "allowSns1") {
        return "allow_sns1";
      } else if (this.value === "allowSns2") {
        return "allow_sns2";
      } else if (this.value === "notAllowTemplate1") {
        return "notAllow_template1";
      } else if (this.value === "notAllowTemplate2") {
        return "notAllow_template2";
      } else if (this.value === "notAllowText1") {
        return "notAllow_text1";
      } else if (this.value === "notAllowText2") {
        return "notAllow_text2";
      } else if (this.value === "notAllowImage1") {
        return "notAllow_image1";
      } else if (this.value === "notAllowImage2") {
        return "notAllow_image2";
      } else if (this.value === "notAllowImage3") {
        return "notAllow_image3";
      } else if (this.value === "notAllowImage4") {
        return "notAllow_image4";
      } else if (this.value === "notAllowThumb1") {
        return "notAllow_thumb1";
      } else if (this.value === "notAllowThumb2") {
        return "notAllow_thumb2";
      } else if (this.value === "notAllowSns1") {
        return "notAllow_sns1";
      } else if (this.value === "notAllowSns2") {
        return "notAllow_sns2";
      } else if (this.value === "notAllowSlide1") {
        return "notAllow_slide1";
      } else if (this.value === "notAllowSlide2") {
        return "notAllow_slide2";
      }
      return "default image";
    },
  },
  methods: {
    selectCard() {
      if (this.setCard && this.value) {
        this.setCard(this.value)
      }
    }
  }
}
</script>

<style scoped lang="scss">
@use "~@/assets/scss/service/base/typography" as typography;
@use '@/assets/scss/service/message.scss';
@use '@/assets/scss/service/template.scss';

.template-card {
  display: inline-flex;
  flex-direction: column;
  align-items: center;

  &-img {
    width: 80px;
    height: 126px;
    margin-bottom: 11px;
    background-color: var(--gray200);
    border-radius: 4px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &-slide {
    width: 400px;
    height: 126px;
    margin-bottom: 11px;
    background-color: var(--gray200);
    border-radius: 4px;

    img {
      width: 100%;
      height: 100%;
    }

  }
}
</style>