<template>
  <b-modal id="add-category-modal" title="그룹 등록" hide-header-close size="md" centered>
    <div class="form-row">
      <label>주소록</label>
      <div>
        <b-input class="w-100" value="이커머스테크" disabled></b-input>
      </div>
    </div>

    <div class="form-row">
      <label>그룹명</label>
      <div>
        <b-input class="w-100" placeholder="입력"></b-input>
      </div>
    </div>

    <template #modal-footer>
      <b-button variant="primary" @click="openSaveCategoryModal">추가</b-button>
      <b-button variant="outline-primary" @click="closeModal">취소</b-button>
    </template>
  </b-modal>
</template>

<script>

export default {
  components: {},
  name: 'AddCategoryModal',
  props: {
    openSaveCategoryModal: {
      type: Function
    }
  },
  data() {
    return {
    }
  },
  methods: {
    closeModal() {
      this.$bvModal.hide('add-category-modal');
    },
  }
}
</script>

<style scoped lang="scss">
@use '@/assets/scss/service/template.scss';
</style>