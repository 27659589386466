<template>
  <div class="signup-wrapper__container__contents__wrapper">
    <div class="signup-wrapper__container__contents__wrapper__header">
      회원가입
    </div>

    <div class="signup-wrapper__container__contents__wrapper__main">
      <div class="signup-wrapper__container__contents__wrapper__main__title">
        1. 기본정보 입력
      </div>

      <div class="signup-wrapper__container__contents__wrapper__main__form">
        <LoginForm>
          <LoginFormTextField
            v-for="field in fields"
            :key="field.label"
            v-model="field.value"
            :label="field.label"
            :type="field.type"
            :placeholder="field.placeholder"
            :desc="field.desc"
            items="flex-start"
            :readonly="field.readonly"
            required
          />
        </LoginForm>
      </div>
    </div>
  </div>
</template>

<script>
import LoginForm from '../../../components/landing/login/LoginForm.vue';
import LoginFormTextField from '../../../components/landing/login/LoginFormTextField.vue';

export default {
  data: () => ({
    fields: [
      { label: '이름 입력', type: 'text', value: '', placeholder: '이름을 입력해주세요' },
      { label: '전화번호 입력', type: 'tel', value: '', placeholder: '전화번호를 입력해주세요' },
      { label: '아이디 입력', type: 'text', readonly: true, value: 'bella@gridge.co.kr', },
      { label: '비밀번호 입력', type: 'password', value: '', placeholder: '비밀번호를 입력해주세요', desc: '대/소문자, 숫자, 특수문자 조합 8~16자 ' },
      { label: '비밀번호 재입력', type: 'password', value: '', placeholder: '비밀번호를 다시 입력해주세요', desc: '대/소문자, 숫자, 특수문자 조합 8~16자 ' },
    ],
  }),

  components: {
    LoginForm,
    LoginFormTextField
  },

  methods: {
    
  },
}
</script>

<style lang="scss" scoped>
@use "../../../assets/scss/landing/abstracts/variables" as v;
@use "../../../assets/scss/service/abstracts/variables" as *;

.signup-wrapper__container__contents__wrapper__main__form :deep .login-form {
  @media (min-width: 375px) {
    --gap: 20px !important;
  }

  @media (min-width: 1024px) {
    --gap: 40px !important;
  }
}
</style>