<template>
  <table class="modal-table">
    <thead>
      <tr class="modal-table-row">
        <th v-for="(col, index) in headers" :key="index" class="modal-table-header">{{ col }}</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(row, index) in data" :key="index" :class="['modal-table-row', { 'selected': isSelectedRow(index) }]"
        @click="selectRowIndex(index)">
        <td v-for="item in row" :key="`row-${index}-${item.name}`" class="modal-table-data">{{ item.value }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: {
    /** @type {import('vue').PropType<{ name: string, value: any }[][]>} */
    data: Array
  },
  data() {
    return {
      selectedRowIndex: -1
    }
  },
  computed: {
    headers() {
      return this.$props.data[0].map(col => col.name)
    }
  },
  methods: {
    isSelectedRow(index) {
      return this.selectedRowIndex === index;
    },
    selectRowIndex(index) {
      this.selectedRowIndex = index;
    }
  }
}
</script>
<style lang="scss" scoped>
.modal-table {
  margin-left: auto;
  margin-right: auto;
  text-align: center;

  @media (min-width: 300px) {
    width: 100%;
  }

  @media (min-width: 375px) {
    width: 100%;
  }

  @media (min-width: 1024px) {
    width: 100%;
  }
}

.selected {
  background-color: #E9E9FE;
}

.modal-table-header {
  background-color: #F3F4F6;
  color: #4B5563;
  height: 34px;
  width: 50%;
  padding: 8px 0px 8px 0px;
  border-bottom: 1px;
  font-size: 16px;
  font-weight: 700;
  line-height: 22.4px;
  letter-spacing: -0.02em;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.modal-table-row {
  border-bottom: 1px solid #D1D5DB;
  height: 50px;
  padding: 8px 0px 8px 0px;
}

.modal-table-data {
  border-bottom: 1px solid #D1D5DB;
  color: #6B7280;
  height: 34px;
  padding: 8px 0px 8px 0px;
  border-bottom: 1px;
  font-size: 16px;
  font-weight: 500;
  line-height: 22.4px;
  letter-spacing: -0.02em;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

  @media (min-width: 300px) {
    font-size: 14px;
  }
}
</style>