<template>
  <b-modal id="block-number-modal" title="수신거부번호 관리" hide-header-close centered size="lg">
    <div class="block-list">
      <div class="input-group">
        <b-input v-model="rejectPreText"></b-input>
        <b-input placeholder="080-0000-0000" v-model="rejectNumber"
          oninput="javascript: this.value = this.value.replace(/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/g, '' );"
          @keypress="fnPreventPhoneNumInput"></b-input>
        <b-button @click="fnInsertRejectNum" variant="dark">등록</b-button>
      </div>
      <ul v-for="(contant, idx) in contants" :key="idx">
        <li>
          <span class="f-body5 c-gray900 text-truncate">{{ contant.phone | unescapeXss }}</span>
          <b-button @click.prevent="fnSelectRejectNum(contant.phone)" variant="outline-primary" size="sm"
            @click="closeModal">선택</b-button>
          <button type="button" class="btn btn-icon p-0">
            <IconClose @click="fnDeleteRejectNum(contant.rejectId)" />
          </button>
        </li>
      </ul>
    </div>

    <template #modal-footer>
      <b-button variant="outline-primary" @click="closeModal">닫기</b-button>
    </template>

    <ConfirmModal ref="confirmModal" :title="confirmTitle" :desc="confirmDesc" :onSubmit="callback" />
    <AlertModal ref="alertModal" :title="alertTitle" :desc="alertDesc" :callbackFunc="callback" />
  </b-modal>
</template>

<script>
import messageApi from "@/modules/message/service/messageApi.js";
import IconClose from '@/components/service/icons/IconClose.vue';
import AlertModal from '@/components/service/modal/AlertModal.vue';
import ConfirmModal from '@/components/service/modal/ConfirmModal.vue'

export default {
  components: { IconClose, ConfirmModal, AlertModal },
  name: "BlockNumberModal",
  props: {
    componentsTitle: {
      type: String,
      require: false,
      default: function () {
        return '수신거부번호 관리';
      }
    },
  },
  data() {
    return {
      limitByte: 30,
      rejectPreText: '무료수신거부 ',
      rejectNumber: '',
      rejectId: '',
      contants: [],
      alertTitle: '',
      alertDesc: '',
      confirmTitle: '',
      confirmDesc: '',
      callback: () => { } // 기본 함수로 초기화
    }
  },
  mounted() {
    this.fnSelectRejectPhoneList();
  },
  methods: {
    closeModal() {
      // this.$emit('update:rcvblcNumOpen', false)
      this.$bvModal.hide('block-number-modal');
    },
    showConfirm(message, method) {
      if (typeof method !== 'function') {
        method = () => { }; // callback이 없는 경우 빈 함수 
      }

      this.confirmTitle = '수신거부번호 관리'
      this.confirmDesc = message
      this.callback = method
      this.$bvModal.show('confirm-modal');

      // this.$refs.confirmModal.openModal(this.componentsTitle, message, method);
    },
    showAlert(msg, method) {
      if (typeof method !== 'function') {
        method = () => { }; // callback이 없는 경우 빈 함수 
      }

      this.alertTitle = '수신거부번호 관리'
      this.alertDesc = msg
      this.callback = method
      this.$bvModal.show('alert-modal');
    },

    fnSelectRejectNum(rcvblcNum) {
      this.$emit('callback-func', this.$gfnCommonUtils.unescapeXss(rcvblcNum));
    },
    fnPreventPhoneNumInput($event) {
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 45) {
        $event.preventDefault();
      }
    },
    //유효성 체크
    fnIsValid() {
      if (!this.rejectPreText) {
        this.showAlert('수신거부문구를 입력해주세요.');
        return false;
      }
      if (!this.rejectNumber) {
        this.showAlert('수신거부번호를 입력해주세요.');
        return false;
      }
      if (!this.$gfnCommonUtils.isDashNum(this.rejectNumber)) {
        this.showAlert('수신거부번호 형식에 맞지않습니다.');
        return false;
      }
      if (this.$gfnCommonUtils.getByte(this.rejectPreText + this.rejectNumber) > this.limitByte) {
        this.showAlert('수신거부문구 + 수신거부번호는 최대 ' + this.limitByte + '까지 입력가능합니다.');
        return false;
      }
    },
    //등록
    fnInsertRejectNum() {
      //유효성 검사
      if (this.fnIsValid() == false) return;
      this.showConfirm("입력하신 정보를 등록하시겠습니까?", this.fnProcInsertRejectNum);
    },
    async fnProcInsertRejectNum() {
      let params = {
        phone: this.rejectPreText + this.rejectNumber
      };
      //저장처리
      await messageApi.insertRejectPhone(params).then(response => {
        const result = response.data;

        if (result.success) {
          // 등록 성공 시
          this.showAlert("등록되었습니다.", this.fnSelectRejectPhoneList)
          this.fnResetForm(); // 폼 초기화
        } else {
          // 등록 실패 시
          this.showAlert("등록 실패하였습니다.");
        }
      });
    },
    // 폼 초기화
    fnResetForm() {
      this.rejectNumber = "";
    },
    //삭제
    fnDeleteRejectNum(rejectId) {
      this.rejectId = rejectId;
      this.showConfirm("삭제하시겠습니까?", this.fnProcDeleteRejectNum);
    },
    async fnProcDeleteRejectNum() {
      let params = {
        rejectId: this.rejectId
      };
      //삭제처리
      await messageApi.deleteRejectPhoneList(params).then(response => {
        const result = response.data;

        if (result.success) {
          this.showAlert('삭제되었습니다.', this.fnSelectRejectPhoneList);
        } else {
          this.showAlert('삭제 실패하였습니다.');
        }
      });
    },
    async fnSelectRejectPhoneList() {

      let params = {};
      await messageApi.selectRejectPhoneList(params).then(response => {
        const result = response.data;

        if (result.success) {
          this.contants = Object.assign([], result.data);
        }
      });
    },
  }
};
</script>

<style scoped lang="scss">
.block-list {
  .input-group {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .form-control {
      margin-left: 6.5px;

      &:first-child {
        margin-left: 0;
      }
    }

    .btn {
      margin-left: 6.5px;
    }
  }

  ul {
    margin: 0;
    padding: 0;
    border-top: 1px solid var(--gray500);
    border-bottom: 1px solid var(--gray500);

    li {
      display: flex;
      align-items: center;
      padding: 12px 0;

      &+li {
        border-top: 1px solid var(--border-color);
      }
    }

    .btn-outline-primary {
      min-width: 73px;
      margin-left: auto;
    }

    .btn-icon {
      margin-left: 20px;
    }
  }
}
</style>
