<template>
  <div class="signup-wrapper">
    <div class="signup-wrapper__container">
      <div class="signup-wrapper__container__header">
        <SignupBackButton @click.native="$router.back()">뒤로가기</SignupBackButton>

        <SignupStepProgress :active-step="currentStep" />
      </div>

      <SignupStepContents />

      <div class="signup-wrapper__container__footer">
        <button v-if="currentStep < maxStep" @click="nextStep">다음</button>
        <button v-else>회원가입</button>
      </div>
    </div>
  </div>
</template>

<script>
import SignupBackButton from '../../../components/landing/signup/SignupBackButton.vue';
import SignupStepContents from '../../../components/landing/signup/SignupStepContents.vue';
import SignupStepProgress from '../../../components/landing/signup/SignupStepProgress.vue';

export default {
  data: () => ({}),

  computed: {
    maxStep() {
      return 3
    },
    currentStep() {
      const path = this.$route.path
      const step = path.match(/\d$/)

      return step ? +step[0] : null
    }
  },

  components: {
    SignupBackButton,
    SignupStepContents,
    SignupStepProgress
  },

  methods: {
    nextStep() {
      this.$router.push(`/sign/signUpStep/${this.currentStep + 1}`)
    }
  },
}
</script>

<style lang="scss" scoped>
@use "../../../assets/scss/landing/abstracts/variables" as v;
@use "../../../assets/scss/service/abstracts/variables" as *;

.signup-wrapper {
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;

  @media (min-width: 375px) {
    /* padding: 40px 0 60px; */
  }

  @media (min-width: 1024px) {
    /* padding: 140px 0; */
  }

  @media (min-width: 1440px) {
    /* padding: 140px 0; */
  }

  &__container {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    width: 100%;
    height: 100%;

    @media (min-width: 300px) {
      padding: 40px 0 0;
      max-width: 280px;
    }

    @media (min-width: 375px) {
      padding: 40px 0 0;
      max-width: 335px;
    }

    @media (min-width: 1024px) {
      padding: 140px 0 0;
      max-width: 904px;
    }

    @media (min-width: 1440px) {
      padding: 140px 0 0;
      max-width: 1200px;
    }

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }

    &__footer {
      @media (min-width: 300px) {
        padding-bottom: 60px;
      }

      @media (min-width: 375px) {
        padding-bottom: 60px;
      }

      @media (min-width: 1024px) {
        padding-bottom: 140px;
      }

      @media (min-width: 1440px) {
        padding-bottom: 140px;
      }

      button {
        padding: 0;
        width: 100%;
        height: 52px;
        background-color: #6D6EFA;
        border-radius: 12px;
        border: 0;
        color: #FFFFFF;
        font-weight: 700;
      }
    }
  }
}
</style>