<template>
  <div class="step-progress">
    <div
        v-for="(step, index) in steps"
        :key="index"
        class="step"
        :class="{ active: activeStep === index + 1 }"
    >
      <div class="circle-label">
        <div class="circle">{{ index + 1 }}</div>
        <span class="label">{{ step }}</span>
      </div>
      <div v-if="index < steps.length - 1" class="divider"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SignupStepProgress",
  props: {
    steps: {
      type: Array,
      required: true,
      default: () => ["기본정보", "회사정보", "영업사원"],
    },
    activeStep: {
      type: Number,
      required: true,
      default: 1,
    },
  },
};
</script>

<style lang="scss" scoped>
.step-progress {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Pretendard, Arial, sans-serif;
}

.step {
  color: #6d6efa;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  font-weight: bold;
}

.circle-label {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 59px;
}

.step.active .circle {
  background-color: #6d6efa;
  color: white;
  font-weight: bold;
}

.step.active .label {
  color: #6d6efa;
  background: none;
  font-weight: 700;
}

.circle {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #d2d2fd;
  font-size: 14px;
  padding: 10px 0;
  gap: 10px;
}

/* 기본 텍스트 스타일 */
.label {
  margin-top: 2px;
  width: 48px;
  height: 25px;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  text-align: center;
  letter-spacing: -0.02em;
  background: none;
  color: #d2d2fd;
  align-items: center;
  justify-content: center;
}

.divider {
  width: 44px;
  height: 54px;
  position: relative;
}

.divider::before {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  content: "\2022\2022\2022";
  color: #6d6efa;
  text-align: center;
  margin-bottom: 16px;
  margin-top: 16px;
  font-size: calc(100% / 2);
}

/* 모바일 (300px ~ 600px) */
@media (max-width: 600px) {
  .circle {
    width: 28px;
    height: 28px;
    font-size: 12px;
    padding: 10px 0;
    gap: 5px;
  }

  .label {
    font-size: 12px;
  }

  .divider {
    width: 30px;
  }
}

/* 태블릿 및 소형 데스크톱 (601px ~ 1024px) */
@media (min-width: 601px) and (max-width: 1024px) {
  .circle {
    width: 30px;
    height: 30px;
    font-size: 13px;
  }

  .label {
    font-size: 13px;
  }

  .divider {
    width: 36px;
  }
}

/* 데스크톱 (1025px 이상) */
@media (min-width: 1025px) {
  .circle {
    width: 32px;
    height: 32px;
    font-size: 14px;
  }

  .label {
    font-size: 14px;
  }

  .divider {
    width: 44px;
  }
}
</style>

