<template>
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" stroke="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M8 3.99951C3 3.99951 1 8.5 1 8.5C1 8.5 3 12.9995 8 12.9995C13 12.9995 15 8.5 15 8.5C15 8.5 13 3.99951 8 3.99951Z" stroke="currentColor" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M8 11C9.38071 11 10.5 9.88071 10.5 8.5C10.5 7.11929 9.38071 6 8 6C6.61929 6 5.5 7.11929 5.5 8.5C5.5 9.88071 6.61929 11 8 11Z" stroke="currentColor" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'IconEyeOff'
}
</script>
