import Vue from 'vue';
import Router from 'vue-router';
import SmsTemplateManage from '../views/bm-smsTemplateManage.vue'
import SmsTemplateList from '../views/bm-smsTemplateList.vue'
import RcsTemplateList from '../views/bm-rcsTemplateList.vue'
import RcsTemplateManage from '../views/bm-rcsTemplateManage.vue'
import RcsTemplateManageEdit from '../views/bm-rcsTemplateManageEdit.vue'
import AlimTalkTemplateManage from '../views/bm-alimTalkTemplateManage.vue'
import AlimTalkTemplateList from '../views/bm-alimTalkTemplateList.vue'
import multiSendTemplateList from '../views/bm-multiSendTemplateList'
import multiSendTemplateManage from '../views/bm-multiSendTemplateManage'
Vue.use(Router);
export default [
	{
		path: '/uc/template/smsTemplateManage',
		name: 'smsTemplateManage',
		meta: { menu: 'UC_SMS_TPL' },
		component: SmsTemplateManage,
		props: true
	},
	{
		path: '/uc/template/smsTemplateList',
		name: 'smsTemplateList',
		meta: { menu: 'UC_SMS_TPL' },
		component: SmsTemplateList,
		props: route => ({ query: route.query })
	},
	{
		path: '/uc/template/rcsTemplateList',
		name: 'rcsTemplateList',
		meta: { menu: 'UC_RCS_TPL' },
		component: RcsTemplateList
	},
	{
		path: '/uc/template/rcsTemplateManage',
		name: 'rcsTemplateManage',
		meta: { menu: 'UC_RCS_TPL' },
		component: RcsTemplateManage,
		props: true
	},
	{
		path: '/uc/template/rcsTemplateManageEdit',
		name: 'rcsTemplateManageEdit',
		meta: { menu: 'UC_RCS_TPL' },
		component: RcsTemplateManageEdit,
		props: true
	},
	{
		path: '/uc/template/alimTalkTemplateList',
		name: 'alimTalkTemplateList',
		meta: { menu: 'UC_ALIM_TPL' },
		component: AlimTalkTemplateList,
		props: true
	},
	{
		path: '/uc/template/alimTalkTemplateManage',
		name: 'alimTalkTemplateManage',
		meta: { menu: 'UC_ALIM_TPL' },
		component: AlimTalkTemplateManage,
		props: true
	},
	{
		path: '/uc/template/multiSendTemplateList',
		name: 'multiSendTemplateList',
		meta: {menu: 'UC_INTG_TPL'},
		component: multiSendTemplateList
	},
	{
		path: '/uc/template/multiSendTemplateManage',
		name: 'multiSendTemplateManage',
		meta: {menu: 'UC_INTG_TPL'},
		component: multiSendTemplateManage,
		props: true
	}

]