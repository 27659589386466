import { render, staticRenderFns } from "./bm-mainNoticeDetail.vue?vue&type=template&id=c9d90210&scoped=true&lang=true"
import script from "./bm-mainNoticeDetail.vue?vue&type=script&lang=js"
export * from "./bm-mainNoticeDetail.vue?vue&type=script&lang=js"
import style0 from "./bm-mainNoticeDetail.vue?vue&type=style&index=0&id=c9d90210&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c9d90210",
  null
  
)

export default component.exports