<template>
  <div
    :class="className"
    :style="style"
  >
    <form @submit.prevent="$emit('submit')">
      <slot />
    </form>
  </div>
</template>

<script>
export default {
  name: 'LoginForm',

  props: {
    row: Boolean,
    gap: {
      type: [Number, String],
      default: 20
    }
  },

  emits: ['submit'],

  computed: {
    className() {
      const { row } = this.$props

      return { 'login-form': true, row }
    },

    style() {
      const { gap } = this.$props

      return {
        '--gap': `${gap}px`
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.login-form {
  > form {
    display: flex;
    flex-direction: column;
    gap: var(--gap);
  }

  &.row {
    margin: 0 !important;

    > form {  
      flex-direction: row;
      width: 100%;
      margin: 0;

      @media (min-width: 300px) {
        flex-direction: column !important;
      }

      @media (min-width: 375px) {
        flex-direction: column;
      }

      @media (min-width: 1024px) {
        flex-direction: row;
      }
    }
  }
}
</style>