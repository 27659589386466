<template>
  <div class="uc-sms-template-list list-view">
    <TemplateTabs />

    <div class="tab-contents">
      <div class="info card">
        <ul>
          <li>아래 탭에서 RCS 유형을 먼저 선택해 주십시오.</li>
          <li>템플릿형은 광고성 메시지를 보내실 수 없습니다.</li>
          <li>동영상 스트리밍 삼성 S시리즈, R시리즈 중 안드로이드 OS 10.0을 탑재한 단말기만 지원합니다.</li>
          <li>승인 상태의 템플릿만 등록제 요금이 적용됩니다. (등록완료 상태의 템플릿은 등록제 요금이 적용되지 않습니다.</li>
          <li>템플릿 승인은 요청일로 부터 1~2영업일이 소요됩니다</li>
        </ul>
      </div>

      <!-- 검색영역 Start -->
      <div class="search-section card border-0">
        <div class="search-section-forms">
          <div class="d-flex align-items-center flex-wrap">
            <label>검색조건</label>
            <b-dropdown id="template-dropdown" variant="secondary" class="template-dropdown">
              <template #button-content>
                <span>{{ searchTag === 'temNm' ? '템플릿명' : searchTag === 'temId' ? '템플릿ID' : '브랜드명' }}</span>
                <IconArrowDown />
              </template>
              <b-dropdown-item @click="searchTag = 'temNm';">템플릿명</b-dropdown-item>
              <b-dropdown-item @click="searchTag = 'temId';">템플릿ID</b-dropdown-item>
              <b-dropdown-item @click="searchTag = 'brandNm';">브랜드명</b-dropdown-item>
            </b-dropdown>
            <div class="search-group">
              <SearchInput v-model="inputTag" @search="fnSearch" />
            </div>


            <i class="vertical-divider ml-0"></i>
            <label>템플릿 상태</label>
            <b-form-group>

              <b-form-checkbox-group id="service-checkbox-group-template2" v-model="statusSelected"
                :options="statusOptions" name="template-1"></b-form-checkbox-group>

            </b-form-group>
          </div>
        </div>
        <b-button @click="fnSearch()" variant="dark" class="btn-submit">검색</b-button>
      </div>
      <!-- 검색영역 End -->

      <!-- 리스트 영역 Start -->
      <div class="table-section card">
        <div class="d-flex align-items-center">
          <p class="list-count">전체 <span class="text-primary">{{ totCnt | formatComma }}건</span></p>
          <b-dropdown id="pageCount-dropdown" variant="secondary" class="pageCount-dropdown">
            <template #button-content>
              <span>{{ pageCount }}개씩 보기</span>
              <IconArrowDown />
            </template>
            <b-dropdown-item-button @click="handlePageCountChange(10)">10개씩 보기</b-dropdown-item-button>
            <b-dropdown-item-button @click="handlePageCountChange(20)">20개씩 보기</b-dropdown-item-button>
            <b-dropdown-item-button @click="handlePageCountChange(30)">30개씩 보기</b-dropdown-item-button>
          </b-dropdown>
          <b-button variant="secondary" class="btn-svg btn-svg-right ml-auto" @click="navigateToManage">
            <span>템플릿 등록</span>
            <IconArrowRight />
          </b-button>
          <i class="vertical-divider"></i>

          <b-button @click="fnExcelDownLoad" variant="outline-primary" class="btn-svg btn-svg-right ml-2">
            <span>엑셀 다운로드</span>
            <IconArrowLineDown />
          </b-button>

        </div>
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th class="text-center">
                  <b-form-checkbox id="selectAll" name="selectAll" v-model="listAllChecked" @change="fnListChkAll"
                    size="lg"></b-form-checkbox>
                </th>

                <th>
                  <div class="d-flex align-items-center"> <!-- NOTE: 정렬시 class명 추가하면 스타일 변경됨 -->
                    템플릿 ID
                    <IconSort />
                  </div>
                </th>
                <th>
                  <div class="d-flex align-items-center">
                    템플릿명
                    <IconSort />
                  </div>
                </th>
                <th>
                  <div class="d-flex align-items-center">
                    브랜드명
                    <IconSort class="down" />
                  </div>
                </th>
                <th>
                  <div class="d-flex align-items-center">
                    상품명
                    <IconSort />
                  </div>
                </th>
                <th>
                  <div class="d-flex align-items-center">
                    승인상태
                    <IconSort />
                  </div>
                </th>
                <!-- <th>
                  <div class="d-flex align-items-center">
                    승인일
                    <IconSort />
                  </div>
                </th> -->
                <th>
                  <div class="d-flex align-items-center">
                    등록일자
                    <IconSort />
                  </div>
                </th>
                <th>템플릿 복사</th>
              </tr>
            </thead>
            <tbody>

              <tr v-for="(contant, idx) in contants" :key="idx">
                <td class="text-center">
                  <b-form-checkbox :id=idx :value="contant.MESSAGEBASE_ID" v-model="listChkBox"
                    @change="selected($event)" size="lg"></b-form-checkbox>
                </td>

                <td>
                  <span class="text-underline" @click="templateUpdate(contant.MESSAGEBASE_ID)">{{ contant.MESSAGEBASE_ID
                    }}</span>
                </td>
                <td>{{ contant.TMPLT_NAME }}</td>
                <td>{{ contant.BRAND_NAME }}</td>
                <td>{{ contant.CARD_TYPE }}{{ contant.FORM_NAME }}</td>

                <td v-if="contant.APPROVAL_STATUS == '반려'">
                  <u><a href="#" @click.prevent="fnOpenRejectPop(contant.MESSAGEBASE_ID)">{{ contant.APPROVAL_STATUS
                      }}</a></u>
                </td>
                <td v-else>{{ contant.APPROVAL_STATUS }}</td>

                <!-- <td>{{contant.APPROVAL_DT}}</td> -->
                <td>{{ contant.REG_DT }}</td>
                <td>
                  <b-button variant="outline-secondary" size="sm"><a href="#"
                      @click="templateCopy(contant.MESSAGEBASE_ID)">템플릿 복사</a></b-button>
                </td>
              </tr>
              <tr v-if="contants.length == 0">
                <td class="text-center" colspan="10">검색된 내용이 없습니다.</td>
              </tr>
            </tbody>
          </table>

          <Pagination @fnClick="fnSearch" :listTotalCnt="totCnt" :selected="pageCount" :pageNum="pageNo"
            ref="updatePaging" />
        </div>
      </div>
      <!-- 리스트 영역 End -->
    </div>

    <AlertModal title="템플릿 삭제" desc="삭제할 항목을 선택해주세요." />
    <rcsTemplateRejectPop :rcsTemplateRejectPopOpen.sync="rcsTemplateRejectPopOpen" :msgId="msgId">
    </rcsTemplateRejectPop>
  </div>
</template>

<script>
import IconArrowDown from '@/components/service/icons/IconArrowDown.vue'
import TemplateTabs from '@/components/service/template/TemplateTabs.vue'
import SearchInput from '@/components/service/form/SearchInput.vue'
import IconSort from '@/components/service/icons/IconSort.vue';
import IconArrowRight from '@/components/service/icons/IconArrowRight.vue';
import Pagination from '@/components/service/Pagination.vue';
import AlertModal from '@/components/service/modal/AlertModal.vue'
import IconArrowLineDown from '@/components/service/icons/IconArrowLineDown.vue';
import rcsTemplateRejectPop from "@/modules/template/components/bp-rcsTemplateRejectPop.vue";
import templateApi from "@/modules/template/service/templateApi.js";

export default {
  components: { IconArrowDown, SearchInput, Pagination, IconSort, TemplateTabs, IconArrowRight, AlertModal, IconArrowLineDown, },
  name: "ucSmsTemplateList",
  data() {
    return {
      statusSelected: [],
      statusOptions: [
        { text: '전체', value: 'all' },
        { text: '등록완료', value: 'complete' },
        { text: '승인대기', value: 'wait' },
        { text: '검수시작', value: 'start' },
        { text: '승인', value: 'allow' },
        { text: '반려', value: 'notallow' },
        { text: '저장', value: 'save' },
        { text: '해지', value: 'cancel' },
      ],
      pageCount: 10,
      status: "",
      searchTag: "temNm",
      msgCat: "",
      rcsAppYn: "",
      inputTag: "",
      listSize: 10,  // select 박스 value (출력 갯수 이벤트)
      pageNo: 1,  // 현재 페이징 위치
      totCnt: 0,  //전체 리스트 수
      offset: 0, //페이지 시작점
      contants: [],
      listChkBox: [],
      rcsTemplateRejectPopOpen: false,
      msgId: ""
    }
  },
  async mounted() {
    await this.fnExistApiKey();
    await this.fnSearch();
  },
  methods: {
    handlePageCountChange(count) {
      this.pageCount = count;
      this.fnSelected(count);
    },
    navigateToManage() {
      this.$router.push(`/uc/template/rcsTemplateManage`);
    },
    async fnSearch(pageNum) {
      this.pageNo = (this.$gfnCommonUtils.defaultIfEmpty(pageNum, '1')) * 1;
      this.fnSelectRcsList();
    },
    async fnExistApiKey() {
      let params = {};
      await templateApi.selectApiKey(params).then(response => {
        const result = response.data;
        if (result.success) {
          if (this.$gfnCommonUtils.isEmpty(result.data)) {
            confirm.fnAlert('RCS 템플릿', '해당 프로젝트의 사용가능한 API 키가 존재하지 않습니다.\n템플릿을 등록/수정/검수요청 하실 수 없습니다.');
          }
        }
      });
    },
    // 검색
    async fnSelectRcsList() {
      var params = {
        "pageNo": this.pageNo,
        "listSize": this.listSize,
        "status": this.status,
        "searchTag": this.searchTag,
        "inputTag": this.inputTag,
        "msgCat": this.msgCat,
        "rcsAppYn": this.rcsAppYn
      };

      await templateApi.selectRcsTemplateList(params).then(response => {
        var result = response.data;
        if (result.success) {
          this.contants = result.data;
          this.totCnt = result.pageInfo.totCnt;
          this.offset = result.pageInfo.offset;
        }
      });
    },
    fnListChkAll() {
      if (this.listAllChecked) {
        this.listChkBox = this.contants.map(contant => contant.MESSAGEBASE_ID);
      } else {
        this.listChkBox = [];
      }
    },
    // 템플릿 등록 페이지 이동
    templateInsert() {
      var params = {};

      templateApi.corpBrandCnt(params).then(response => {
        var result = response.data;
        var resultCnt = result.data;
        if (resultCnt > 0) {
          this.$router.push({ name: "rcsTemplateManage", params: { status: "INS" } });
        } else {
          confirm.fnAlert("등록된 브랜드가 없습니다.", "");
        }
      });
    },
    // 템플릿 수정
    templateUpdate(msgId) {
      this.$router.push({ name: "rcsTemplateManageEdit", params: { status: "UPT", msgId: msgId } })
    },
    // 템플릿 복사
    templateCopy(msgId) {
      this.$router.push({ name: "rcsTemplateManage", params: { status: "CPY", msgId: msgId } })
    },
    fnOpenRejectPop(msgId) {
      this.msgId = msgId
      this.rcsTemplateRejectPopOpen = true;
    },

    fnSelected(listSize) {
      this.listSize = Number(listSize);
      this.$refs.updatePaging.fnAllDecrease();
    },
    //템플릿 엑셀 다운로드
    fnExcelDownLoad() {
      //const params = this.searchData;

      const params = {
        "status": this.status,
        "searchTag": this.searchTag,
        "inputTag": this.inputTag,
        "msgCat": this.msgCat,
        "rcsAppYn": this.rcsAppYn
      };

      templateApi.excelDownloadRcsTmplt(params);
    },

  }
};
</script>

<style scoped lang="scss">
@use "~@/assets/scss/service/base/typography" as typography;
@import '@/assets/scss/service/message.scss';
@import '@/assets/scss/service/template.scss';

.table td {
  vertical-align: middle;
}

.template-dropdown {
  min-width: 160px;
  margin: 0 16px;
}

.search-group {
  width: 355px;
  margin-right: 20px;
}

.form-group {
  margin-left: 24px;
  margin-bottom: 0;
}

.vertical-divider {
  width: 1px;
  height: 16px;
  margin: 0 20px;
  background: var(--border-color);
}

.list-view .pageCount-dropdown {
  margin-right: auto;
}
</style>
