<template>  
    <b-modal scrollable id="confirm-login-modal" :title="title" hide-header-close centered content-class="confirm-login-modal">
  
      <p class="desc" v-html="nl2br(desc)">

      </p>
      <p class="desc">남은시간 : {{timeRemaining}}초</p>
      <template #modal-footer>
        <b-button variant="danger" @click="handleSubmit">연장</b-button>
        <b-button variant="outline-secondary" @click="logout">로그아웃</b-button>
      </template>  
    </b-modal>
  </template>
  
  <script>
  import loginApi from '@/modules/login/service/api';
  export default {
    components: { },
    name: "ConfirmLoginModal",
    props: {
      title: {
        type: String,
        required: true
      },
      desc: {
        type: String,
        required: true
      },
      timeRemaining: {
        type: Number,
        required: true
      },
      onSubmit: {
        type: Function,
        default: () => { } // 기본 빈 함수
      }
    },
    data() {
      return {
      }
    },
    methods: {
      closeModal() {
        this.$bvModal.hide('confirm-login-modal');
      },
      logout() {
        this.closeModal();
        loginApi.logout().then(response => {
            if (response.data.success) {
                this.$router.push({
                    path: "/login"
                });
            }
        });
      },
      handleSubmit() {
  
        if (this.onSubmit) {
          this.onSubmit()
        }
        this.closeModal()
      },
      nl2br(str) {
        if (typeof str === 'string') {
          return str.replace(/\n/g, '<br>');
        }
        return str;
      },
    }
  };
  </script>
  
  <style scoped lang="scss">
  </style>
  