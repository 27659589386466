<template>
  <LoginFormField :id="id" :justify="justify" :items="items" :label="label" :label-style="labelStyle" :required="required">
    <div style="display: flex; gap: 20px;">
      <slot />
    </div>
  </LoginFormField>
</template>

<script>
import LoginFormField from './LoginFormField.vue';

export default {
  name: 'LoginFormRadioGroupField',

  model: {
    prop: 'modelValue',
    event: 'input'
  },

  props: {
    justify: {
      type: String,
      default: 'flex-start'
    },
    items: {
      type: String,
      default: 'flex-start'
    },
    row: {
      type: Boolean,
      default: () => true
    },
    modelValue: [String, Number],
    label: String,
    labelStyle: Object,
    required: Boolean,
    id: {
      type: String,
      default: () => `id-${crypto.randomUUID()}`
    },
    name: {
      type: String,
      default: () => `name-${crypto.randomUUID()}`
    }
  },

  emits: ['input'],

  computed: {
    
  },

  provide() {
    return {
      withInRadioGroup: true,
      name: this.$props.name,
      modelValue: this.$props.modelValue,
      onClickRadio: (value) => {
        this.$emit('input', value)
      }
    }
  },

  components: {
    LoginFormField
  }
}
</script>

<style lang="scss" scoped>
@use "../../../assets/scss/service/abstracts/variables" as *;

.login-form__field {
  position: relative;

  input[type="checkbox"] {
    display: none;

    ~ label {
      display: flex;
      gap: 6px;
      align-items: center;
      margin: 0;
      cursor: pointer;
      user-select: none;

      &::before {
        display: block;
        width: 16px;
        height: 16px;
        border: 1px solid #6D6EFA;
        border-radius: 2px;
        box-sizing: border-box;
        content: '';
      }
    }

    &:checked ~ label {
      &::before {
        border: 0;
        background-repeat: no-repeat;
        background-size: contain;
        background-image: url('#{$icon}icon-check-on.svg');
      }
    }
  }
}
</style>