<template>  
  <b-modal scrollable id="alert-modal2" :title="title" hide-header-close centered content-class="alert-modal2">

    <p class="desc" v-html="desc"></p>

    <template #modal-footer>
      <b-button variant="outline-primary" @click="closeModal">닫기</b-button>
    </template>  
  </b-modal>
</template>

<script>
export default {
  name: "AlerModal2",
  props: {
    title: {
      type: String,
      required: true
    },
    desc: {
      type: String,
      required: true
    },
    callbackFunc: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
    }
  },
  methods: {
    closeModal() {
      this.$bvModal.hide('alert-modal2');
      if (this.callbackFunc) {
        this.callbackFunc();
      }
    },
  }
};
</script>

<style lang="scss" scoped>
@use "../../scss/service/base/typography" as typography;

::v-deep(.modal-dialog) {
  border-radius: 24px;

  @media (min-width: 300px) {
    margin: 0 auto !important;
    width: 87% !important;
    max-width: 87% !important;
  }

  @media (min-width: 375px) {
    margin: 0 auto !important;
    width: 87% !important;
    max-width: 87% !important;
  }

  @media (min-width: 768px) {
    width: 684px !important;
    max-width: 684px !important;
  }
}

::v-deep(.modal-content) {
  padding: 0 24px;
  background: #ffffff;
  border-radius: 12px;

  &.confirm-modal,
  &.alert-modal {
    background-color: var(--white);

    @media (min-width: 300px) {
      margin: 0 auto;
      width: 280px;
      max-width: 280px;
    }

    @media (min-width: 375px) {
      margin: 0 auto;
      width: 335px;
      max-width: 335px;
    }

    @media (min-width: 768px) {
      margin: 0;
      width: 684px;
      max-width: 684px;
    }

    .modal-title {
      @include typography.font-style(20px, 700, 140%, -0.4px);
      color: var(--grary-900);

      @media (min-width: 768px) {
        text-align: initial !important;
      }
    }

    .modal-body {
      padding: 0;

      @media (min-width: 768px) {
        text-align: initial !important;
      }
    }

    .desc {
      margin: 0;
      @include typography.font-style(16px, 500, 140%, -0.32px);
      color: #6B7280;
    }

    .modal-footer {
      justify-content: end;

      @media (min-width: 300px) {
        justify-content: center;
      }

      @media (min-width: 375px) {
        justify-content: center;
      }

      @media (min-width: 768px) {
        justify-content: end;
      }
    }
  }
}

::v-deep(.modal-header) {
  border: none;
  padding: 24px 0 20px;
}

::v-deep(.modal-title) {
  width: 100%;
  @include typography.font-style(20px, 700, 28px, -0.02em);
  color: var(--grary-900);
}

::v-deep(.title-desc) {
  margin: 20px 0 0;
  @include typography.font-style(14px, 700, 140%, -0.28px);
  color: var(--gray500);

  span {
    color: var(--status-failure);
  }
}

::v-deep(.modal-body) {
  max-height: 740px;
  padding: 28px;
  background-color: var(--white);
  border-radius: 12px;
  overflow-y: auto;

  @media (min-width: 300px) {
      text-align: center !important;
      padding: 10px;
    }

  @media (min-width: 375px) {
      text-align: center !important;
      padding: 10px;
    }
}

::v-deep(.modal-footer) {
  border: none;
  justify-content: center;
  padding: 20px 0 24px;

  .btn {
    min-width: 100px;
    margin: 0;

    & + .btn {
      margin-left: 12px;
    }
  }
}

::v-deep(.modal-xl) {
  width: 1159px;
  max-width: 1159px;
}

::v-deep(.modal-lg) {
  width: 931px;
  max-width: 931px;
}

::v-deep(.modal-md) {
  width: 684px;
  max-width: 684px;
}

::v-deep(.modal-s) {
  width: 424px;
  max-width: 424px;
}

::v-deep(.modal-xs) {
  width: 384px;
  max-width: 384px;
}

::v-deep(.modal-xxs) {
  width: 324px;
  max-width: 324px;
}

::v-deep(.modal-backdrop) {
  background-color: rgba(0, 0, 0, .4);
}

::v-deep(#search-address-modal .modal-dialog) {
  width: 955px;
  max-width: 955px;
}

::v-deep(#select-image-modal .btn-icon path) {
  stroke: var(--primary);
}

::v-deep(#talk-move-request-modal .modal-body) {
  overflow: visible;
}

</style>