<template>
  <LoginFormField :id="id" :justify="justify" :items="items" :label="label" :label-style="labelStyle" :required="required">
    <div style="display: flex; flex-direction: column; width: 100%">
      <div class="login-form__field__input">
        <div role="button" class="login-form__field__input__file-upload-guide" @click="$refs.$file.click()">
          <IconFile />
          <div>
            <strong>파일을 등록해주세요</strong>
          </div>
          <div>
            <small>최대 1부 등록이 가능합니다</small>
          </div>
        </div>

        <input
          ref="$file"
          :id="id"
          :name="name"
          type="file"
          @input="onInput"
        />
      </div>

      <div class="login-form__field__input__file-name">
        <IconFile />
        <span>{{ fileName }}</span>
      </div>
    </div>
  </LoginFormField>
</template>

<script>
import IconFile from '../../service/icons/IconFile.vue';
import LoginFormField from './LoginFormField.vue';

export default {
  name: 'LoginFormFileField',

  props: {
    justify: {
      type: String,
      default: 'flex-start'
    },
    items: {
      type: String,
      default: 'flex-start'
    },
    fileName: String,
    label: String,
    labelStyle: Object,
    id: {
      type: String,
      default: () => `id-${crypto.randomUUID()}`
    },
    name: String,
    required: Boolean,
  },

  emits: ['change'],

  data: () => ({
    
  }),

  computed: {
    
  },

  methods: {
    onInput(e) {
      this.$emit('change', e.target.files[0])
    }
  },

  components: {
    LoginFormField,
    IconFile
  },
}
</script>

<style lang="scss" scoped>
@use "../../../assets/scss/landing/abstracts/variables" as *;

.login-form__field {
  position: relative;

  &__input {
    position: relative;
    flex: 1;
    width: 100%;
    
    input {
      display: none;
    }

    &__file-upload-guide {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: #E9E9FE;
      border: 1px solid #D2D2FD;
      color: #6D6EFA;
      font-size: 16px;
      line-height: 1.4;
      gap: 4px;

      @media (min-width: 300px) {
        padding: 20px 16px;
        border-radius: 8px;
        font-size: 14px;
      }

      @media (min-width: 375px) {
        padding: 20px 16px;
        border-radius: 8px;
        font-size: 14px;
      }

      @media (min-width: 1024px) {
        padding: 20px 16px;
        border-radius: 8px;
        font-size: 16px;
      }

      :deep svg {
        margin-bottom: 4px;
      }

      strong {
        font-weight: 700;
        font-size: 1em;
      }

      small {
        font-weight: 400;
        font-size: 12px;
      }
    }

    &__file-name {
      display: flex;
      align-items: center;
      gap: 8px;
      margin-top: 12px;
      background-color: #F3F4F6;
      border-radius: 8px;
      padding: 10px 8px;
      color: #6B7280;
      font-size: 14px;
      font-weight: 400;
    }
  }
}
</style>