<template>
  <div class="signup-wrapper__container__contents__wrapper">
    <div class="signup-wrapper__container__contents__wrapper__header">
      회원가입
    </div>

    <div class="signup-wrapper__container__contents__wrapper__main">
      <div class="signup-wrapper__container__contents__wrapper__main__title">
        2. 회사정보 입력
      </div>

      <div class="signup-wrapper__container__contents__wrapper__main__form">
        <LoginForm>
          <div style="display: flex; gap: 4px; align-items: flex-end; flex: 1;">
            <LoginFormTextField label="사업자 번호 입력" placeholder="사업자 번호를 검색해주세요" items="flex-start" style="flex: 1;"
              required />
            <button @click="$bvModal.show('signup-modal')">확인</button>
          </div>

          <LoginFormTextField v-for="field in fields1" :key="field.label" v-model="field.value" :label="field.label"
            :type="field.type" :placeholder="field.placeholder" :desc="field.desc" items="flex-start"
            :readonly="field.readonly" required />

          <LoginFormRadioGroupField label="고객유형" required>
            <LoginFormRadio label="개인" />
            <LoginFormRadio label="개인사업자" />
            <LoginFormRadio label="법인사업자" />
          </LoginFormRadioGroupField>

          <LoginFormTextField v-for="field in fields2" :key="field.label" v-model="field.value" :label="field.label"
            :type="field.type" :placeholder="field.placeholder" :desc="field.desc" items="flex-start"
            :readonly="field.readonly" required />

          <LoginFormFileField v-for="file in files" :key="file.label" :label="file.label" :file-name="file.fileName"
            @change="$file => file.fileName = $file.name" />
        </LoginForm>

        <div class="address-input-wrapper">
          <div class="address-input-wrapper__title">
            사업장 주소
          </div>

          <div class="address-input-wrapper__contents">
            <LoginForm row>
              <LoginFormTextField label="우편번호" placeholder="우편번호를 입력해주세요" items="flex-start" style="flex: 1;"
                required />

              <div style="display: flex; gap: 4px; align-items: flex-end; flex: 1;">
                <LoginFormTextField label="주소 입력" placeholder="주소를 검색해주세요" items="flex-start" style="flex: 1;"
                  :label-style="{
                    width: '69px',
                    minWidth: '69px',
                  }" required />
                <button>주소 검색</button>
              </div>
            </LoginForm>

            <div class="address-input-wrapper__contents__divider"></div>

            <LoginForm>
              <LoginFormTextField label="상세주소 입력" placeholder="상세주소를 입력해주세요" items="flex-start" required />
              <LoginFormTextField label="유선번호 입력" placeholder="유선번호를 입력해주세요" items="flex-start" required />
            </LoginForm>
          </div>
        </div>
      </div>
    </div>

    <SignupModal title="기업 선택" :buttons="{
      cancel: { text: '닫기' },
      confirm: { text: '선택' },
    }">
      <template #signup-contents>
        <SignupModalTable :data="Array.from({ length: 20 }, () => ([
          { name: '고객사 / 대표자명', value: '염단라탁' },
          { name: '사업회사', value: '비트큐브' },
        ]))" />
      </template>
    </SignupModal>
  </div>
</template>

<script>
import LoginForm from '../../../components/landing/login/LoginForm.vue';
import LoginFormRadioGroupField from '../../../components/landing/login/LoginFormRadioGroupField.vue';
import LoginFormRadio from '../../../components/landing/login/LoginFormRadio.vue';
import LoginFormTextField from '../../../components/landing/login/LoginFormTextField.vue';
import LoginFormFileField from '../../../components/landing/login/LoginFormFileField.vue';
import SignupModal from '../../../components/landing/signup/SignupModal.vue';
import SignupModalTable from '../../../components/landing/signup/SignupModalTable.vue';

export default {
  data: () => ({
    fields1: [
      { label: '고객번호 입력', type: 'text', value: '000000', readonly: true, placeholder: '고객번호를 입력해주세요', desc: '고객사 신규 고객일 경우 자동 생성됩니다.' },
    ],
    fields2: [
      { label: '생년월일/법인번호', type: 'text', value: '', placeholder: '생년월일 또는 법인번호를 입력해주세요.' },
      { label: '사업자명', type: 'text', value: '', placeholder: '사업자명을 입력해주세요', desc: '특수문자 입력 불가, 1~20자 입력' },
      { label: '대표자명', type: 'text', value: '', placeholder: '대표자명을 입력해주세요', desc: '특수문자 입력 불가, 1~20자 입력' },
      { label: '업태', type: 'text', value: '', placeholder: '업태를 입력해주세요', desc: '특수문자 입력 불가, 1~20자 입력' },
      { label: '업종', type: 'text', value: '', placeholder: '업종을 입력해주세요', desc: '특수문자 입력 불가, 1~20자 입력' },
    ],
    files: [
      { label: '사업자등록증', fileName: '', required: true },
      { label: '재직증명서', fileName: '', required: true },
    ]
  }),

  components: {
    LoginForm,
    LoginFormTextField,
    LoginFormRadio,
    LoginFormRadioGroupField,
    LoginFormFileField,
    SignupModal,
    SignupModalTable
  },

  methods: {

  },
}
</script>

<style lang="scss" scoped>
@use "../../../assets/scss/landing/abstracts/variables" as v;
@use "../../../assets/scss/service/abstracts/variables" as *;

.signup-wrapper__container__contents__wrapper__main__form :deep .login-form {
  @media (min-width: 375px) {
    --gap: 20px !important;
  }

  @media (min-width: 1024px) {
    --gap: 40px !important;
  }
}

.address-input-wrapper {
  color: #6B7280;

  &__title {
    font-weight: 700;

    @media (min-width: 300px) {
      font-size: 14px;
      margin-top: 20px;
    }

    @media (min-width: 375px) {
      font-size: 14px;
      margin-top: 20px;
    }

    @media (min-width: 1024px) {
      font-size: 18px;
      margin-top: 0;
    }

    &::after {
      display: block;
      width: 100%;
      height: 0;
      border-bottom: 1px solid #E5E7EB;
      content: '';

      @media (min-width: 300px) {
        padding-top: 12px;
      }

      @media (min-width: 375px) {
        padding-top: 12px;
      }

      @media (min-width: 1024px) {
        padding-top: 20px;
      }
    }
  }

  &__contents {
    @media (min-width: 300px) {
      padding-top: 20px;
    }

    @media (min-width: 375px) {
      padding-top: 20px;
    }

    @media (min-width: 1024px) {
      padding-top: 40px;
    }

    &__divider {
      @media (min-width: 300px) {
        margin: 20px 0;
      }

      @media (min-width: 375px) {
        margin: 20px 0;
      }

      @media (min-width: 1024px) {
        margin: 40px 0;
      }
    }

    button {
      width: 100%;

      @media (min-width: 300px) {
        padding: 0;
        max-width: 83px
      }

      @media (min-width: 375px) {
        padding: 0;
        max-width: 83px
      }

      @media (min-width: 1024px) {
        padding: 0;
        max-width: 75px;
      }
    }
  }
}

button {
  padding: 0 25.5px;
  height: 44px;
  background-color: #6D6EFA;
  border-radius: 8px;
  border: 0;
  color: #FFFFFF;
  font-size: 14px;
  font-weight: 700;

  @media (min-width: 300px) {
    font-weight: 400;
    padding: 0 16px;
    height: 41px;
  }

  @media (min-width: 375px) {
    font-weight: 400;
    padding: 0 16px;
    height: 41px;
  }

  @media (min-width: 1024px) {
    font-weight: 700;
    padding: 0 25.5px;
    height: 44px;
  }
}

@media (min-width: 300px) {
  .address-input-wrapper__title {
      font-size: 16px;
  }
    }
</style>