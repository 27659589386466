<template>
  <div class="uc-sms-tempalte-manage">
    <TemplateTabs />

    <!-- //phoneWrap -->
    <div class="d-flex tab-contents">
      <div class="card">
        <div class="preview p-sticky">
          <p class="preview-title">미리보기</p>
          <PreviewDefault size="lg">
            <div class="preview-section sms">

              <div v-if="tmpltData.senderType == 'MMS' || tmpltData.senderType == 'LMS'" class="phoneText2 mb10">
                <p class="m-0 f-body5 c-gray900" v-if="$gfnCommonUtils.isEmpty(tmpltData.tmpltTitle)">템플릿 제목</p>
                <p class="m-0 f-body5 c-gray900" v-else><span
                    v-if="(tmpltData.senderType == 'MMS' || tmpltData.senderType == 'LMS') && tmpltData.msgKind == 'A'">(광고)</span>{{
                      tmpltData.tmpltTitle }}
                </p>
              </div>

              <p class="m-0 mt-2 f-caption2 c-gray700" v-if="$gfnCommonUtils.isEmpty(tmpltData.tmpltContent)">템플릿 내용</p>
              <p class="m-0 mt-2 f-caption2 c-gray700" v-else>
                <span>
                  <span v-if="tmpltData.senderType == 'SMS' && tmpltData.msgKind == 'A'">(광고)</span>
                  <pre class="m-0 f-caption2">{{ tmpltData.tmpltContent }}</pre>
                </span>
                <br v-if="!$gfnCommonUtils.isEmpty(tmpltData.tmpltContent)" />
                <span v-if="tmpltData.msgKind == 'A' && !$gfnCommonUtils.isEmpty(tmpltData.rcvblcNumber)">
                  {{ tmpltData.rcvblcNumber }}
                </span>
              </p>
            </div>

            <div v-if="tmpltData.senderType == 'MMS'" class="preview-img">
              <div v-if="!tmpltData.imgInfoList || tmpltData.imgInfoList.length === 0" class="empty-img">
                <img src="@/assets/images/service/preview/preview-img.svg" alt="" class="img-icon">
                <p class="m-0 mt-1 c-caption2 c-gray500">
                  작성페이지에서 이미지를<br />
                  삽입할 수 있습니다.</p>
              </div>
              <div v-else v-for="(imgInfo, idx) in tmpltData.imgInfoList" :key="idx" class="empty-img mb-1">
                <img :src="imgInfo.imgUrl" :alt="imgInfo.imgName">
              </div>
            </div>

            <!-- <img src="" alt=""> -->
          </PreviewDefault>
        </div>
      </div>

      <!-- //우측 등록 -->
      <div class="section card flex-fill">
        <p class="title f-title1 c-gray900">템플릿 등록</p>
        <div class="form-wrap">
          <div class="form-row">
            <label for="name">템플릿 명 <span class="require">*</span></label>
            <b-input v-model="tmpltData.tmpltName" id="name"></b-input>
          </div>
          <div class="form-row">
            <label for="desc">템플릿 설명</label>
            <b-input v-model="tmpltData.tmpltDesc" id="desd"></b-input>
          </div>
          <div class="form-row">
            <label>발송유형 <span class="require">*</span></label>
            <b-form-group class="radio-group">
              <b-form-radio-group inline v-model="tmpltData.senderType">
                <b-form-radio v-model="tmpltData.senderType" name="senderType" value="SMS">SMS</b-form-radio>
                <b-form-radio v-model="tmpltData.senderType" name="senderType" value="LMS">LMS</b-form-radio>
                <b-form-radio v-model="tmpltData.senderType" name="senderType" value="MMS">MMS</b-form-radio>
              </b-form-radio-group>
            </b-form-group>
          </div>
          <div class="form-row">
            <label>메시지구분 <span class="require">*</span></label>
            <b-form-group class="radio-group">
              <b-form-radio-group inline v-model="tmpltData.msgKind">
                <b-form-radio v-model="tmpltData.msgKind" name="msgKind" value="A">광고성</b-form-radio>
                <b-form-radio v-model="tmpltData.msgKind" name="msgKind" value="I">정보성</b-form-radio>
              </b-form-radio-group>
            </b-form-group>
          </div>
          <div class="form-row" v-if="tmpltData.senderType == 'LMS' || tmpltData.senderType == 'MMS'">
            <label>제목 <span class="require">*</span></label>
            <b-input id="desd" v-model="tmpltData.tmpltTitle" @input="fnSetCurrByte"></b-input>
          </div>
          <div class="form-row align-items-start">
            <label for="content">내용 <span class="require">*</span></label>
            <div>
              <b-form-textarea id="content" rows="6" max-rows="6" :placeholder="contentAreaPlaceholder"
                v-model="tmpltData.tmpltContent" maxlength="2000" @input="fnSetCurrByte"></b-form-textarea>
              <p class="m-0 mt-1 pt-2 f-body5 c-gary600">({{ msgCurrByte | formatComma }} / {{ msgLimitByte |
                formatComma }})</p>
            </div>
          </div>

          <div v-if="tmpltData.msgKind == 'A'" class="form-row">
            <label class="f-body5 c-gray500">광고성메시지 <span class="require">*</span>
              <br />수신거부번호</label>
            <b-input-group>
              <b-input-group-append>
                <b-button variant="dark" v-b-modal.block-number-modal>선택</b-button>
              </b-input-group-append>
              <b-form-input v-model="tmpltData.rcvblcNumber" @input="fnSetCurrByte"
                placeholder="ex) 수신거부번호: 123-1234-1234"></b-form-input>
            </b-input-group>
          </div>

          <div class="form-row" v-if="tmpltData.senderType == 'MMS'">
            <label class="form-label">이미지</label>
            <div class="content-image">
              <b-button variant="outline-primary" size="sm" v-b-modal.select-image-modal>이미지 선택</b-button>
              <ul v-for="imgIdx in imgLimitSize" :key="imgIdx" class="image-select-list m-0 p-0">

                <li class="text-truncate" v-if="tmpltData.imgInfoList.length > imgIdx - 1">
                  <button type="button" class="btn btn-icon p-0">{{ fnSubString(tmpltData.imgInfoList[imgIdx -
                    1].imgUrl,
                    0, 35) }}
                    <IconClose @click="fnDelImg(imgIdx)" />
                  </button>
                </li>
                <!-- 이미지 없을때 확인필요 -->
                <li v-else>
                  <a></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <hr class="hr" />
        <div class="submit-wrap">
          <b-button @click="goBackToList" variant="secondary" size="lg">취소</b-button>
          <b-button v-if="isInsert" @click="fnSaveSmsTemplate" variant="primary" size="lg">등록</b-button>
          <b-button v-else @click="fnSaveSmsTemplate" variant="primary" size="lg">수정</b-button>
        </div>
      </div>
    </div>

    <SelectImageModal @img-callback="fnCallbackImgInfo" :useCh="useCh" />
    <BlockNumberModal @callback-func="fnCallbackRcvblcNum" />
    <AlertModal :title="alertTitle" :desc="alertDesc" />
    <ConfirmModal :title="confirmTitle" :desc="confirmDesc" :onSubmit="callback" />


  </div>
</template>

<script>
import TemplateTabs from '@/components/service/template/TemplateTabs.vue';
import SelectImageModal from '@/modules/message/components/modal/SelectImageModal.vue';
import BlockNumberModal from '@/modules/template/components/modal/BlockNumberModal.vue';
import ConfirmModal from '@/components/service/modal/ConfirmModal.vue';
import AlertModal from '@/components/service/modal/AlertModal.vue';
import PreviewDefault from '@/components/service/preview/PreviewDefault.vue';
import IconClose from '@/components/service/icons/IconClose.vue';
import templateApi from "@/modules/template/service/templateApi.js";

export default {
  components: { SelectImageModal, TemplateTabs, PreviewDefault, IconClose, BlockNumberModal, ConfirmModal, AlertModal },
  name: "smsTemplateManage",
  data() {
    return {
      displayTitle: '',
      displayContent: '',
      imgLimitSize: 3,
      msgCurrByte: 0,
      msgLimitByte: 0,
      titleLimitByte: 40,
      useCh: 'MMS',
      imageInfo: [],
      beforeSenderTyep: 'SMS',
      beforemsgKind: 'I',
      isInsert: true,
      confirmTitle: '',
      confirmDesc: '',
      alertTitle: '',
      alertDesc: '',
      title: '',
      desc: '',
      callback: () => { }, // 기본 함수로 초기화
      contentAreaPlaceholder: '변수로 설정하고자 하는 내용을 #{ }표시로 작성해 주십시오.\n:예) 이름과 출금일을 변수 설정\n:예) #{name}님 #{yyyymmdd} 출금 예정입니다.',
      tmpltData: { tmpltContent: '', msgKind: 'I', senderType: 'SMS', imgInfoList: [] },
      originalParams: {} //상세화면에서 목록올때 기존 검색조건, 페이징 정보 세팅 목록에서 온 원본 params를 저장할 데이터
    }
  },
  props: {
    tmpltId: {
      type: String,
      require: false,
      default: function () {
        return '';
      }
    },
    componentsTitle: {
      type: String,
      require: false,
      default: function () {
        return '문자';
      }
    },
  },
  created() {
    //상세화면에서 목록올때 기존 검색조건, 페이징 정보 세팅 
    this.originalParams = { ...this.$route.params, fromDetailPage: true, };


  },
  mounted() {
    this.fnValidUseChGrp();
    this.fnSetTemplateInfo();
    this.fnGetLimitByte();
    this.fnSetCurrByte();
  },
  watch: {
    tmpltData: {
      deep: true,
      handler(nData) {
        if (nData.senderType != this.beforeSenderTyep) {
          this.beforeSenderTyep = nData.senderType;
          this.fnGetLimitByte();
        }
        if (nData.msgKind != this.beforemsgKind) {
          this.beforemsgKind = nData.msgKind;
          this.fnSetCurrByte();
        }
      }
    },
    messageType(newValue) {
      if (newValue === 'a') {
        this.title = '(광고)' + this.title
      }
    }
  },
  methods: {
    // updateContent() {
    //   this.displayContent = this.nl2br(this.tmpltData.tmpltContent);
    // },
    // nl2br(str) {
    //   if (typeof str === 'string') {
    //     return str.replace(/\n/g, '<br>');
    //   }
    //   return str;
    // },
    showConfirm(message, method) {
      if (typeof method !== 'function') {
        method = () => { }; // callback이 없는 경우 빈 함수 
      }

      this.confirmTitle = 'SMS/MMS 템플릿'
      this.confirmDesc = message
      this.callback = method
      this.$bvModal.show('confirm-modal');
    },
    showAlert(msg) {
      this.alertTitle = 'SMS/MMS 템플릿'
      this.alertDesc = msg
      this.$bvModal.show('alert-modal');
    },
    fnSetCurrByte() {
      let body = this.$gfnCommonUtils.defaultIfEmpty(this.tmpltData.tmpltContent, '');
      let rcvblcNum = this.$gfnCommonUtils.defaultIfEmpty(this.tmpltData.rcvblcNumber, '');
      let totalMsg = body;
      if (this.tmpltData.msgKind == 'A') {
        totalMsg += rcvblcNum + (this.tmpltData.senderType == 'SMS' ? '(광고)' : '');
      }
      this.msgCurrByte = this.getByte(totalMsg);
    },
    fnGetLimitByte() {
      if (this.tmpltData.senderType == 'SMS') {
        this.msgLimitByte = 90;
      } else if (this.tmpltData.senderType == 'LMS') {
        this.msgLimitByte = 1000;
      } else if (this.tmpltData.senderType == 'MMS') {
        this.msgLimitByte = 2000;
      }
    },
    fnCallbackRcvblcNum(rcvblcNum) {
      this.tmpltData.rcvblcNumber = rcvblcNum;
      this.fnSetCurrByte();
    },
    async fnValidUseChGrp() {
      let params = { chGrp: 'SMS/MMS' };
      await templateApi.selectValidUseChGrp(params).then(response => {
        const result = response.data;
        if (result.success) {
          if (this.$gfnCommonUtils.isEmpty(result.data)) {
            this.showAlert('이용하실 수 없는 채널입니다.');
            this.$router.back();
          }
        } else {
          this.showAlert('시스템 오류입니다. 잠시 후 다시 시도하세요.');
          this.$router.back();
        }
      });
    },
    //template 정보 조회
    fnSetTemplateInfo() {
      if (!this.$gfnCommonUtils.isEmpty(this.tmpltId)) {
        this.isInsert = false;
        this.fnSelectSmsTmpltInfo();
      } else {
        this.isInsert = true;
      }
    },
    //템플릿 정보 조회
    fnSelectSmsTmpltInfo() {
      const vm = this;
      const params = { tmpltId: this.tmpltId };
      templateApi.selectSmsTmpltInfo(params).then(response => {
        const result = response.data;
        if (result.success) {
          const targetField = ['tmpltName', 'tmpltDesc', 'tmpltTitle', 'tmpltContent', 'rcvblcNumber'];
          let tempData = Object.assign({}, this.tmpltData);
          result.data.forEach(function (obj) {
            tempData = obj;
            if (!vm.$gfnCommonUtils.isEmpty(obj.imgInfoList)) {
              tempData.imgInfoList = JSON.parse(obj.imgInfoList);
            } else {
              tempData.imgInfoList = [];
            }
            vm.$gfnCommonUtils.unescapeXssFields(tempData, targetField);
          });
          this.tmpltData = Object.assign({}, tempData);
          this.fnSetCurrByte();
        } else {
          this.showAlert(result.message);
          this.tmpltData = {};
          this.isInsert = true;
        }
      });
    },
    //유효성 체크
    fnIsValid() {
      if (!this.tmpltData.tmpltName) {
        this.showAlert("템플릿명을 입력해주세요.");
        return false;
      }
      if (!this.tmpltData.senderType) {
        this.showAlert("발송유형을 선택해주세요.");
        return false;
      }
      if (!this.tmpltData.msgKind) {
        this.showAlert("메시지구분을 선택해주세요.");
        return false;
      }
      if (this.tmpltData.senderType == 'MMS') {
        if (this.tmpltData.tmpltTitle == false) {
          this.showAlert("메시지 제목을 입력해주세요.");
          return false;
        }
        let title = this.$gfnCommonUtils.defaultIfEmpty(this.tmpltData.tmpltTitle, '');
        let totalTitle = (this.tmpltData.msgKind == 'A' ? '(광고)' : '') + title;
        let titleCurrByte = this.getByte(totalTitle);
        if (this.titleLimitByte < titleCurrByte) {
          const alertMsg = (this.tmpltData.msgKind != 'A' ? '' : '\'(광고)\' + ') + '제목이 ' + this.titleLimitByte + 'byte를 넘지 않아야됩니다.\n(현재 : ' + titleCurrByte + 'byte)';
          this.showAlert(alertMsg);
          return false;
        }
      }
      if (this.tmpltData.senderType == 'MMS' && !this.tmpltData.tmpltTitle) {
        this.showAlert("메시지 제목을 입력해주세요.");
        return false;
      }
      if (!this.tmpltData.tmpltContent) {
        this.showAlert("메시지 내용을 입력해주세요.");
        return false;
      }
      if (this.tmpltData.msgKind == 'A' && !this.tmpltData.rcvblcNumber) {
        this.showAlert("광고성메시지 수신거부번호를 입력해주세요.");
        return false;
      }
      if (this.msgLimitByte < this.msgCurrByte) {
        const alertMsg = '내용 + 광고성메시지 수신거부번호가 ' + this.msgLimitByte + 'byte를 넘지 않아야됩니다.\n(현재 : ' + this.msgCurrByte + 'byte)';
        this.showAlert(alertMsg);
        return false;
      }

      return true;
    },
    //저장
    fnSaveSmsTemplate() {
      //유효성 검사
      if (this.fnIsValid() == false) return;
      const saveType = (this.isInsert ? '등록' : '수정');

      this.showConfirm("템플릿을 " + saveType + "하시겠습니까?", this.fnProcSaveSmsTemplate);
    },
    async fnProcSaveSmsTemplate() {
      //DATA Set
      let params = Object.assign({}, this.tmpltData);
      if (this.tmpltData.senderType == 'SMS') {
        params.tmpltTitle = '';
      }
      if (this.tmpltData.msgKind != 'A') {
        params.rcvblcNumber = '';
      }
      if (this.tmpltData.imgInfoList.length == 0) {
        params.imgInfoList = [];
      } else {
        params.imgInfoListStr = JSON.stringify(this.tmpltData.imgInfoList);
      }

      //저장처리
      await templateApi.saveSmsTmplt(params).then(response => {
        const result = response.data;
        if (result.success) {
          this.showConfirm('저장되었습니다.', this.fnSaveAlertCallback);
        } else {
          this.showConfirm(result.message, null);
        }
      });
    },
    fnSaveAlertCallback() {
      this.$router.push('smsTemplateList')
    },
    fnDelImg(idx) {
      this.tmpltData.imgInfoList.splice(idx - 1, 1);
    },
    fnSubString(str, sIdx, length) {
      let shortStr = ''
      if (!this.$gfnCommonUtils.isEmpty(str)) {
        shortStr = str.toString();
        if (shortStr.length > length) {
          shortStr = shortStr.substring(sIdx, length) + '...  ';
        } else {
          shortStr = shortStr + '  ';
        }
      }
      return shortStr;
    },
    fnCallbackImgInfo(imgInfo) {

      const processSingleImgInfo = (singleImgInfo) => {
        let temp = {
          imgUrl: singleImgInfo.chImgUrl,
          fileId: singleImgInfo.fileId,
        };

        if (singleImgInfo.rtnParams && singleImgInfo.rtnParams.updIdx > 0) {
          this.tmpltData.imgInfoList[singleImgInfo.rtnParams.updIdx - 1] = temp;
        } else {
          if (this.fnImgLimitSize() === false) return;
          this.tmpltData.imgInfoList.push(temp);
        }
      };

      // imgInfo가 배열인지 단건인지 구분
      if (Array.isArray(imgInfo)) {
        imgInfo.forEach(processSingleImgInfo);
      } else {
        processSingleImgInfo(imgInfo);
      }
    },
    fnDelDuplImgInfo() {
      const vm = this;
      this.tmpltData.imgInfoList = this.tmpltData.imgInfoList.filter(function (item, i) {
        return (
          vm.tmpltData.imgInfoList.findIndex((item2) => {
            return item.fileId === item2.fileId;
          }) === i
        );
      });
    },
    fnImgLimitSize() {
      if (this.tmpltData.imgInfoList != null && this.tmpltData.imgInfoList.length >= this.imgLimitSize) {
        confirm.fnAlert(this.componentsTitle, '이미지는 최대 ' + this.imgLimitSize + '개까지 등록 가능합니다.');
        return false;
      }
    },
    //get 문자열 byte
    getByte(str) {
      return str
        .split('')
        .map(s => s.charCodeAt(0))
        .reduce((prev, c) => (prev + ((c === 10) ? 2 : ((c >> 7) ? 2 : 1))), 0);
    },
    //단축 URL 선택
    btnSelect(shortendUrl) {
      if (this.tmpltData.tmpltContent.length > 0)
        this.tmpltData.tmpltContent += '\n'

      this.tmpltData.tmpltContent += shortendUrl
    },
    //상세화면에서 목록올때 기존 검색조건, 페이징 정보 세팅 
    goBackToList() {
      // 원본 params를 그대로 smsTemplateList 페이지로 전달
      this.$router.push({
        name: 'smsTemplateList',
        params: this.originalParams
      });
    }
  }
};
</script>

<style scoped lang="scss">
@use '@/assets/scss/service/message.scss';
@use '@/assets/scss/service/template.scss';

.input-group-append {
  .btn {
    width: 120px;
    margin-right: 12px;
  }

  input {
    width: 340px;
  }
}

.content-image {
  display: flex;
  align-items: center;

  .btn {
    margin-right: 28px;
  }

  li {
    display: flex;
    align-items: center;

    span {
      max-width: 240px;
      margin-right: 12px;
    }
  }
}
</style>
