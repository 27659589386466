<template>
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
  <path d="M1.66671 4.89427L2.73337 3.83594L16.6667 17.7693L15.6084 18.8359L13.0417 16.2693C12.0834 16.5859 11.0667 16.7526 10 16.7526C5.83337 16.7526 2.27504 14.1609 0.833374 10.5026C1.40837 9.03594 2.32504 7.74427 3.49171 6.71927L1.66671 4.89427ZM10 8.0026C10.6631 8.0026 11.299 8.266 11.7678 8.73484C12.2366 9.20368 12.5 9.83956 12.5 10.5026C12.5 10.7943 12.45 11.0776 12.3584 11.3359L9.16671 8.14427C9.42504 8.0526 9.70837 8.0026 10 8.0026ZM10 4.2526C14.1667 4.2526 17.725 6.84427 19.1667 10.5026C18.4834 12.2359 17.325 13.7359 15.8334 14.8276L14.65 13.6359C15.7834 12.8526 16.7167 11.7859 17.35 10.5026C15.975 7.7026 13.1334 5.91927 10 5.91927C9.09171 5.91927 8.20004 6.06927 7.36671 6.33594L6.08337 5.06094C7.28337 4.54427 8.60837 4.2526 10 4.2526ZM2.65004 10.5026C4.02504 13.3026 6.86671 15.0859 10 15.0859C10.575 15.0859 11.1417 15.0276 11.6667 14.9109L9.76671 13.0026C8.57504 12.8776 7.62504 11.9276 7.50004 10.7359L4.66671 7.89427C3.84171 8.6026 3.15004 9.48594 2.65004 10.5026Z" fill="black"/>
</svg>
</template>

<script>
  export default {
    name: 'IconEye',
  };
</script>