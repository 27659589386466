<template>
  <div class="signup-wrapper__container__contents__wrapper">
    <div class="signup-wrapper__container__contents__wrapper__header">
      회원가입
    </div>

    <div class="signup-wrapper__container__contents__wrapper__main">
      <div class="signup-wrapper__container__contents__wrapper__main__title">
        3. 영업사원
      </div>

      <div class="signup-wrapper__container__contents__wrapper__main__form">
        <LoginForm>
          <div style="display: flex; gap: 4px; align-items: flex-end; flex: 1;">
            <LoginFormTextField v-for="field in fields" :key="field.label" v-model="field.value" :label="field.label"
              :type="field.type" :placeholder="field.placeholder" :desc="field.desc" items="flex-start"
              :readonly="field.readonly" style="flex: 1;" />
            <button @click="$bvModal.show('signup-modal')">이름 검색</button>
          </div>
        </LoginForm>
      </div>
    </div>

    <SignupModal title="영업사원 선택" :buttons="{
      cancel: { text: '닫기' },
      confirm: { text: '선택' },
    }">
      <template #signup-contents>
        <div style="display: flex; flex-direction: column; height: 100%; overflow: hidden;">
          <SearchBox />
          <div style="width: 100%; min-height: 20px;"></div>
          <div style="flex: 1; overflow: auto;">
            <SignupModalTable :data="Array.from({ length: 20 }, () => ([
              { name: '사원명', value: '김영서' },
              { name: '전화번호', value: '010-****-**00' },
            ]))" />
          </div>
        </div>
      </template>
    </SignupModal>
  </div>
</template>

<script>
import LoginForm from '../../../components/landing/login/LoginForm.vue';
import LoginFormTextField from '../../../components/landing/login/LoginFormTextField.vue';
import SignupModal from '../../../components/landing/signup/SignupModal.vue';
import SignupModalTable from '../../../components/landing/signup/SignupModalTable.vue';
import SearchBox from '../../main/components/SearchBox.vue'

export default {
  data: () => ({
    fields: [
      { label: '영업사원', type: 'text', value: '', placeholder: '영업사원 이름을 입력해주세요' },
    ],
  }),

  components: {
    LoginForm,
    LoginFormTextField,
    SignupModal,
    SignupModalTable,
    SearchBox
  },

  methods: {

  },
}
</script>

<style lang="scss" scoped>
@use "../../../assets/scss/landing/abstracts/variables" as v;
@use "../../../assets/scss/service/abstracts/variables" as *;

.signup-wrapper__container__contents__wrapper__main__form :deep .login-form {
  @media (min-width: 300px) {
    --gap: 10px !important;
  }

  @media (min-width: 375px) {
    --gap: 20px !important;
  }

  @media (min-width: 1024px) {
    --gap: 40px !important;
  }
}

button {
  padding: 0;
  width: 100%;
  height: 44px;
  background-color: #6D6EFA;
  border-radius: 8px;
  border: 0;
  color: #FFFFFF;
  font-size: 14px;
  font-weight: 700;

  @media (min-width: 300px) {
    font-weight: 400;
    max-width: 83px;
    height: 41px;
  }

  @media (min-width: 375px) {
    font-weight: 400;
    max-width: 83px;
    height: 41px;
  }

  @media (min-width: 1024px) {
    font-weight: 700;
    max-width: 75px;
    height: 44px;
  }
}
</style>