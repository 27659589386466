<template>
  <div class="signup-wrapper__container__contents">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'SignupStepContents',

  components: {
    
  }
}
</script>

<style lang="scss" scoped>
.signup-wrapper__container__contents {
  @media (min-width: 300px) {
    margin: 13px 0 84px;
    flex: 1;
  }

  @media (min-width: 375px) {
    margin: 13px 0 84px;
    flex: 1;
  }

  @media (min-width: 1024px) {
    margin: 80px 0;
    flex: initial;
  }

  @media (min-width: 1440px) {
    margin: 80px 0;
    flex: initial;
  }

  ::v-deep .signup-wrapper__container__contents__wrapper {
    display: flex;
    flex-direction: column;

    @media (min-width: 300px) {
      gap: 20px;
    }

    @media (min-width: 375px) {
      gap: 40px;
    }

    @media (min-width: 1024px) {
      gap: 80px;
    }

    &__header {
      line-height: 1.4;
      font-weight: 700;
      color: #111827;

      @media (min-width: 300px) {
        font-size: 18px;
      }

      @media (min-width: 375px) {
        font-size: 20px;
      }

      @media (min-width: 1024px) {
        font-size: 40px;
      }
    }

    &__main {
      display: flex;
      flex-direction: column;
      font-weight: 700;
      color: #111827;

      &__title {
        @media (min-width: 300px) {
          padding-bottom: 20px;
          font-size: 16px;
          line-height: 1.375;
        }

        @media (min-width: 375px) {
          padding-bottom: 20px;
          font-size: 16px;
          line-height: 1.375;
        }

        @media (min-width: 1024px) {
          padding-bottom: 40px;
          font-size: 24px;
          line-height: 1.41666667;
        }

        &::after {
          display: block;
          width: 100%;
          height: 0;
          border-bottom: 1px solid #E5E7EB;
          content: '';

          @media (min-width: 300px) {
            padding-top: 12px;
          }

          @media (min-width: 375px) {
            padding-top: 12px;
          }

          @media (min-width: 1024px) {
            padding-top: 40px;
          }
        }
      }

      &__form {
        color: #6B7280;

        @media (min-width: 300px) {
          padding: 0 0;
        }

        @media (min-width: 375px) {
          padding: 0 0;
        }

        @media (min-width: 1024px) {
          padding: 0 80px;
        }
      }
    }
  }
}
</style>