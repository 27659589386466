<template>
	<div class="login-wrapper">
	  <div class="login-wrapper__bg">
		<picture>
		  <source media="(max-width: 1280px)" :srcset="require('@/assets/images/landing/bg-login-tablet.png')" />
		  <source media="(min-width: 1280px)" :srcset="require('@/assets/images/landing/bg-login.png')" />
		  <img ref="bgImg" :src="require('@/assets/images/landing/bg-login.png')" alt="Login Background" />
		</picture>
	  </div>

	  <div class="login-wrapper__form-wrapper">
		<div class="login-wrapper__form-wrapper-container">
		  <div class="login-wrapper__form-wrapper-container__header">
			<div class="login-wrapper__form-wrapper-container__header__logo">
				<router-link to="/public/main">EC-톡톡</router-link>
			</div>
  
			<div class="login-wrapper__form-wrapper-container__header__title">
			  로그인
			</div>
		  </div>
  
			<div class="login-wrapper__form-wrapper-container__contents">
			<div class="login-wrapper__form-wrapper-container__contents__form-main">
				<LoginForm>
					<LoginFormTextField v-model="form.id" label="아이디 입력" 
					placeholder="아이디를 입력해주세요" items="flex-start" required />
							<LoginFormTextField @keyup.enter="ajaxlogin" v-model="form.password" :maxlength="30"
								label="비밀번호 입력" type="password" placeholder="비밀번호를 입력해주세요" items="flex-start"
								required />
							<LoginFormSingleCheckboxField ref="chkSaveId" v-model="form.idSave" label="아이디 저장"
								justify="flex-end" items="flex-end">
								<template #label>
									<span style="display: flex; align-items: center; height: 33px;">아이디 저장</span>
								</template>
							</LoginFormSingleCheckboxField>
						</LoginForm>
					</div>

					<div class="login-wrapper__form-wrapper-container__contents__two-auth">
						<div class="login-wrapper__form-wrapper-container__contents__two-auth__header">
						2차 인증
						</div>

						<div class="login-wrapper__form-wrapper-container__contents__two-auth__verifications">
							<LoginForm row>
								<LoginFormRadioGroupField v-model="twoAuthForm.verificationType">
								<LoginFormRadio value="sms">
									<template #label>
									<span style="display: flex; align-items: center; height: 33px;">문자로 인증하기</span>
									</template>
								</LoginFormRadio>
								<LoginFormRadio value="email">
									<template #label>
									<span style="display: flex; align-items: center; height: 33px;">이메일로 인증하기</span>
									</template>
								</LoginFormRadio>
								</LoginFormRadioGroupField>
							</LoginForm>
							</div>
						</div>

					<div class="login-wrapper__form-wrapper-container__contents__buttons">
						<div class="login-wrapper__form-wrapper-container__contents__buttons__row">
							<button @click="ajaxlogin">로그인</button>
						</div>

						<div class="login-wrapper__form-wrapper-container__contents__buttons__row">
							<div>
								<div>
									<router-link to="/login/findUserId">아이디 찾기</router-link>
								</div>
								<div class="bar">|</div>
								<div>
									<router-link to="/login/findUserPwd">비밀번호 찾기</router-link>
								</div>
							</div>

							<div class="bar">|</div>

							<div>
								<router-link to="/sign/signUp">회원가입</router-link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<AlertModal2 :title="alertTitle" :desc="alertDesc" />
	</div>
</template>

<script>
import AlertModal2 from '../../../components/service/modal/AlertModal2.vue';
import LoginForm from '../../../components/landing/login/LoginForm.vue';
import LoginFormTextField from '../../../components/landing/login/LoginFormTextField.vue';
import LoginFormSingleCheckboxField from '../../../components/landing/login/LoginFormSingleCheckboxField.vue';
import LoginFormRadioGroupField from '../../../components/landing/login/LoginFormRadioGroupField.vue';
import LoginFormRadio from '../../../components/landing/login/LoginFormRadio.vue';
import loginApi from '@/modules/login/service/api';

export default {
	data: () => ({
		form: {
			id: '',
			password: '',
			idSave: false
		},
		twoAuthForm: {
			verificationType: 'sms'
		},
		alertTitle: '',
		alertDesc: '',
		isPasswordFocus: false,
		isPasswordVisible: false,
		errors: [],
		userId: '',
		loginPwd: null,
		status: '',
		findUserIdUrl: '/login/findUserId',
		findUserPwdUrl: '/login/findUserPwd',
		popReset: 0,
		rowData: {},
		email: "testadmin",
		authType: 'sms'
	}),

	components: {
		LoginForm,
		LoginFormTextField,
		LoginFormSingleCheckboxField,
		LoginFormRadioGroupField,
		LoginFormRadio,
		AlertModal2
	},
	created() {
		// 로그인 페이지 진입시
		this.$store.commit("login/isLogin", false);
		this.$store.commit("login/isErrorPage", false);
	},
	mounted() {
		window.addEventListener("resize", this.resizeBgImage);

		const bgImg = this.$refs.bgImg;

		// 이미지 로드 이벤트 처리
		bgImg.addEventListener("load", this.resizeBgImage);

		// 초기 로드 시 크기 설정
		bgImg.style.width = '100%';
		bgImg.style.height = 'auto';

		// 이미지가 이미 캐시되어 로드된 상태일 경우 처리
		if (bgImg.complete) {
		this.resizeBgImage();
		}
	},

	beforeDestroy() {
		window.removeEventListener("resize", this.resizeBgImage);

		// 이미지 로드 이벤트 제거
		const bgImg = this.$refs.bgImg;
			if (bgImg) {
			bgImg.removeEventListener("load", this.resizeBgImage);
			}
	},
	
	methods: {
		resizeBgImage() {
			const bgImg = this.$refs.bgImg
			const bgImgParent = bgImg.closest('div')

			if (bgImgParent.offsetWidth >= bgImg.clientWidth) {
				bgImg.style.width = '100%'
				bgImg.style.height = 'auto'
			}
			if (bgImgParent.offsetHeight >= bgImg.clientHeight) {
				bgImg.style.width = 'auto'
				bgImg.style.height = '100%'
			}
		},
		chgChkUserId() {
			if (this.$refs.chkSaveId.checked == true) {
				localStorage.wtmpltUserId = this.form.id;
			} else {
				delete localStorage.wtmpltUserId;
			}
		},
		toRegister() {
			this.$router.push({ name: 'register' });
		},
		formCheck: function () {
			this.errors = [];

			if (!this.form.id) this.errors.push('아이디를 입력해 주세요.');
			if (!this.form.password) this.errors.push('비밀번호를 입력해 주세요.');

			return this.errors.length == 0;
		},
		fnSendMail: function () {
			var vm = this;
			var params = {
				email: this.email,
				location: "/login"
			};

			return new Promise(function (resolve, reject) {
				loginApi.sendCertifyMail(params).then(response => {
					var result = response.data;
					if (!result.success) {
						alert(result.message);
						reject(result.message); // 실패한 경우에 reject 호출
					} else {
						if (vm.authType == 'sms') {
							alert("문자 인증 성공하였습니다.");
						} else {
							alert("이메일 인증 성공하였습니다.");
						}
						resolve(); // 성공한 경우에 resolve 호출
					}
				}).catch(error => {
					reject(error); // 에러가 발생한 경우에 reject 호출
				});
			});
		},

		fnSetPhoneCertify() {
			var vm = this;
			if (!vm.formCheck()) return false;
			var params = {
				"userId": this.form.id,
				"userPwd": this.form.password
			};
			if (vm.authType == 'sms') {

				loginApi.setPhoneCertify(params).then(function (response) {
					var result = response.data;

					if (result.success) {
						vm.rowData = {
							...result.data,
							authType: vm.authType,
							email: vm.form.id
						}

						// 인증번호 요청 팝업
						vm.popReset++;
						jQuery("#smsConfirm").modal("show");

					} else {
						var msg = null;
						switch (result.code) {
							case 'CE_ID_PWD': // ID/PWD 불일치
								msg = "로그인 실패하였습니다. (5회 실패 시 계정 잠김)";
								break;
							case 'SS_NOT_AUTH': // 미인증 계정
								msg = 'Email 인증 후 이용할 수 있습니다.';
								break;
							case 'SS_NOT_USE': // 휴면 계정
								msg = '최종 접속일 기준 30일이 초과되어 휴면 처리 된 계정입니다.';
								break;
							case 'SS_LOCK': // 잠김 계정
								msg = '관리자 승인 후 이용할 수 있습니다.';
								break;
							case 'SS_DEL_USE': // 삭제 계정
								msg = '삭제 된 계정입니다.';
								break;
							case 'CE_TO_LOCK': // ID/PWD 불일치 횟수초과로 계정 잠김
								msg = '5회 이상 로그인 실패하여 해당 아이디에 대한 계정이 잠금처리되었습니다.\n관리자에게 문의하세요.';
								break;
							case 'SS_INVLD_ROLE': // 권한이 없는 사용자ID
								msg = 'Back Office 권한이 없는 사용자ID입니다.';
								break;
							case 'SE_INTERNAL': // 최근 접속일시 업데이트 실패
								msg = '데이터베이스와 통신에 실패하였습니다.';
								break;
							case 'CE_WRONG_AUTHNUM': // 인증번호요청
								msg = '인증번호가 잘못 되었습니다.';
								this.$bvModal.show('alert-modal2');
								break;
							default:
								msg = result.message;

								if (msg != null) {
									this.$bvModal.show('alert-modal2');
								}
						}
					}
				});
			} else {
				loginApi.setMailCertify(params).then(function (response) {
					var result = response.data;

					if (result.success) {
						vm.rowData = {
							...result.data,
							authType: vm.authType,
							email: vm.form.id
						}

						// 인증번호 요청 팝업
						vm.popReset++;
						jQuery("#smsConfirm").modal("show");

					} else {
						var msg = null;
						switch (result.code) {
							case 'CE_ID_PWD': // ID/PWD 불일치
								msg = "로그인 실패하였습니다. (5회 실패 시 계정 잠김)";
								this.$bvModal.show('alert-modal');
								break;
							case 'SS_NOT_AUTH': // 미인증 계정
								msg = 'Email 인증 후 이용할 수 있습니다.';
								break;
							case 'SS_NOT_USE': // 휴면 계정
								msg = '최종 접속일 기준 30일이 초과되어 휴면 처리 된 계정입니다.';
								break;
							case 'SS_LOCK': // 잠김 계정
								msg = '관리자 승인 후 이용할 수 있습니다.';
								break;
							case 'SS_DEL_USE': // 삭제 계정
								msg = '삭제 된 계정입니다.';
								break;
							case 'CE_TO_LOCK': // ID/PWD 불일치 횟수초과로 계정 잠김
								msg = '5회 이상 로그인 실패하여 해당 아이디에 대한 계정이 잠금처리되었습니다.\n관리자에게 문의하세요.';
								break;
							case 'SS_INVLD_ROLE': // 권한이 없는 사용자ID
								msg = 'Back Office 권한이 없는 사용자ID입니다.';
								break;
							case 'SE_INTERNAL': // 최근 접속일시 업데이트 실패
								msg = '데이터베이스와 통신에 실패하였습니다.';
								break;
							case 'CE_WRONG_AUTHNUM': // 인증번호요청
								msg = '인증번호가 잘못 되었습니다.';
								this.$bvModal.show('alert-modal');
								break;
							default:
								msg = result.message;
						}
						if (msg != null) {
							this.$bvModal.show('alert-modal');
						}

					}
				});
			}
		},
		ajaxlogin: function () {
			var vm = this;

			// this.$refs.chkSaveId.focus();
			vm.errmsg = null;

			if (!this.formCheck()) return false;
			var params = {
				"userId": vm.form.id,
				"userPwd": vm.form.password,
			};
			// var params = vm.rowData;  --  추후 변경 임시 주석함 로그인시 호출 함수도fnSetPhoneCertify  로 변경해야함

			loginApi.login(params).then(function (response) {
				var rsp = response.data;
				console.log('rsp:', rsp);
				if (rsp.success) {
					vm.chgChkUserId();
					vm.$store.commit("login/isLogin", true);
					vm.$router.push({ path: rsp.data });
				} else {
					vm.$store.commit("login/isLogin", false);
					var msg = null,
						next = null;
					switch (rsp.code) {
						case 'CE_ID_PWD': // ID/PWD 불일치
							vm.errors.push('로그인 실패하였습니다. (5회 실패 시 계정 잠김)');
							msg = '로그인 실패하였습니다.';
							break;
						case 'SS_NOT_AUTH': // 미인증 계정
							msg = 'Email 인증 후 이용할 수 있습니다.';
							break;
						case 'SS_NOT_USE': // 중지 계정
							msg = '관리자 승인 후 이용할 수 있습니다.';
							break;
						case 'SS_LOCK': // 잠김 계정
							msg = '관리자 승인 후 이용할 수 있습니다.';
							break;
						case 'SS_DEL_USE': // 삭제 계정
							msg = '삭제 된 계정입니다.';
							break;
						case 'SS_PWD_EXPIRE': // 비밀번호 만료
							msg = '비밀번호를 변경하신지 90일이 지났습니다.\n비밀번호 변경 화면으로 이동합니다.';
							next = '/login?expire';
							break;
						case 'SS_INIT_PWD': // 최초 비밀번호 변경 필요
							msg = '새로운 비밀번호로 변경이 필요합니다.\n비밀번호 변경 화면으로 이동합니다.';
							next = function () {
								// vue-router를 사용하여 데이터 전달
								this.$router.push({ name: 'changepwd', params: { userId: vm.form.id } });
							};
							break;
						case 'CE_TO_LOCK': // ID/PWD 불일치 횟수초과로 계정 잠김
							msg = '5회 이상 로그인 실패하여 해당 아이디에 대한 계정이 잠금처리되었습니다.\n관리자에게 문의하세요.';
							break;
						case 'SE_INTERNAL': // 최근 접속일시 업데이트 실패
							msg = '데이터베이스와 통신에 실패하였습니다.';
							break;
						case 'SS_NOT_PROJECT': // 참여 프로젝트 없음
							msg = '참여되어 있는 프로젝트가 존재하지 않습니다.';
							break;
						case 'SS_NOT_USE_CORP': // 사용정지된 고객사
							msg = '사용정지 된 고객사의 사용자입니다.';
							break;
						case 'SS_NOT_EXIST_CORP': // 삭제된 고객사
							msg = '삭제 된 고객사의 사용자입니다.';
							break;
						case 'CE_WRONG_AUTHNUM': // 인증번호요청
							msg = '인증번호가 잘못 되었습니다.';
							vm.showAlert(msg);
							break;
						default:
							vm.errmsg = '인증에 실패했습니다.';
					}

					if (msg != null) {
						vm.showAlert(msg);
						if (next != null) {
							next();
						}
					}
				}
			});
		},
		showAlert(msg) {
			this.alertTitle = '로그인'
			this.alertDesc = msg
			this.$bvModal.show('alert-modal');

		},
		signUp: function () {
			this.$router.push({ name: "signUp" });
		}
	},
	
}
</script>

<style lang="scss" scoped>
@use "../../../assets/scss/landing/abstracts/variables" as v;
@import "../../../assets/scss/landing/login";

.login-wrapper__form-wrapper-container__header__logo a {
  all: unset;
  display: inherit;
  cursor: pointer;
}

.login-wrapper {
  display: flex;
  justify-content: flex-end;
  width: 100vw;
  height: 100vh;

  &__bg {
    position: relative;
    display: none;
    overflow: hidden;
    background: linear-gradient(301.87deg, #4042FF 33.75%, #A7C0FF 91.23%);
    background-size: auto 100%;
    background-repeat: no-repeat;

    img {
      position: absolute;
      top: 0;
      left: 0;
    }

    @media (min-width: 1024px) {
      display: block;
      flex: 1;
    }
  }

  &__form-wrapper {
    display: flex;
    justify-content: center;
    background-color: #ffffff;
    box-shadow: 0px 4px 32px 0px #6D6EFA3D;

	@media (min-width: 300px) {
      width: 100%;
    }

    @media (min-width: 375px) {
      width: 100%;
    }

    @media (min-width: 1024px) {
      width: fit-content;
    }

    @media (min-width: 1280px) {
      width: fit-content;
    }

    &-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 80px;
      box-sizing: content-box;

	  @media (min-width: 300px) {
        gap: 32px;
        padding: 0 20px;
        width: 382px;
      }

      @media (min-width: 375px) {
        gap: 32px;
        padding: 0 20px;
        width: 382px;
      }

      @media (min-width: 1024px) {
        gap: 60px;
        padding: 0 60px;
        width: 382px;
      }

      @media (min-width: 1280px) {
        gap: 80px;
        padding: 0 170px;
        width: 503px;
      }

      &__header {
        display: flex;
        flex-direction: column;
        gap: 40px;

        &__logo {
          width: fit-content;
          padding: 8px 12px;
          background-color: #D2D2FD;
          font-size: 14px;
          font-weight: 700;
          line-height: 1.429;
          letter-spacing: -0.15px;
          color: #414296;
        }

        &__title {
          font-size: 64px;
          font-weight: 700;
          line-height: 1.4;
          color: #111827;

		  @media (min-width: 300px) {
            font-size: 28px;
          }

          @media (min-width: 375px) {
            font-size: 28px;
          }

          @media (min-width: 1024px) {
            font-size: 40px;
          }

          @media (min-width: 1280px) {
            font-size: 64px;
          }
        }
      }

      &__contents {
        display: flex;
        flex-direction: column;
        gap: 60px;
        color: #6B7280;
		
		@media (min-width: 300px) {
          gap: 40px;
        }

        @media (min-width: 375px) {
          gap: 40px;
        }

        @media (min-width: 768px) {
          gap: 60px;
        }

        &__two-auth {
          &__header {
            font-size: 24px;
            font-weight: 700;
            line-height: 1.4;
            color: #111827;
          }

          &__verifications {
            margin-top: 20px;
            padding-top: 20px;
            border-top: 1px solid #E5E7EB;

			@media (min-width: 300px) {
              margin-top: 16px;
              padding-top: 16px;
            }

            @media (min-width: 375px) {
              margin-top: 16px;
              padding-top: 16px;
            }

            @media (min-width: 768px) {
              margin-top: 20px;
              padding-top: 20px;
            }
          }
        }

        &__buttons {
          display: flex;
          flex-direction: column;
          font-weight: 500;
          color: #374151;

		  @media (min-width: 300px) {
            gap: 16px;
            margin-top: 0;
            flex: 1;
          }

          @media (min-width: 375px) {
            gap: 16px;
            margin-top: 0;
            flex: 1;
          }

          @media (min-width: 768px) {
            gap: 20px;
            margin-top: 0;
            flex: auto;
          }

          &__row {
            display: flex;
            justify-content: space-between;
            align-items: center;

			@media (min-width: 300px) {
              justify-content: center;
              gap: 12px;

              >.bar {
                display: block;
              }
            }

            @media (min-width: 375px) {
              justify-content: center;
              gap: 12px;

              >.bar {
                display: block;
              }
            }

            @media (min-width: 768px) {
              justify-content: space-between;
              gap: 0;

              >.bar {
                display: none;
              }
            }

            >div {
              display: flex;
              align-items: center;
              gap: 12px;

              a {
                color: #374151;

                &:hover {
                  color: #6D6EFA;
                }
              }
            }
          }

          button {
            flex: 1;
            padding: 0;
            width: 100%;
            height: 52px;
            background-color: #6D6EFA;
            border-radius: 12px;
            border: 0;
            color: #FFFFFF;
            font-weight: 700;
          }

          .bar {
            font-weight: 300;
            color: #D2D2FD;
          }
        }
      }
    }
  }
}
</style>