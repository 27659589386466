import signUp from '../views/SignupTerms.vue'
import mailSend from '../views/signUpMailSend'
import checkPlusSuccess from '../views/bm-checkPlus-success'
import signUpStep from '../views/SignupStep.vue'
import signUpStep1 from '../views/SignupStep1.vue'
import signUpStep2 from '../views/SignupStep2.vue'
import signUpStep3 from '../views/SignupStep3.vue'

//import checkPlusFail from '../views/bm-checkPlus-fail'



export default [
    {
        path: '/sign/signUp',
        name: 'signUp',
        component: signUp,
        meta: { public: true }
    },
    {
        path: '/sign/mailSend',
        name: 'mailSend',
        component: mailSend,
        meta: { public: true }
    },
    {
        path: '/not/checkPlusSuccess',
        name: 'checkPlusSuccess',
        component: checkPlusSuccess,
        meta: { public: true }
    },
    {
      path: "/sign/signUpStep",
      component: signUpStep,
      meta: { public: true },
      children: [
          {
            path: "",
            redirect: "1", // 기본 자식 라우트 설정
          },
          {
            path: "1",
            component: signUpStep1,
          },
          {
            path: "2",
            component: signUpStep2,
          },
          {
            path: "3",
            component: signUpStep3,
          },
        ],
    },
    
    // {
    //     path: '/checkPlusFail',
    //     name: 'checkPlusFail',
    //     component: checkPlusFail,
    //     meta: { public: true }
    // },

]