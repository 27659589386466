<template>
  <b-modal scrollable id="add-content-modal" title="내용 입력" hide-header-close centered>
    <template #modal-title>
      <h5 class="modal-title">내용 입력</h5>
      <p class="title-desc">* 광고성메시지 수신거부번호는 내용 하단에 포함됩니다. 또한 광고 표기는 제목 또는 내용에 포함되어 있어야 합니다.</p>
    </template>

    <div class="form-group" v-if="this.sendData.senderType == 'LMS' || this.sendData.senderType == 'MMS'">
      <label for="title" class="f-body5 c-gray500">제목</label>
      <b-input type="text" id="title" placeholder="제목 내용" maxlength="30" v-model="smsTitle"></b-input>
    </div>

    <label for="content" class="f-body5 c-gray500 mb-1 pb-2">내용</label>
    <b-form-textarea
      id="content"
      rows="6"
      max-rows="6"
      v-model="smsContent"
      maxlength="2000"
    ></b-form-textarea>
    <p class="textarea-count mt-2 mb-0">(63 / 90)</p>

    <div class="block">
      <label class="f-body5 c-gray500">광고성메시지<br/>수신거부번호</label>
      <b-input-group class="w-100">
        <b-input-group-append>
          <b-button variant="dark" @click="openBlockList">선택</b-button>
        </b-input-group-append>
        <b-form-input v-model="rcvblcNumber" maxlength="20" placeholder="ex) 수신거부번호: 123-1234-1234"></b-form-input>
      </b-input-group>
    </div>

    <div v-if="showBlockList" class="block-list">
      <p class="m-0 mb-1 pb-3 f-title3 c-gray900">수신거부번호 관리</p>
      <div class="input-group">
        <b-input value="무료수신거부"></b-input>
        <b-input placeholder="060-000-0000"></b-input>
        <b-button variant="dark">등록</b-button>
      </div>
      <ul>
        <li>
          <span class="f-body5 c-gray900 text-truncate">무료수신거부 060-0000-0000</span>
          <b-button variant="outline-primary" size="sm" @click="hideBlockList">선택</b-button>
          <button type="button" class="btn btn-icon p-0">
            <IconClose />
          </button>
        </li>
        <li>
          <span class="f-body5 c-gray900 text-truncate">무료수신거부 060-0000-0000</span>
          <b-button variant="outline-primary" size="sm" @click="hideBlockList">선택</b-button>
          <button type="button" class="btn btn-icon p-0">
            <IconClose />
          </button>
        </li>
      </ul>
    </div>

    <template #modal-footer>
      <b-button variant="primary" @click="fnCallbackInputData">입력</b-button>
      <b-button variant="outline-primary" @click="closeModal">닫기</b-button>
    </template>
  </b-modal>
</template>

<script>
import IconClose from '@/components/service/icons/IconClose.vue';
import confirm from "@/modules/commonUtil/service/confirm.js"

export default {
  components: { IconClose, },
  name: "AddContentModal",
  props: {
    isSpecialBusi: {
      type: Boolean,
      require: false,
      default: false,
    },
    sendData : {
      type: Object,
      require: true,
    },
    componentsTitle: {
      type: String,
      require: false,
      default: function() {
        return 'SMS 발송 내용';
      }
    },
  },
  data() {
    return {
      showBlockList: false,
      rcvblcNumOpen: false,
      smsTitle: 'sss',
      smsContent : '',
      rcvblcNumber: '',
      msgCurrByte: 0,
      msgLimitByte: 0,
      titleLimitByte: 40,
      contentAreaPlaceholder: '변수로 설정하고자 하는 내용을 #{ }표시로 작성해 주십시오.\n:예) 이름과 출금일을 변수 설정\n:예) #{name}님 #{yyyymmdd} 출금 예정입니다.'
    }
  },
  watch: {
    sendData: {
      deep: true, // 객체 내부의 값도 감지
      handler() {
        this.fnSetInitInfo();
      },
    }
  },
  mounted() {
    this.fnSetInitInfo(); // 컴포넌트가 처음 로드될 때 실행
  },
  methods: {
    closeModal() {
      this.$bvModal.hide('add-content-modal');
    },
    openBlockList() {
      this.showBlockList = true;
    },
    hideBlockList() {
      this.showBlockList = false;
    },
    fnSetCurrByte(){
      let body = this.$gfnCommonUtils.defaultIfEmpty(this.smsContent, '');
      let rcvblcNum = this.$gfnCommonUtils.defaultIfEmpty(this.rcvblcNumber, '');
      let totalMsg = body ;
      if(this.sendData.msgKind == 'A'){
        totalMsg += rcvblcNum + (this.sendData.senderType == 'SMS' ? '(광고)' : '');
      }
      this.msgCurrByte = this.getByte(totalMsg);
    },
    fnGetLimitByte(){
      if(this.sendData.senderType == 'SMS'){
        this.msgLimitByte = 90;
      } else if(this.sendData.senderType == 'LMS'){
        this.msgLimitByte = 1000;
      } else if(this.sendData.senderType == 'MMS'){
        this.msgLimitByte = 2000;
      }
    },
    fnCallbackRcvblcNum(rcvblcNum){
      this.$set(this.$data, 'rcvblcNumber', rcvblcNum)
      //this.rcvblcNumber = rcvblcNum;
      this.fnSetCurrByte();
    },
    //입력정보 callback
    fnCallbackInputData(){
      if(this.sendData.senderType == 'MMS'){
        if(this.smsTitle == false){
          confirm.fnAlert(this.componentsTitle, '제목을 입력해주세요.');
          return false;
        }
        let title = this.$gfnCommonUtils.defaultIfEmpty(this.smsTitle, '');
        let totalTitle =  title;
        let titleCurrByte = this.getByte(totalTitle);
        if(this.titleLimitByte < titleCurrByte){
          const alertMsg = '제목이 '+this.titleLimitByte+'byte를 넘지 않아야됩니다.\n(현재 : '+titleCurrByte+'byte)';
          confirm.fnAlert(this.componentsTitle, alertMsg);
          return false;
        }
      }
      if(!this.smsContent){
        alert("내용을 입력해주세요.");
        return false;
      }
      if(this.sendData.msgKind == 'A' && !this.rcvblcNumber){
        alert("광고성메시지 수신거부번호를 입력해주세요.");
        return false;
      }

      if(this.sendData.msgKind == 'A') {
        if(this.sendData.senderType != "SMS") {
          var chkContent = this.smsContent + this.smsTitle;
          if(chkContent.indexOf("광고")== -1) {
            confirm.fnAlert(this.componentsTitle, '광고성메시지는 제목 또는 내용에 광고문구를 표기해야 합니다.');
             return false;
          }
        }
      }

      if(this.msgLimitByte < this.msgCurrByte){
        const alertMsg = '내용 + 광고성메시지 수신거부번호가 '+this.msgLimitByte+'byte 를 넘지 않아야됩니다.\n(현재 : '+this.msgCurrByte+'byte)';
        confirm.fnAlert(this.componentsTitle, alertMsg);
        return false;
      }
      this.$parent.fnSetSmsInfo(this.$data);
      this.closeModal();
    },
        //get 문자열 byte
        getByte(str) {
      return str
        .split('')
        .map(s => s.charCodeAt(0))
        .reduce((prev, c) => (prev + ((c === 10) ? 2 : ((c >> 7) ? 2 : 1))), 0);
    },
    //초기 정보 Set
    fnSetInitInfo(){
      var senderType = this.sendData.senderType;
      var msgKind = this.sendData.msgKind;
      if(senderType == "SMS") {
        this.smsContent = this.$gfnCommonUtils.defaultIfEmpty(this.sendData.smsContent.replace("(광고)" , ""), '');
        this.smsTitle = this.$gfnCommonUtils.defaultIfEmpty(this.sendData.smsTitle, '');
      } else {
        if(msgKind == "A") {
          this.smsContent = this.$gfnCommonUtils.defaultIfEmpty(this.sendData.smsContent, '');
          if(this.sendData.smsContent.indexOf("광고") != -1 || this.sendData.smsTitle.indexOf("광고") != -1) {
            this.smsTitle = this.$gfnCommonUtils.defaultIfEmpty(this.sendData.smsTitle.replace("(광고)" , ""), '');
          } else {
            this.smsTitle = this.$gfnCommonUtils.defaultIfEmpty(this.sendData.smsTitle, '(광고)');
          }
        } else {
          this.smsContent = this.$gfnCommonUtils.defaultIfEmpty(this.sendData.smsContent, '');
          this.smsTitle = this.$gfnCommonUtils.defaultIfEmpty(this.sendData.smsTitle, '');
        }
      }
      this.rcvblcNumber = this.$gfnCommonUtils.defaultIfEmpty(this.sendData.rcvblcNumber, '');
      this.msgCurrByte = 0;
      this.msgLimitByte = 0;
    },
    //팝업 닫기
    fnClose(){
      this.$emit('update:smsContsOpen', false)
    },
    //단축 URL 선택
    btnSelect(shortendUrl){
      if(this.smsContent.length > 0)
        this.smsContent += '\n'

      this.smsContent += shortendUrl
    },
  }
};
</script>

<style scoped lang="scss">
.block {
  display: flex;
  margin-top: 20px;
  label {
    min-width: 75px;
    margin-right: 12px;
  }
}
.input-group {
  .btn {
    width: 100px;
    margin-right: 20px;
  }
}
.block-list {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid var(--border-color);
  .input-group {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    .form-control {
      margin-left: 20px;
      &:first-child {
        margin-left: 0;
      }
    }
    .btn {
      margin-left: 20px;
    }
  }
  ul {
    margin: 0;
    padding: 0;
    border-top: 1px solid var(--gray500);
    border-bottom: 1px solid var(--gray500);
    li {
      display: flex;
      align-items: center;
      padding: 12px 0;
      & + li {
        border-top: 1px solid var(--border-color);
      }
    }
    .btn-outline-primary {
      min-width: 73px;
      margin-left: auto;
    }
    .btn-icon {
      margin-left: 20px;
    }
  }
}

</style>
