<template>
  <div class="login-wrapper">
    <div class="login-wrapper__bg">
      <picture>
        <source media="(max-width: 1280px)" :srcset="require('@/assets/images/landing/bg-login-tablet.png')" />
        <source media="(min-width: 1280px)" :srcset="require('@/assets/images/landing/bg-login.png')" />
        <img ref="bgImg" :src="require('@/assets/images/landing/bg-login.png')" alt="Login Background" />
      </picture>
    </div>

    <div class="login-wrapper__form-wrapper">
      <div class="login-wrapper__form-wrapper__btn-back">
        <button @click="$router.back()">뒤로가기</button>
      </div>

      <div class="login-wrapper__form-wrapper-container">
        <div class="login-wrapper__form-wrapper-container__header">
          <div class="login-wrapper__form-wrapper-container__header__btn-back">
            <button @click="$router.back()">뒤로가기</button>
          </div>

          <div class="login-wrapper__form-wrapper-container__header__title">
            회원가입
          </div>
        </div>

        <div class="login-wrapper__form-wrapper-container__contents">
          <div class="login-wrapper__form-wrapper-container__contents__form-main">
            <LoginForm>
              <LoginFormTextField v-model="form.email" label="이메일 입력" placeholder="이메일 주소를 입력해주세요" items="flex-start"
                desc="이메일 인증 유효 시간은 24시간입니다." required />

              <div class="terms-all-agree">
                <LoginFormSingleCheckboxField items="flex-start">
                  <template #label>
                    <strong>전체 약관에 동의합니다</strong>
                  </template>
                </LoginFormSingleCheckboxField>
              </div>

              <div class="terms-rows">
                <div class="terms-row">
                  <LoginFormSingleCheckboxField>
                    <template #label>
                      <div>
                        <strong style="color: #6D6EFA">[필수]</strong>
                        <span>&nbsp;서비스 이용약관 동의에 동의합니다.</span>
                      </div>
                    </template>
                  </LoginFormSingleCheckboxField>

                  <button @click="$bvModal.show('signup-modal')">
                    <span>약관보기</span>
                    <IconArrowRight2 />
                  </button>
                </div>

                <div class="terms-row">
                  <LoginFormSingleCheckboxField>
                    <template #label>
                      <div>
                        <strong style="color: #6D6EFA">[필수]</strong>
                        <span>&nbsp;개인정보 수집, 이용에 동의합니다.</span>
                      </div>
                    </template>
                  </LoginFormSingleCheckboxField>

                  <button @click="$bvModal.show('signup-modal')">
                    <span>약관보기</span>
                    <IconArrowRight2 />
                  </button>
                </div>

                <div class="terms-row">
                  <LoginFormSingleCheckboxField>
                    <template #label>
                      <div>
                        <strong style="color: #6D6EFA">[선택]</strong>
                        <span>&nbsp;홍보성 정보 수신에 동의합니다.</span>
                      </div>
                    </template>
                  </LoginFormSingleCheckboxField>

                  <button @click="$bvModal.show('signup-modal')">
                    <span>약관보기</span>
                    <IconArrowRight2 />
                  </button>
                </div>
              </div>
            </LoginForm>
          </div>

          <div class="login-wrapper__form-wrapper-container__contents__buttons">
            <div class="login-wrapper__form-wrapper-container__contents__buttons__row">
              <button @click="showAlertModal">인증메일 발송</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <AlertModal2 title="인증메일 발송완료" desc="입력하신 이메일 주소로 인증 메일을 발송했습니다." />
    <!-- <AlertModal2 id="already-joined" title="가입 회원 안내 모달" desc="이미 가입한 회원입니다." /> -->
    <SignupModal title="약관 상세">
      <template #signup-name>
        <strong style="color: #6D6EFA">[필수]</strong>
        <span>&nbsp;서비스 이용약관 동의에 동의합니다.</span>
      </template>

      <template #signup-contents>제 1 장 총칙

        제 1 조 (목적)
        본 약관은 통계청이 운영하는 나라통계시스템 운영홈페이지(이하 "당 사이트")에서 제공하는 모든 서비스(이하 "서비스")의 이용조건 및 절차, 이용자와 당 사이트의 권리, 의무, 책임사항과 기타 필요한
        사항을 규정함을 목적으로 합니다.

        제 2 조 (약관의 효력과 변경)
        ① 당 사이트는 이용자가 본 약관 내용에 동의하는 것을 조건으로 이용자에게 서비스를 제공하며, 당 사이트의 서비스 제공 행위 및 이용자의 서비스 사용 행위에는 본 약관을 우선적으로 적용하겠습니다.
        ② 당 사이트는 본 약관을 사전 고지 없이 변경할 수 있으며, 변경된 약관은 당 사이트 내에 공지함으로써 이용자가 직접 확인하도록 할 것입니다. 이용자가 변경된 약관에 동의하지 아니하는 경우 본인의
        회원등록을 취소(회원탈퇴)할 수 있으며, 계속 사용할 경우에는 약관 변경에 대한 암묵적 동의로 간주됩니다. 변경된 약관은 공지와 동시에 그 효력을 발휘합니다.

        제 3 조 (약관 외 준칙)
        본 약관에 명시되지 않은 사항은 전기통신기본법, 전기통신사업법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률 및 기타 관련 법령의 규정에 의합니다.
      </template>
    </SignupModal>
  </div>
</template>

<script>
import AlertModal2 from '../../../components/service/modal/AlertModal2.vue';
import LoginForm from '../../../components/landing/login/LoginForm.vue';
import LoginFormTextField from '../../../components/landing/login/LoginFormTextField.vue';
import LoginFormSingleCheckboxField from '../../../components/landing/login/LoginFormSingleCheckboxField.vue';
import IconArrowRight2 from '../../../components/service/icons/IconArrowRight2.vue'
import SignupModal from '../../../components/landing/signup/SignupModal.vue';

export default {
  data: () => ({
    form: {
      email: '',
    },
  }),

  components: {
    IconArrowRight2,
    LoginForm,
    LoginFormTextField,
    LoginFormSingleCheckboxField,
    AlertModal2,
    SignupModal
  },

  methods: {
    showAlertModal() {
      this.$bvModal.show('alert-modal2');
    },

    resizeBgImage() {
      const bgImg = this.$refs.bgImg
      const bgImgParent = bgImg.closest('div')

      if (bgImgParent.offsetWidth >= bgImg.clientWidth) {
        bgImg.style.width = '100%'
        bgImg.style.height = 'auto'
      }
      if (bgImgParent.offsetHeight >= bgImg.clientHeight) {
        bgImg.style.width = 'auto'
        bgImg.style.height = '100%'
      }
    }
  },

  mounted() {
    window.addEventListener("resize", this.resizeBgImage);

    const bgImg = this.$refs.bgImg;

    // 이미지 로드 이벤트 처리
    bgImg.addEventListener("load", this.resizeBgImage);

    // 초기 로드 시 크기 설정
    bgImg.style.width = '100%';
    bgImg.style.height = 'auto';

    // 이미지가 이미 캐시되어 로드된 상태일 경우 처리
    if (bgImg.complete) {
      this.resizeBgImage();
    }
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.resizeBgImage);

    // 이미지 로드 이벤트 제거
    const bgImg = this.$refs.bgImg;
    if (bgImg) {
      bgImg.removeEventListener("load", this.resizeBgImage);
    }
  }
}
</script>

<style lang="scss" scoped>
@use "../../../assets/scss/landing/abstracts/variables" as v;
@use "../../../assets/scss/service/abstracts/variables" as *;

.login-wrapper {
  display: flex;
  justify-content: flex-end;
  position: relative;
  width: 100vw;
  height: 100vh;

  &__bg {
    position: relative;
    display: none;
    overflow: hidden;
    background: linear-gradient(301.87deg, #4042FF 33.75%, #A7C0FF 91.23%);
    background-size: auto 100%;
    background-repeat: no-repeat;

    img {
      position: absolute;
      top: 0;
      left: 0;
    }

    @media (min-width: 1024px) {
      display: block;
      flex: 1;
    }
  }

  &__form-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    background-color: #ffffff;
    box-shadow: 0px 4px 32px 0px #6D6EFA3D;

    @media (min-width: 300px) {
      width: 100%;
    }

    @media (min-width: 375px) {
      width: 100%;
    }

    @media (min-width: 1024px) {
      width: fit-content;
    }

    @media (min-width: 1280px) {
      width: fit-content;
    }

    &__btn-back {
      position: absolute;
      bottom: 20px;
      left: -116px;
      width: 96px;
      height: 36px;
      display: flex;
      justify-content: flex-end;
      background: url($icon + "icon-caret-left.svg") #ffffff;
      background-size: 16px auto;
      background-repeat: no-repeat;
      background-position: 12px center;
      border-radius: 36px;

      button {
        @media (min-width: 300px) {
          display: none;
        }

        @media (min-width: 375px) {
          display: none;
        }

        @media (min-width: 1024px) {
          display: block;
        }

        display: flex;
        align-items: center;
        padding: 0 12px;
        width: fit-content;
        height: 100%;
        background-color: transparent;
        border: 0;
        color: #111827;
        font-size: 14px;
        font-weight: 700;
      }
    }

    &-container {
      display: flex;
      flex-direction: column;
      gap: 60px;
      box-sizing: content-box;

      @media (min-width: 300px) {
        padding: 40px 20px;
        width: 382px;
      }

      @media (min-width: 375px) {
        padding: 40px 20px;
        width: 382px;
      }

      @media (min-width: 1024px) {
        justify-content: center;
        padding: 0 60px;
        width: 382px;
      }

      @media (min-width: 1280px) {
        gap: 80px;
        padding: 0 170px;
        width: 503px;
      }

      &__header {
        display: flex;
        flex-direction: column;
        gap: 40px;

        &__btn-back {
          @media (min-width: 300px) {
            display: block;
          }

          @media (min-width: 375px) {
            display: block;
          }

          @media (min-width: 1024px) {
            display: none;
          }

          padding-left: 16px;
          background: url($icon + "icon-caret-left.svg");
          background-size: 12px auto;
          background-repeat: no-repeat;
          background-position: 0% center;

          button {
            display: flex;
            align-items: center;
            padding: 0;
            height: 19px;
            background-color: transparent;
            border: 0;
            color: #111827;
            font-size: 12px;
            font-weight: 700;
          }
        }

        &__title {
          font-size: 40px;
          font-weight: 700;
          line-height: 1.4;
          color: #111827;

          @media (min-width: 300px) {
            font-size: 20px;
          }

          @media (min-width: 375px) {
            font-size: 20px;
          }

          @media (min-width: 1024px) {
            font-size: 28px;
          }

          @media (min-width: 1280px) {
            font-size: 40px;
          }
        }
      }

      &__contents {
        display: flex;
        flex-direction: column;
        gap: 40px;
        color: #6B7280;

        @media (min-width: 300px) {
          gap: 20px;
          flex: 1;
        }

        @media (min-width: 375px) {
          gap: 20px;
          flex: 1;
        }

        @media (min-width: 768px) {
          gap: 40px;
          flex: none;
        }

        &__info {
          background-color: #F9FAFB;
          font-weight: 400;
          line-height: 1.4;
          color: #9CA3AF;

          @media (min-width: 300px) {
            padding: 20px;
            font-size: 12px;
            border-radius: 8px;
          }

          @media (min-width: 375px) {
            padding: 20px;
            font-size: 12px;
            border-radius: 8px;
          }

          @media (min-width: 768px) {
            padding: 20px;
            font-size: 12px;
            border-radius: 8px;
          }

          @media (min-width: 1024px) {
            padding: 20px;
            font-size: 14px;
            border-radius: 8px;
            font-weight: 500;
          }

          @media (min-width: 1200px) {
            padding: 20px;
            font-size: 14px;
            border-radius: 8px;
            font-weight: 500;
          }
        }

        &__form-main {
          flex: 1;

          @media (min-width: 768px) {
            flex: none;
          }
        }

        &__buttons {
          display: flex;
          flex-direction: column;
          font-weight: 500;
          color: #374151;

          @media (min-width: 300px) {
            gap: 16px;
            margin-top: 0;
          }

          @media (min-width: 375px) {
            gap: 16px;
            margin-top: 0;
          }

          @media (min-width: 768px) {
            gap: 20px;
            margin-top: 20px;
          }

          &__row {
            display: flex;
            justify-content: space-between;
            align-items: center;

            @media (min-width: 300px) {
              justify-content: center;
              gap: 12px;
            }

            @media (min-width: 375px) {
              justify-content: center;
              gap: 12px;
            }

            @media (min-width: 768px) {
              justify-content: flex-end;
              gap: 0;
            }

            >div {
              display: flex;
              align-items: center;
              gap: 12px;

              a {
                color: #374151;

                &:hover {
                  color: #6D6EFA;
                }
              }
            }
          }

          button {
            flex: 1;
            padding: 0;
            width: 100%;
            height: 52px;
            background-color: #6D6EFA;
            border-radius: 12px;
            border: 0;
            color: #FFFFFF;
            font-weight: 700;
          }
        }
      }
    }
  }
}

.terms-all-agree {
  margin-top: 40px;
  padding: 16px 20px;
  background-color: #F3F4F6;
  border-radius: 12px;

  label {
    strong {
      color: #111827;
      font-size: 18px;
      line-height: 1.3888888;

      @media (min-width: 300px) {
        font-size: 16px;
      }

      @media (min-width: 375px) {
        font-size: 16px;
      }

      @media (min-width: 768px) {
        font-size: 18px;
      }
    }
  }
}

.terms-rows {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.terms-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  letter-spacing: -0.2px;
  font-size: 16px;

  @media (min-width: 300px) {
    font-size: 14px;
  }

  @media (min-width: 375px) {
    font-size: 14px;
  }

  @media (min-width: 1024px) {
    font-size: 14px;
  }

  @media (min-width: 1280px) {
    font-size: 16px;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 400;
    color: #6D6EFA;
    background-color: transparent;
    border-radius: 6px;
    border: 1px solid #6D6EFA;
    width: 73px;
    height: 32px;

    @media (min-width: 300px) {
      width: 20px;
      height: 20px;
      border-width: 0;
      padding: 0;

      span {
        display: none;
      }

      svg {
        display: block;
      }
    }

    @media (min-width: 375px) {
      width: 20px;
      height: 20px;
      border-width: 0;
      padding: 0;

      span {
        display: none;
      }

      svg {
        display: block;
      }
    }

    @media (min-width: 768px) {
      border: 1px solid #6D6EFA;
      width: 73px;
      height: 32px;

      span {
        display: inline;
      }

      svg {
        display: none;
      }
    }
  }
}
</style>