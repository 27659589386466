<template>
  <b-modal id="signup-modal" :title="title" hide-header-close centered content-class="signup-modal">
    <div v-if="hasSignupNameSlot" class="signup-modal-name">
      <slot name="signup-name"></slot>
    </div>

    <div class="signup-modal-contents">
      <slot name="signup-contents"></slot>
    </div>

    <template #modal-footer>
      <div :class="{ 'has-one-button': buttonsLength === 1 }">
        <b-button v-if="buttons.cancel" variant="outline-primary" @click="closeModal">
          {{ buttons.cancel.text }}
        </b-button>
        <b-button v-if="buttons.confirm" variant="primary" @click="closeModal">
          {{ buttons.confirm.text }}
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: "SignupModal",
  props: {
    title: {
      type: String,
      required: true
    },
    callbackFunc: {
      type: Function,
      default: null,
    },
    buttons: {
      /** @type {import('vue').PropType<{
       *   cancel: {
       *     text: string
       *   },
       *   confirm: {
       *     text: string
       *   }
       * }>} */
      type: Object,
      default: () => ({
        confirm: {
          text: '확인'
        }
      })
    }
  },
  data() {
    return {

    }
  },
  computed: {
    hasSignupNameSlot() {
      return !!this.$slots['signup-name']
    },
    buttonsLength() {
      return Object.keys(this.$props.buttons).length
    }
  },
  methods: {
    closeModal() {
      this.$bvModal.hide('signup-modal');

      if (this.callbackFunc) {
        this.callbackFunc();
      }
    },
  }
};
</script>

<style lang="scss">
@use "../../../assets/scss/landing/base/common" as c;

#signup-modal {
  .modal-md {
    margin-left: auto;
    margin-right: auto;

    @media (min-width: 300px) {
      width: 300px;
    }

    @media (min-width: 375px) {
      width: 350px;
    }

    @media (min-width: 768px) {
      width: 684px;
    }
  }

  .modal-content {

    @media (min-width: 300px) {
      padding: 0 20px 20px;
    }

    @media (min-width: 375px) {
      padding: 0 20px 20px;
    }

    @media (min-width: 768px) {
      padding: 0 24px 24px;
    }
  }
}

.signup-modal .modal-body {
  @include c.commonStyles();

  max-height: 558px;
  width: 100%;
  overflow-y: hidden;

  @media (min-width: 300px) {
    padding: 24px 18px;
    max-height: 440px;
  }

  @media (min-width: 375px) {
    padding: 28px 20px;
    max-height: 472px;
  }

  @media (min-width: 768px) {
    padding: 28px 28px;
    max-height: 558px;
  }
}

.signup-modal .modal-footer {
  display: flex;

  >div {
    display: flex;
    flex: 1;
    justify-content: space-between;
    margin: 0;

    &.has-one-button {
      justify-content: flex-end;
    }
  }
}

.signup-modal-name {
  padding-bottom: 20px;
  border-bottom: 1px solid #E5E7EB;

  &:empty {
    display: none;
  }
}

.signup-modal-contents {
  display: flex;
  flex-direction: column;
  color: #6B7280;
  font-size: 16px;
  font-weight: 400;
  white-space: pre-line;
  padding-top: 20px;
  overflow-y: auto;

  @media (min-width: 300px) {
    max-height: 280px;
  }

  @media (min-width: 375px) {
    max-height: 372px;
  }

  @media (min-width: 768px) {
    max-height: 458px;
  }

  &:first-child {
    padding-top: 0;

    @media (min-width: 375px) {
      max-height: 380px;
    }

    @media (min-width: 375px) {
      max-height: 416px;
    }

    @media (min-width: 768px) {
      max-height: 502px;
    }
  }
}

.signup-modal-contents .search-box {
  width: 100%;

  input {
    flex: 1;
  }
}
</style>