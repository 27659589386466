<template>
  <LoginFormField :justify="justify" :items="items">
    <input
      :id="id"
      :name="name"
      type="checkbox"
      :checked="modelValue ? 'checked' : ''"
      @input="$emit('input', !modelValue)"
    />
    <label :for="id">
      <slot name="label">
        {{ label }}
      </slot>
    </label>
  </LoginFormField>
</template>

<script>
import LoginFormField from './LoginFormField.vue';

export default {
  name: 'LoginFormSingleCheckboxField',

  model: {
    prop: 'modelValue',
    event: 'input'
  },

  props: {
    justify: {
      type: String,
      default: 'flex-start'
    },
    items: {
      type: String,
      default: 'center'
    },
    row: Boolean,
    modelValue: Boolean,
    label: String,
    id: {
      type: String,
      default: () => `id-${crypto.randomUUID()}`
    },
    name: String
  },

  emits: ['input'],

  computed: {
    
  },

  components: {
    LoginFormField
  }
}
</script>

<style lang="scss" scoped>
@use "../../../assets/scss/service/abstracts/variables" as *;

.login-form__field {
  input[type="checkbox"] {
    display: none;

    ~ label {
      display: flex;
      gap: 6px;
      align-items: center;
      margin: 0;
      cursor: pointer;
      user-select: none;

      &::before {
        display: block;
        width: 16px;
        height: 16px;
        border: 1px solid #6D6EFA;
        border-radius: 2px;
        box-sizing: border-box;
        content: '';
      }
    }

    &:checked ~ label {
      &::before {
        border: 0;
        background-repeat: no-repeat;
        background-size: contain;
        background-image: url('#{$icon}icon-check-on.svg');
      }
    }
  }
}
</style>