<template>
  <div @click="handleSubmit" >
    <span class="desc" v-html="title" />
    <button ref='button' class="btn btn-icon icon-sort">
      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="5" viewBox="0 0 10 5" fill="none" class="ic-up">
        <path d="M5 0L0 5H10L5 0Z" fill="#D1D5DB"/>
      </svg>
      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="5" viewBox="0 0 10 5" fill="none" class="ic-down">
        <path d="M5 5L0 0H10L5 5Z" fill="#D1D5DB"/>
      </svg>
    </button>
  </div>
</template>

<script>
export default {
  name: "IconSort",
  props: {
    title: {
      type: String,
    },
    field: {
      type: String,
      // required: true
    },
    onSubmit: {
      type: Function,
    }
  },
  methods: {
    handleSubmit() {
      const button = this.$refs.button; // ref를 통해 버튼 참조
      var type = "";

      const allIconSort = document.querySelectorAll('.icon-sort');
      allIconSort.forEach(iconSort => {
        if (iconSort === button) {
          return;
        }
        iconSort.classList.remove('up');
        iconSort.classList.remove('down');
      });
      
      if (button.classList.contains('up')) {
        button.classList.remove('up');
        button.classList.add('down');
        type = "asc";
      }
      else if (button.classList.contains('down')) {
        button.classList.remove('down');
      }
      else {
        button.classList.add('up');
        type = "desc";
      }

      if (this.onSubmit) {
        this.onSubmit(this.field, type)
      }
    },
  }
}
</script>

<style scoped lang="scss">
.icon-sort {
  display: inline-flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  border: none;
  &.up {
    .ic-up path {
      fill: var(--gray900);
    }
    .ic-down {
      display: none;
    }
  }
  &.down {
    .ic-down path {
      fill: var(--gray900);
    }
    .ic-up {
      display: none;
    }
  }
}
.ic-down {
  margin-top: 4px;
}
</style>