<template>
  <LoginFormField :id="id" :justify="justify" :items="items" :label="label" :label-style="labelStyle"
    :required="required">
    <div style="display: flex; flex-direction: column; width: 100%">
      <div class="login-form__field__input">
        <div class="timer">
          <slot name="timer"></slot>
        </div>

        <input v-if="type !== 'textarea'" :value="modelValue" :class="{ invalid }" :id="id" :name="name"
          :type="isPasswordVisible ? 'text' : type" :placeholder="placeholder" :readonly="readonly"
          @input="$emit('input', $event.target.value)" />
        <textarea v-else :value="modelValue" :class="{ invalid }" :id="id" :name="name" :placeholder="placeholder"
          :readonly="readonly" @input="$emit('input', $event.target.value)" />

        <div v-if="type === 'password' && modelValue.length" class="password__eye"
          @click="() => isPasswordVisible = !isPasswordVisible">
          <IconEyeOn v-if="!isPasswordVisible" class="password__eye__on" />
          <IconEyeOff v-else class="password__eye__off" />
        </div>
      </div>

      <div class="login-form__field__desc">{{ desc }}</div>
    </div>
  </LoginFormField>
</template>

<script>
import IconEyeOn from '../../../components/service/icons/IconEyeOn.vue';
import IconEyeOff from '../../../components/service/icons/IconEyeOff.vue';
import LoginFormField from './LoginFormField.vue';

export default {
  name: 'LoginFormTextField',

  model: {
    prop: 'modelValue',
    event: 'input'
  },

  props: {
    justify: {
      type: String,
      default: 'flex-start'
    },
    items: {
      type: String,
      default: 'center'
    },
    row: Boolean,
    modelValue: String,
    label: String,
    labelStyle: Object,
    id: {
      type: String,
      default: () => `id-${crypto.randomUUID()}`
    },
    name: String,
    type: {
      type: String,
      default: 'text'
    },
    placeholder: String,
    desc: String,
    required: Boolean,
    readonly: Boolean,
    invalid: Boolean,
  },

  emits: ['input'],

  data: () => ({
    isFocused: false,
    isPasswordVisible: false,
  }),

  computed: {

  },

  components: {
    IconEyeOn,
    IconEyeOff,
    LoginFormField
  },
}
</script>

<style lang="scss" scoped>
.login-form__field {
  position: relative;

  &__input {
    position: relative;
    flex: 1;
    width: 100%;

    .password__eye {
      position: absolute;
      top: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      cursor: pointer;

      @media (min-width: 300px) {
        right: 8px;
      }

      @media (min-width: 375px) {
        right: 9.5px;
      }

      @media (min-width: 768px) {
        right: 16px;
      }

      &__on,
      &__off {
        @media (min-width: 300px) {
          width: 14px;
        }

        @media (min-width: 375px) {
          width: 16px;
        }

        @media (min-width: 768px) {
          width: 20px;
          height: 20px;
        }

        color: #374151;
      }

      &::after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: '';
        z-index: 1;
      }
    }

    .timer {
      position: absolute;
      top: 1px;
      bottom: 1px;
      right: 1px;
      padding-left: 4px;
      padding-right: 15px;
      background-color: #ffffff;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 700;
      color: #FF594F;

      &:empty {
        display: none;
      }
    }

    input,
    textarea {
      padding: 8.5px 16px;
      width: 100%;
      border-radius: 8px;
      border: 1px solid #D1D5DB;
      color: #111827;
      outline: none;
      font-size: 14px;
      font-weight: 400;
      line-height: 1.78571428;

      &.invalid {
        border-color: #DC2626;
      }

      &::placeholder {
        color: #9CA3AF;
      }

      &:read-only {
        background-color: #F9FAFB;
      }

      @media (min-width: 300px) {
        padding: 8px 12px;
        font-size: 12px;
      }

      @media (min-width: 375px) {
        padding: 8px 12px;
        font-size: 12px;
      }

      @media (min-width: 1024px) {
        padding: 8.5px 16px;
        font-size: 14px;
      }
    }
  }


  &__desc {
    margin-top: 8px;
    font-size: 14px;
    font-weight: 500;
    color: #4B5563;

    &:empty {
      display: none;
    }
  }
}
</style>