<template>
    <div>
        <!-- 승인/비승인 선택 Start -->
        <div class="d-flex align-items-center mt-1 pt-3">
            <label class="form-label">RCS 승인 여부 <span class="require">*</span></label>
            <b-form-group class="m-0">
                <b-form-radio-group inline v-model="subTab">
                    <b-form-radio name="some-radios" value="allow">승인형</b-form-radio>
                    <b-form-radio name="some-radios" value="notallow">비승인형</b-form-radio>
                </b-form-radio-group>
            </b-form-group>
        </div>
        <!-- 승인/비승인 선택 End -->

        <!-- 승인 템플릿 탭 Start -->
        <div v-if="subTab === 'allow'" class="template-tabs-wrap m-0">
            <div class="service-template-tabs">

                <div class="tab" :class="{ active: templateTab === 'template' }">
                    <button class="btn" @click="changeTemplateTab('template')">템플릿 형
                        <b-button v-b-tooltip.hover.html="tooltipContent('template')" variant="light"
                            class="btn-icon p-0 btn-tooltip">
                            <IconQuestionLine />
                        </b-button>
                    </button>
                </div>
                <div class="tab" :class="{ active: templateTab === 'image' }">
                    <button class="btn" @click="changeTemplateTab('image')">이미지 형
                        <b-button v-b-tooltip.hover.html="tooltipContent('image')" variant="light"
                            class="btn-icon p-0 btn-tooltip">
                            <IconQuestionLine />
                        </b-button>
                    </button>
                </div>
                <div class="tab" :class="{ active: templateTab === 'thumb' }">
                    <button class="btn" @click="changeTemplateTab('thumb')">썸네일 형
                        <b-button v-b-tooltip.hover.html="tooltipContent('thumb')" variant="light"
                            class="btn-icon p-0 btn-tooltip">
                            <IconQuestionLine />
                        </b-button>
                    </button>
                </div>
                <div class="tab" :class="{ active: templateTab === 'sns' }">
                    <button class="btn" @click="changeTemplateTab('sns')">SNS 형
                        <b-button v-b-tooltip.hover.html="tooltipContent('sns')" variant="light"
                            class="btn-icon p-0 btn-tooltip">
                            <IconQuestionLine />
                        </b-button>
                    </button>
                </div>
            </div>
        </div>
        <!-- 승인 템플릿 탭 End -->

        <!-- 비승인 템플릿 탭 Start -->
        <div v-if="subTab === 'notallow'" class="template-tabs-wrap m-0">
            <div class="service-template-tabs">
                <div class="tab" :class="{ active: templateTab === 'template' }">
                    <button class="btn" @click="changeTemplateTab('template')">템플릿 형
                        <b-button v-b-tooltip.hover.html="tooltipContent('template')" variant="light"
                            class="btn-icon p-0 btn-tooltip">
                            <IconQuestionLine />
                        </b-button>
                    </button>
                </div>
                <div class="tab" :class="{ active: templateTab === 'text' }">
                    <button class="btn" @click="changeTemplateTab('text')">텍스트 형
                        <b-button v-b-tooltip.hover.html="tooltipContent('text')" variant="light"
                            class="btn-icon p-0 btn-tooltip">
                            <IconQuestionLine />
                        </b-button>
                    </button>
                </div>
                <div class="tab" :class="{ active: templateTab === 'image' }">
                    <button class="btn" @click="changeTemplateTab('image')">이미지 형
                        <b-button v-b-tooltip.hover.html="tooltipContent('image')" variant="light"
                            class="btn-icon p-0 btn-tooltip">
                            <IconQuestionLine />
                        </b-button>
                    </button>
                </div>
                <div class="tab" :class="{ active: templateTab === 'thumb' }">
                    <button class="btn" @click="changeTemplateTab('thumb')">썸네일 형
                        <b-button v-b-tooltip.hover.html="tooltipContent('thumb')" variant="light"
                            class="btn-icon p-0 btn-tooltip">
                            <IconQuestionLine />
                        </b-button>
                    </button>
                </div>
                <div class="tab" :class="{ active: templateTab === 'sns' }">
                    <button class="btn" @click="changeTemplateTab('sns')">SNS 형
                        <b-button v-b-tooltip.hover.html="tooltipContent('sns')" variant="light"
                            class="btn-icon p-0 btn-tooltip">
                            <IconQuestionLine />
                        </b-button>
                    </button>
                </div>
                <div class="tab" :class="{ active: templateTab === 'slide' }">
                    <button class="btn" @click="changeTemplateTab('slide')">슬라이드 형
                        <b-button v-b-tooltip.hover.html="tooltipContent('slide')" variant="light"
                            class="btn-icon p-0 btn-tooltip">
                            <IconQuestionLine />
                        </b-button>
                    </button>
                </div>
            </div>
        </div>
        <!-- 비승인 템플릿 탭 End -->

        <!-- 승인형 템플릿 탭 상세  -->
        <div class="service-template-content" v-if="subTab === 'allow'">
            <div v-if="templateTab === 'template'">
                <div class="template-select">
                    <TemplateCard :setCard="setCard" value="allowTemplate1">
                        <b-form-radio v-model="card" name="type" value="allowTemplate1" class="custom-radio-vertical">서술
                            템플릿<br>기본형</b-form-radio>
                    </TemplateCard>
                    <TemplateCard :setCard="setCard" value="allowTemplate2">
                        <b-form-radio v-model="card" name="type" value="allowTemplate2" class="custom-radio-vertical">서술
                            템플릿<br>강조형A</b-form-radio>
                    </TemplateCard>
                    <TemplateCard :setCard="setCard" value="allowTemplate3">
                        <b-form-radio v-model="card" name="type" value="allowTemplate3"
                            class="custom-radio-vertical">스타일 템플릿<br>기본형</b-form-radio>
                    </TemplateCard>
                    <TemplateCard :setCard="setCard" value="allowTemplate4">
                        <b-form-radio v-model="card" name="type" value="allowTemplate4"
                            class="custom-radio-vertical">스타일 템플릿<br>강조형A</b-form-radio>
                    </TemplateCard>
                </div>
            </div>

            <div v-else-if="templateTab === 'image'">
                <div class="template-select">
                    <TemplateCard :setCard="setCard" value="allowImage1">
                        <b-form-radio v-model="card" name="type" value="allowImage1" class="custom-radio-vertical">이미지
                            강조형</b-form-radio>
                    </TemplateCard>
                    <TemplateCard :setCard="setCard" value="allowImage2">
                        <b-form-radio v-model="card" name="type" value="allowImage2"
                            class="custom-radio-vertical">이미지&타이틀<br />강조형</b-form-radio>
                    </TemplateCard>
                </div>
            </div>

            <div v-else-if="templateTab === 'thumb'">
                <div class="template-select">
                    <TemplateCard :setCard="setCard" value="allowThumb1">
                        <b-form-radio v-model="card" name="type" value="allowThumb1"
                            class="custom-radio-vertical">썸네일형(세로)</b-form-radio>
                    </TemplateCard>
                    <TemplateCard :setCard="setCard" value="allowThumb2">
                        <b-form-radio v-model="card" name="type" value="allowThumb2"
                            class="custom-radio-vertical">썸네일형(가로)</b-form-radio>
                    </TemplateCard>
                </div>
            </div>

            <div v-else-if="templateTab === 'sns'">
                <div class="template-select">
                    <TemplateCard :setCard="setCard" value="allowSns1">
                        <b-form-radio v-model="card" name="type" value="allowSns1"
                            class="custom-radio-vertical">SNS형</b-form-radio>
                    </TemplateCard>
                    <TemplateCard :setCard="setCard" value="allowSns2">
                        <b-form-radio v-model="card" name="type" value="allowSns2"
                            class="custom-radio-vertical">SNS형(중간버튼)</b-form-radio>
                    </TemplateCard>
                </div>
            </div>
        </div>


        <!-- 비승인형 템플릿 탭 상세  -->
        <div class="service-template-content" v-if="subTab === 'notallow'">
            <div v-if="templateTab === 'template'">
                <div class="template-select">
                    <TemplateCard :setCard="setCard" value="notAllowTemplate1">
                        <b-form-radio v-model="card" name="type" value="notAllowTemplate1"
                            class="custom-radio-vertical">프리
                            템플릿<br />기본형</b-form-radio>
                    </TemplateCard>
                    <TemplateCard :setCard="setCard" value="notAllowTemplate2">
                        <b-form-radio v-model="card" name="type" value="notAllowTemplate2"
                            class="custom-radio-vertical">프리
                            템플릿<br />강조형A</b-form-radio>
                    </TemplateCard>
                </div>
            </div>

            <div v-else-if="templateTab === 'text'">
                <div class="template-select">
                    <TemplateCard :setCard="setCard" value="notAllowText1">
                        <b-form-radio v-model="card" name="type" value="notAllowText1"
                            class="custom-radio-vertical">SMS</b-form-radio>
                    </TemplateCard>
                    <TemplateCard :setCard="setCard" value="notAllowText2">
                        <b-form-radio v-model="card" name="type" value="notAllowText2"
                            class="custom-radio-vertical">LMS</b-form-radio>
                    </TemplateCard>
                </div>
            </div>

            <div v-else-if="templateTab === 'image'">
                <div class="template-select">
                    <TemplateCard :setCard="setCard" value="notAllowImage1">
                        <b-form-radio v-model="card" name="type" value="notAllowImage1"
                            class="custom-radio-vertical">세로형(Medium)</b-form-radio>
                    </TemplateCard>
                    <TemplateCard :setCard="setCard" value="notAllowImage2">
                        <b-form-radio v-model="card" name="type" value="notAllowImage2"
                            class="custom-radio-vertical">세로형(Tall)</b-form-radio>
                    </TemplateCard>
                    <TemplateCard :setCard="setCard" value="notAllowImage3">
                        <b-form-radio v-model="card" name="type" value="notAllowImage3"
                            class="custom-radio-vertical">이미지
                            강조형</b-form-radio>
                    </TemplateCard>
                    <TemplateCard :setCard="setCard" value="notAllowImage4">
                        <b-form-radio v-model="card" name="type" value="notAllowImage4"
                            class="custom-radio-vertical">이미지&타이틀<br />강조형</b-form-radio>
                    </TemplateCard>
                </div>
            </div>

            <div v-else-if="templateTab === 'thumb'">
                <div class="template-select">
                    <TemplateCard :setCard="setCard" value="notAllowThumb1">
                        <b-form-radio v-model="card" name="type" value="notAllowThumb1"
                            class="custom-radio-vertical">썸네일형(세로)</b-form-radio>
                    </TemplateCard>
                    <TemplateCard :setCard="setCard" value="notAllowThumb2">
                        <b-form-radio v-model="card" name="type" value="notAllowThumb2"
                            class="custom-radio-vertical">썸네일형(가로)</b-form-radio>
                    </TemplateCard>
                </div>
            </div>

            <div v-else-if="templateTab === 'sns'">
                <div class="template-select">
                    <TemplateCard :setCard="setCard" value="notAllowSns1">
                        <b-form-radio v-model="card" name="type" value="notAllowSns1"
                            class="custom-radio-vertical">SNS형</b-form-radio>
                    </TemplateCard>
                    <TemplateCard :setCard="setCard" value="notAllowSns2">
                        <b-form-radio v-model="card" name="type" value="notAllowSns2"
                            class="custom-radio-vertical">SNS형(중간버튼)</b-form-radio>
                    </TemplateCard>
                </div>
            </div>

            <div v-else-if="templateTab === 'slide'">
                <div class="template-select">
                    <TemplateCard :setCard="setCard" value="notAllowSlide1">
                        <b-form-radio v-model="card" name="type" value="notAllowSlide1"
                            class="custom-radio-vertical">슬라이드형(Small)</b-form-radio>
                    </TemplateCard>
                    <TemplateCard :setCard="setCard" value="notAllowSlide2">
                        <b-form-radio v-model="card" name="type" value="notAllowSlide2"
                            class="custom-radio-vertical">슬라이드형(Medium)</b-form-radio>
                    </TemplateCard>
                </div>
            </div>
        </div>

    </div>
</template>


<script>
import TemplateCard from '@/components/service/TemplateCard.vue'
import IconQuestionLine from '@/components/service/icons/IconQuestionLine.vue'


export default {
    components: {
        TemplateCard, IconQuestionLine,
    },
    name: "rcsTemplateTab",
    data() {
        return {
            subTab: 'allow',
            templateTab: 'template',
            templateCard: 'template1',
            card: 'template1'
        }
    },
    methods: {
        changeSubTab(value) {
            this.subTab = value;
        },
        changeTemplateTab(value) {
            this.templateTab = value;
        },
        setCard(value) {
            this.card = value;
        },
        tooltipContent(tab) {
            switch (tab) {
                case 'template':
                    return `
            템플릿형 정보성 메시지를 사전에 등록 및 승인을 받아 메시지를 발송할 수 있습니다.<br/>
            스타일/서술 템플릿 형식은 승인완료된 양식만 발송 할 수 있습니다.<br/>
            템플릿형은 광고성 메시지를 발송할 수 없습니다.
          `;
                case 'image':
                    return `
            미디어를 위에 넣어 타이틀, 내용, 다양한 버튼을 조합하여 메시지를 발송할 수 있습니다.
          `;
                case 'thumb':
                    return `
            썸네일 이미지와 관련 설명을 입력하여 상품이나 서비스를 효과적으로 전달할 수 있습니다.
          `;
                case 'sns':
                    return `
            버튼과 이미지를 활용하여, SNS 느낌의 메시지를 발송할 수 있습니다.
          `;
                case 'text':
                    return `
            메시지에 버튼을 추가하여 메시지를 발송할 수 있습니다.
          `;
                case 'slide':
                    return `
            미디어를 여러 개의 카드로 구성하여 한번에 발송할 수 있습니다. 
          `;
                default:
                    return '알 수 없는 형식입니다.';
            }
        }
    }
}
</script>


<style scoped lang="scss">
@use "~@/assets/scss/service/base/typography" as typography;
@use '@/assets/scss/service/message.scss';
@use '@/assets/scss/service/template.scss';

.template-tabs-wrap {
    margin: 8px 0;
}

.service-template {
    &-tabs {
        display: flex;
        margin-top: 28px;

        .tab {
            .btn:not(.btn-icon) {
                padding: 12px 24px;
                background-color: #F7F8FB;
                border: 1px solid var(--border-color);
                border-radius: 0;
                text-align: center;
                @include typography.font-style(14px, 500, 140%, -0.28px);
                color: #6E6F73;
            }

            &+.tab {
                .btn {
                    border-left: none;
                }
            }

            &.active {
                .btn {
                    background-color: #6E6F73;
                    color: var(--white);
                }
            }
        }
    }

    &-content {
        min-height: 224px;
        margin-top: 12px;
        padding: 20px;
        background-color: var(--gray50);
        border: 1px solid var(--border-color);
    }
}

.template-select {
    &>div+div {
        margin-left: 12px;
    }
}
</style>
