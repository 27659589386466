<template>
	<div class="login-wrapper">
	  <div class="login-wrapper__bg">
		<picture>
		  <source media="(max-width: 1280px)" :srcset="require('@/assets/images/landing/bg-login-tablet.png')" />
		  <source media="(min-width: 1280px)" :srcset="require('@/assets/images/landing/bg-login.png')" />
		  <img ref="bgImg" :src="require('@/assets/images/landing/bg-login.png')" alt="Login Background" />
		</picture>
	  </div>
	  <div class="login-wrapper__form-wrapper">
		<div class="login-wrapper__form-wrapper__btn-back">
		  <button @click="$router.back()">뒤로가기</button>
		</div>
  
		<div class="login-wrapper__form-wrapper-container">
		  <div class="login-wrapper__form-wrapper-container__header">
			<div class="login-wrapper__form-wrapper-container__header__btn-back">
			  <button @click="$router.back()">뒤로가기</button>
			</div>
  
			<div class="login-wrapper__form-wrapper-container__header__title">
			  아이디 찾기
			</div>
		  </div>
  
		  <div class="login-wrapper__form-wrapper-container__contents">
			<template v-if="!finded">
			  <div class="login-wrapper__form-wrapper-container__contents__form-main">
				<LoginForm>
				  <LoginFormTextField v-model="form.name" label="사업자명 입력" placeholder="사업자명을 입력해주세요" items="flex-start"
					required />
				  <LoginFormTextField v-model="form.tel" label="전화번호 입력" placeholder="전화번호를 입력해주세요" items="flex-start"
					required />
				</LoginForm>
			  </div>
  
			  <div class="login-wrapper__form-wrapper-container__contents__buttons">
				<div class="login-wrapper__form-wrapper-container__contents__buttons__row">
				  <button @click="showAlertModal">인증메일 발송</button>
				</div>
			  </div>
			</template>
  
			<template v-else>
			  <div class="login-wrapper__form-wrapper-container__contents__finded">
				<div class="login-wrapper__form-wrapper-container__contents__finded__header">
				  고객님의 아이디
				</div>
  
				<div class="login-wrapper__form-wrapper-container__contents__finded__contents">
				  bella@gridge.co.kr
				</div>
			  </div>
  
			  <div class="login-wrapper__form-wrapper-container__contents__buttons">
				<div class="login-wrapper__form-wrapper-container__contents__buttons__row">
				  <button @click="$router.push('/login')">로그인하기</button>
				</div>
  
				<div class="login-wrapper__form-wrapper-container__contents__buttons__row">
				  <div>
					<router-link to="/login/findUserPwd">비밀번호 찾기</router-link>
				  </div>
				</div>
			  </div>
			</template>
		  </div>
		</div>
	  </div>
  
	  <AlertModal2 title="미등록 회원 모달" desc="등록된 사용자가 없습니다. 이메일 주소를 확인해주세요." />
	</div>
  </template>
  
  <script>
  import AlertModal2 from '../../../components/service/modal/AlertModal2.vue';
  import LoginForm from '../../../components/landing/login/LoginForm.vue';
  import LoginFormTextField from '../../../components/landing/login/LoginFormTextField.vue';
  
  export default {
  data: () => ({
    form: {
      name: '',
      tel: '',
    },
    finded: false
  }),

  components: {
    LoginForm,
    LoginFormTextField,
    AlertModal2
  },

  methods: {
    showAlertModal() {
      this.$bvModal.show('alert-modal2');
      this.finded = true
    },
    resizeBgImage() {
      const bgImg = this.$refs.bgImg
      const bgImgParent = bgImg.closest('div')

      if (bgImgParent.offsetWidth >= bgImg.clientWidth) {
        bgImg.style.width = '100%'
        bgImg.style.height = 'auto'
      }
      if (bgImgParent.offsetHeight >= bgImg.clientHeight) {
        bgImg.style.width = 'auto'
        bgImg.style.height = '100%'
      }
    }
  },

  mounted() {
    window.addEventListener("resize", this.resizeBgImage);

    this.$refs.bgImg.style.width = '100%'
    this.$refs.bgImg.style.height = 'auto'
    this.resizeBgImage()
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.resizeBgImage);
  }
  }
  </script>
  
<style lang="scss" scoped>
@use "../../../assets/scss/landing/abstracts/variables" as v;
@use "../../../assets/scss/service/abstracts/variables" as *;

.login-wrapper {
  display: flex;
  justify-content: flex-end;
  position: relative;
  width: 100vw;
  height: 100vh;

  &__bg {
    position: relative;
    display: none;
    overflow: hidden;
    background: linear-gradient(301.87deg, #4042FF 33.75%, #A7C0FF 91.23%);
    background-size: auto 100%;
    background-repeat: no-repeat;

    img {
      position: absolute;
      top: 0;
      left: 0;
    }

    @media (min-width: 1024px) {
      display: block;
      flex: 1;
    }
  }

  &__form-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    background-color: #ffffff;
    box-shadow: 0px 4px 32px 0px #6D6EFA3D;

    @media (min-width: 300px) {
      width: 100%;
    }

    @media (min-width: 375px) {
      width: 100%;
    }

    @media (min-width: 1024px) {
      width: fit-content;
    }

    @media (min-width: 1280px) {
      width: fit-content;
    }

    &__btn-back {
      position: absolute;
      bottom: 20px;
      left: -116px;
      width: 96px;
      height: 36px;
      display: flex;
      justify-content: flex-end;
      background: url($icon + "icon-caret-left.svg") #ffffff;
      background-size: 16px auto;
      background-repeat: no-repeat;
      background-position: 12px center;
      border-radius: 36px;

      button {
        @media (min-width: 375px) {
          display: none;
        }

        @media (min-width: 1024px) {
          display: block;
        }

        display: flex;
        align-items: center;
        padding: 0 12px;
        width: fit-content;
        height: 100%;
        background-color: transparent;
        border: 0;
        color: #111827;
        font-size: 14px;
        font-weight: 700;
      }
    }

    &-container {
      display: flex;
      flex-direction: column;
      gap: 80px;
      box-sizing: content-box;

      @media (min-width: 300px) {
        padding: 40px 20px;
        width: 382px;
      }

      @media (min-width: 375px) {
        padding: 40px 20px;
        width: 382px;
      }

      @media (min-width: 1024px) {
        justify-content: center;
        padding: 0 60px;
        width: 382px;
      }

      @media (min-width: 1280px) {
        padding: 0 170px;
        width: 503px;
      }

      &__header {
        display: flex;
        flex-direction: column;
        gap: 40px;

        &__btn-back {
          @media (min-width: 300px) {
            display: block;
          }

          @media (min-width: 375px) {
            display: block;
          }

          @media (min-width: 1024px) {
            display: none;
          }

          padding-left: 16px;
          background: url($icon + "icon-caret-left.svg");
          background-size: 12px auto;
          background-repeat: no-repeat;
          background-position: 0% center;

          button {
            display: flex;
            align-items: center;
            padding: 0;
            height: 19px;
            background-color: transparent;
            border: 0;
            color: #111827;
            font-size: 12px;
            font-weight: 700;
          }
        }

        &__title {
          font-size: 40px;
          font-weight: 700;
          line-height: 1.4;
          color: #111827;

          @media (min-width: 300px) {
            font-size: 20px;
          }

          @media (min-width: 375px) {
            font-size: 20px;
          }

          @media (min-width: 1024px) {
            font-size: 28px;
          }

          @media (min-width: 1280px) {
            font-size: 40px;
          }
        }
      }

      &__contents {
        display: flex;
        flex-direction: column;
        gap: 60px;
        color: #6B7280;

        @media (min-width: 300px) {
          gap: 40px;
          flex: 1;
        }

        @media (min-width: 375px) {
          gap: 40px;
          flex: 1;
        }

        @media (min-width: 768px) {
          gap: 60px;
          flex: none;
        }

        &__form-main {
          flex: 1;

          @media (min-width: 768px) {
            flex: none;
          }
        }

        &__finded {
          display: flex;
          flex-direction: column;
          gap: 20px;
          font-size: 16px;
          font-weight: 500;
          flex: 1;

          @media (min-width: 300px) {
            gap: 12px;
            font-size: 14px;
          }

          @media (min-width: 375px) {
            gap: 12px;
            font-size: 14px;
          }

          @media (min-width: 768px) {
            gap: 20px;
            font-size: 16px;
            flex: none;
          }

          &__title {
            color: #6B7280;
          }

          &__contents {
            display: flex;
            justify-content: center;
            background-color: #F9FAFB;
            border: 2px solid #D1D5DB;
            color: #6D6EFA;
            font-weight: 700;
            line-height: 1.4;

            @media (min-width: 300px) {
              padding: 46px 0;
              font-size: 20px;
              border-radius: 12px;
            }

            @media (min-width: 375px) {
              padding: 46px 0;
              font-size: 20px;
              border-radius: 12px;
            }

            @media (min-width: 768px) {
              padding: 42px 0;
              font-size: 24px;
              border-radius: 16px;
            }

            @media (min-width: 1024px) {
              padding: 40.5px 0;
              font-size: 28px;
              border-radius: 20px;
            }

            @media (min-width: 1200px) {
              padding: 52px 0;
              font-size: 40px;
              border-radius: 20px;
            }
          }
        }

        &__buttons {
          display: flex;
          flex-direction: column;
          font-weight: 500;
          color: #374151;

          @media (min-width: 300px) {
            gap: 16px;
            margin-top: 0;
          }

          @media (min-width: 375px) {
            gap: 16px;
            margin-top: 0;
          }

          @media (min-width: 768px) {
            gap: 20px;
            margin-top: 0;
          }

          &__row {
            display: flex;
            justify-content: space-between;
            align-items: center;

            @media (min-width: 300px) {
              justify-content: center;
              gap: 12px;
            }

            @media (min-width: 375px) {
              justify-content: center;
              gap: 12px;
            }

            @media (min-width: 768px) {
              justify-content: flex-end;
              gap: 0;
            }

            >div {
              display: flex;
              align-items: center;
              gap: 12px;

              a {
                color: #374151;

                &:hover {
                  color: #6D6EFA;
                }
              }
            }
          }

          button {
            flex: 1;
            padding: 0;
            width: 100%;
            height: 52px;
            background-color: #6D6EFA;
            border-radius: 12px;
            border: 0;
            color: #FFFFFF;
            font-weight: 700;
          }
        }
      }
    }
  }
}
</style>