<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M6.26463 4.8V19.2H17.7354V9.525H13.9855C13.4982 9.525 13.1032 9.12206 13.1032 8.625V4.8H6.26463ZM14.8678 6.07279L16.4876 7.725H14.8678V6.07279ZM4.95224 3.46131C5.24181 3.16594 5.63454 3 6.04405 3H13.9855C14.2195 3 14.444 3.09482 14.6094 3.2636L19.2416 7.9886C19.407 8.15739 19.5 8.3863 19.5 8.625V19.425C19.5 19.6318 19.4601 19.8366 19.3825 20.0277C19.3049 20.2188 19.1911 20.3924 19.0478 20.5387C18.9044 20.685 18.7342 20.801 18.5468 20.8801C18.3595 20.9593 18.1587 21 17.9559 21H6.04405C5.63455 21 5.24181 20.8341 4.95224 20.5387C4.66268 20.2433 4.5 19.8427 4.5 19.425V4.575C4.5 4.15728 4.66268 3.75668 4.95224 3.46131ZM8.47106 12.675C8.47106 12.1779 8.86609 11.775 9.35338 11.775H14.6473C15.1346 11.775 15.5296 12.1779 15.5296 12.675C15.5296 13.1721 15.1346 13.575 14.6473 13.575H9.35338C8.86609 13.575 8.47106 13.1721 8.47106 12.675ZM8.47106 15.375C8.47106 14.8779 8.86609 14.475 9.35338 14.475H14.6473C15.1346 14.475 15.5296 14.8779 15.5296 15.375C15.5296 15.8721 15.1346 16.275 14.6473 16.275H9.35338C8.86609 16.275 8.47106 15.8721 8.47106 15.375Z" fill="currentColor"/>
  </svg>
</template>

<script>
  export default {
    name: 'IconFile',
  };
</script>